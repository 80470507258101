.popout_container__mobile--notification {
    position: absolute;
    z-index: 500;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    opacity: 0;

    transition: all 300ms ease-in-out;
}

.popout_container__mobile--notification.active {
    display: flex;
    opacity: 1;
}

.popout_container__mobile--notification .notification-popup__elements--mobile {
    background-color: var(--primary-black);
    width: 75%;
    min-height: 30%;
    max-height: 50%;
    border-radius: 10px;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
}

.popout_container__mobile--notification .notification-popup__elements--mobile .notification-popup__elements__title--mobile {
    color: white;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}

.popout_container__mobile--notification .notification-popup__elements--mobile .notification-popup__elements__text--mobile {
    color: white;
    font-size: 0.85rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center; 
}

.popout_container__mobile--notification .notification-popup__elements--mobile .notification-popup__elements__text_item--mobile {
    border: 1px solid var(--secondairy-medium-gray);
    border-radius: 5px;
    padding: 7.5px;
}

.popout_container__mobile--notification .notification-popup__elements--mobile .notification-popup__buttons {
    display: flex;
    gap: 25px;
    width: 100%;
    height: 50px;
}

.popout_container__mobile--notification .notification-popup__elements--mobile .notification-popup__buttons button {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: var(--secondairy-medium-gray);
    color: var(--primary-black);
    font-weight: 600;
}

.popout_container__mobile--notification .notification-popup__elements--mobile .notification-popup__buttons button.green {
    background-color: var(--primary-green);
}