#mobile-customer-page {
  display: grid;
  grid-template: repeat(8, 1fr) / 1fr 1fr;
  gap: 7.5px;
}

#mobile-customer-page #header--mobile {
  position: relative;
  grid-column: span 2;
  grid-row: span 1;
  overflow: hidden;
}

#mobile-customer-page #header--mobile .header__buttons {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;

}

#mobile-customer-page #header--mobile .header__buttons .header__button {
  height: 50px;
  width: 50px;
  background-color: var(--primary-green);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondairy-light-gray);
  font-size: 1.8rem;
  box-shadow: var(--border-shadow-float);
}

#mobile-customer-page #customer-table {
  grid-column: span 2;
  grid-row: span 7;
  padding: 0;
  min-height: 100%;
}

#mobile-customer-page #customer-table .circular_loading_spinner__wrapper {
  display: flex;
  align-items: baseline;
  padding-top: 30px;
}

#mobile-customer-page .table__row-4--mobile {
  grid-template-columns: 60% 40%;
}

#mobile-customer-page .table {
  overflow: scroll;
  overflow-x: hidden;
  min-height: 100%;
}