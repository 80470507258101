* {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Disables default iOS styling */
  -moz-appearance: none;
  /* Disables default Firefox styling */
  appearance: none;
  /* Disables default styling for other browsers */
}

html,
body {
  margin: 0;
  padding: 0;
}

/* Custom scrollbar for Chrome, Safari and Opera */

*::-webkit-scrollbar {
  width: 1em;
}

*::-webkit-scrollbar-track {
  background: var(--secondairy-ultra-light-gray);
  border-radius: 10px;
  opacity: 0.5;
  margin-block: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background: var(--secondairy-light-gray);
  border-radius: 10px;
}

body::-webkit-scrollbar {
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: black;
  -webkit-appearance: none;
  /* Remove default styling on iOS */
  appearance: none;
  /* Remove default styling on modern browsers */
}

button {
  -webkit-appearance: none;
  /* Remove default styling on iOS */
  appearance: none;
  /* Remove default styling on modern browsers */
  -webkit-text-fill-color: inherit;
  color: var(--primary-black);
  -webkit-user-select: none;
  /* Chrome, Safari, Opera */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

:root {
  --primary-dark-theme: rgba(40, 40, 40, 255);
  /*#282828*/
  --primary-black: rgba(30, 30, 30, 1);
  /*#1E1E1E*/
  --primary-green: rgba(85, 193, 178, 1);
  /*#55c1b2*/
  --light-primary-green: rgb(170, 224, 216);
  /*#AAE0D8*/
  --ultra-light-primary-green: rgb(212, 241, 236);
  /*#AAE0D8*/
  --secondairy-dark-gray: rgb(56, 56, 56);
  /*#323232*/
  --secondairy-medium-gray: rgb(95, 95, 95);
  /*#5F5F5F*/
  --secondairy-light-medium-gray-plus: rgba(75, 75, 75);
  /*#5e5e5e*/
  --secondairy-light-medium-gray: rgb(207, 207, 207);
  /*#CFCFCF*/
  --secondairy-light-gray: rgba(235, 235, 235, 1);
  /*#EBEBEB*/
  --secondairy-light-gray-plus: rgb(221, 221, 221);

  --secondairy-ultra-light-gray: rgba(250, 250, 250, 1);
  /*#FAFAFA*/

  --border-shadow: rgba(50, 50, 93, 0.2) 0.8px 0.8px 1px 0.5px;
  --border-shadow-min: rgba(50, 50, 93, 0.10) 0.5px 0.5px 1px 0px;
  --border-shadow-float: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  --border-shadow-allround: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

  --red: rgb(212, 53, 53);
  /* #D43535 */
  --light-red: rgb(250, 210, 210);
  --dark-red: rgb(155, 64, 64);
  /* #9B4040 */
  --ultra-light-red: rgb(250, 79, 79, 0.2);
  --hyper-light-red: rgb(250, 79, 79, 0.1);
  --green: rgb(52, 211, 153);
  --light-green: rgb(209, 250, 229);
  --ultra-light-green: rgb(209, 250, 229, 0.3);
  --hyper-light-green: rgb(209, 250, 229, 0.15);
  --dark-green: rgb(71, 95, 70);
  --blue: rgb(30, 64, 175);
  --light-blue: rgb(219, 234, 254);
  --dark-blue: rgb(9, 24, 71);

  --purple: rgb(148, 51, 181);
  --light-purple: rgb(236, 197, 249);
  --dark-purple: rgb(77, 26, 94);

  --special-gold: rgb(211, 187, 108);
  --special-gold-light: #f7d200;
  --special-gold-ultra-light-plus: #e9da85;
  --special-gold-ultra-light: #f7d20048;
  --special-gold-dark: rgb(161, 125, 3);
  --special-silver: rgb(192, 192, 192);
  --special-bronze: rgb(205, 127, 50);

  /* mobile */
  --mobile-red: rgb(212, 53, 53);
  --mobile-light-red: rgb(250, 210, 210)
}

.template {
  position: relative;
  display: grid;
  grid-template: auto auto / min-content auto;
  grid-template-areas:
    "aside header"
    "aside body";
  background: linear-gradient(180deg, var(--secondairy-ultra-light-gray) 0%, var(--secondairy-light-gray) 100%);
  max-width: 100vw;
  overflow: hidden;
}

.template__aside {
  grid-area: aside;
  z-index: 999;
}

.template__body {
  position: relative;
  grid-area: body;
  height: 90vh;
  padding: 20px;
}

.template__header {
  grid-area: header;
}

.body__section {
  position: relative;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 7.5px;
  box-shadow: var(--border-shadow-min);
  padding: 15px;
  overflow: auto;
  overflow-x: hidden;
}

.body__section.big {
  height: 100% !important;
}

.body__section.hide {
  opacity: 0;
  padding: 0;
  height: 0% !important;
  pointer-events: none;
}

.body__section.invisible {
  padding: 0 !important;
  height: 0px !important;
  pointer-events: none;
}

.body__section_content__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.body__section_content__wrapper.mobile h2 {
  color: var(--secondairy-medium-gray) !important;
}

.body__section__header {
  display: grid;
  grid-template: auto / repeat(6, 1fr);
  margin-bottom: 7.5px;
  width: 100%;
  cursor: pointer;
}

.body__section__header_item {
  display: flex;
  gap: 10px;
  padding: 7.5px;
  border-bottom: 2px var(--secondairy-light-gray) solid;
  font-size: 0.70rem;
  text-transform: uppercase;
  color: var(--secondairy-medium-gray);
  width: 100%;
  justify-self: center;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease-in-out;
  user-select: none;
}

.body__section__header_item.hovered {
  border-bottom: 2px var(--secondairy-light-medium-gray) solid;
  color: var(--primary-black);
  font-weight: 500;
}

.body__section__header_item.selected {
  border-bottom: 2px var(--primary-green) solid;
  color: var(--primary-black);
  color: var(--primary-green);
  font-weight: 500;
}

/*  GENERICS  */

.no-select {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard syntax */
}

.clickable {
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

.flex--gap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flex--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-spread {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex--vert {
  display: flex;
  flex-direction: column;
}

.red-border {
  border: var(--red) solid 2px !important;
  border-radius: 5px;
}

.position-top-right {
  position: absolute;
  right: 7.5px;
  top: 7.5px;
}

.hide {
  display: none !important;
  pointer-events: none;
}

.disabled {
  pointer-events: none;
}

.not-allowed {
  cursor: not-allowed !important;
}

.faded-away {
  opacity: 0 !important;
}

.visible {
  opacity: 1 !important;
}

.txt--light-medium-gray {
  color: var(--secondairy-light-medium-gray)
}

.italic {
  font-style: italic;
}

.grayed-out {
  opacity: 0.3;
}

.grayed-out-50 {
  opacity: 0.5;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scroll {
  height: 100%;
  padding-bottom: 15px;
  overflow: scroll;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align-right {
  display: flex;
  align-items: center;
  justify-content: end;
}

.height-full {
  height: 100%;
}

.hght100 {
  height: 100% !important;
}

.hght160px {
  height: 160px;
}

.wdth100 {
  width: 100%;
}

.wdth50 {
  width: 50% !important;
}

.wdth25 {
  width: 25% !important;
}

.wdth75 {
  width: 75% !important;
}

.relative {
  position: relative;
}

.align-right {
  text-align: right !important;
}

.placeholder {
  color: var(--secondairy-light-medium-gray);
  font-style: italic;
}

.loading_spinner_circle {
  color: var(--secondairy-light-gray);
  width: 100%;
  display: flex;
  justify-content: center;
}

.loading_spinner_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ellipsis {
  overflow: hidden;
  /* Hide text that goes beyond the box */
  white-space: nowrap;
  /* Keep the text on a single line */
  text-overflow: ellipsis;
  /* Add ellipsis at the end */
}

/*  TOASTS  */

.Toastify__toast-container--bottom-right .Toastify__toast-theme--light.Toastify__toast--info {
  background: var(--primary-green) !important;
  color: var(--primary-black) !important;
}

.Toastify__toast-container--bottom-right .Toastify__toast-theme--light.Toastify__toast--success {
  background: var(--primary-green) !important;
  color: var(--primary-black) !important;
}

.Toastify__toast-container--bottom-right .Toastify__toast-theme--light.Toastify__toast--error {
  background: var(--red) !important;
  color: var(--primary-black) !important;
}

/*  TEXT  */

.side_note {
  font-style: italic;
  color: var(--secondairy-medium-gray);
  font-size: 0.6rem;
}

/*  GRIDS  */

.grid--2-colums {
  display: grid;
  grid-template: auto / 1fr 1fr;
}

.grid--2-colums-30-70 {
  display: grid;
  grid-template: auto / 30% 70%;
}

.grid--2-colums-50-50 {
  display: grid;
  grid-template: auto / 1fr 1fr;
}

.placeholder-text {
  width: 100%;
  text-align: left;
  font-style: italic;
  font-weight: 300;
  font-size: 0.65rem;
  padding: 7.5px 0;
}

/*  TABLES  */

table {
  position: relative;
  font-size: 0.7rem;
  background-color: white;
  border-radius: 7.5px;
  /* min-height: 100%; */
  width: 100%;
  padding: 10px;
  table-layout: fixed;
}

.table_wrapper {
  background-color: white;
  padding: 15px;
}

table thead {
  position: relative;
  width: 100%;
}

th {
  text-align: left;
  padding: 15px;
  font-weight: 500;
}

td {
  text-align: left;
  padding: 15px;
  font-weight: 300;
}

tr {
  height: auto;
}

table tbody tr:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
  border-radius: 15px;
}

.table {
  position: relative;
  width: 100%;
  /* min-width: 1040px; */
  font-size: 0.75rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.table__rows_container--scroll-x {
  overflow: auto;
  overflow-x: auto;
}

.table__rows_container--scroll-auto {
  overflow: auto;
  overflow-x: hidden;
}

.table__rows_container {
  overflow: scroll;
  overflow-x: hidden;
  flex-grow: 1;
}

.table__header--field {
  font-weight: 500;
  padding: 5px;
  text-align: left;
  word-break: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table__header--field svg {
  margin-left: 5px;
  color: var(--secondairy-medium-gray);
  opacity: 0.75;
  font-size: 0.9rem;
}

.table__row--field {
  font-weight: 300;
  align-self: center;
  padding: 5px;
  word-break: break-all;
  display: flex;
}

.table__header-dynamic {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-template-columns: 90px 150px 120px 100px repeat(auto-fill, minmax(min-content, 300px));
  width: 100%;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 0.75rem;
}

.table__row-dynamic {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-template-columns: 90px 150px 120px 100px repeat(auto-fill, minmax(min-content, 300px));
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.75rem;
}

.table__header-2 {
  display: grid;
  grid-template: 35px / repeat(2, 1fr);
  width: calc(100% - 1em);
  font-weight: 500;
  padding: 5px;
  font-size: 0.75rem;
}

.table__row-2 {
  display: grid;
  grid-template: 35px / repeat(2, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.75rem;
}

.table__header-3 {
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  width: calc(100% - 1em);
  font-weight: 500;
  padding: 5px;
  font-size: 0.75rem;
}

.table__row-3 {
  display: grid;
  grid-template: 35px / repeat(3, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.75rem;
}

.table__header-4 {
  display: grid;
  grid-template: 35px / repeat(4, 1fr);
  width: calc(100% - 1em);
  font-weight: 500;
  padding: 5px;
  font-size: 0.75rem;
}

.table__row-4 {
  display: grid;
  grid-template: 35px / repeat(4, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.75rem;

}

.table__row-4 {
  display: grid;
  grid-template: 35px / repeat(4, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.8rem;
}

.table__header-5 {
  display: grid;
  grid-template: 35px / repeat(5, 1fr);
  width: calc(100% - 1em);
  font-weight: 500;
  font-size: 0.75rem;
  padding: 5px;
}

.table__row-5 {
  display: grid;
  grid-template: 35px / repeat(5, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.7rem;
}

.table__header-6 {
  display: grid;
  grid-template: 35px / repeat(6, 1fr);
  width: calc(100% - 1em);
  font-weight: 900;
  font-size: 0.75rem;
  padding: 5px;
}

.table__row-6 {
  display: grid;
  grid-template: 35px / repeat(6, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.7rem;
}

.table__header-7 {
  display: grid;
  grid-template: 35px / repeat(7, 1fr);
  width: calc(100% - 1em);
  font-weight: 900;
  font-size: 0.75rem;
  padding: 5px;
  font-size: 0.75rem;
}

.table__row-7 {
  display: grid;
  grid-template: 30px / repeat(7, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.7rem;
}

.table__header-8 {
  display: grid;
  grid-template: 35px / repeat(8, 1fr);
  width: calc(100% - 1em);
  font-weight: 900;
  font-size: 0.75rem;
  padding: 5px;
  font-size: 0.75rem;
}

.table__row-8 {
  display: grid;
  grid-template: 35px / repeat(8, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.7rem;
}

.table__header-9 {
  display: grid;
  grid-template: 35px / repeat(9, 1fr);
  width: calc(100% - 1em);
  font-weight: 500;
  font-size: 0.75rem;
  padding: 5px;
}

.table__row-9 {
  display: grid;
  grid-template: 30px / repeat(9, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.7rem;
}

.table__header-10 {
  display: grid;
  grid-template: 35px / repeat(10, 1fr);
  width: calc(100%);
  font-weight: 500;
  font-size: 0.75rem;
  padding: 5px;
}

.table__row-10 {
  display: grid;
  grid-template: 30px / repeat(10, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.7rem;
}

.table__header-11 {
  display: grid;
  grid-template: 35px / repeat(11, 1fr);
  width: calc(100% - 1em);
  font-weight: 500;
  font-size: 0.75rem;
  padding: 5px;
}

.table__row-11 {
  display: grid;
  grid-template: 30px / repeat(11, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.7rem;
}

.table__row-3.odd {
  background-color: var(--secondairy-ultra-light-gray);
  border-radius: 7.5px;
}

.table__row-3:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
}

.table__row-4:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
}

.table__row-5:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
}

.table__row-6:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
}

.table__row-7:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
}

.table__row-8:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
}

.table__row-9:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
}

.table__row-10:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
}

.table__row-11:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
}

table {
  border-collapse: collapse;
  width: 100%;
}

table .table__header--field {
  padding: 12.5px 10px;
}

.table__row td {
  padding: 12.5px 10px;
}

.table__row td:first-child {
  border-bottom-left-radius: 7.5px;
  border-top-left-radius: 7.5px;
}

.table__row td:last-child {
  border-bottom-right-radius: 7.5px;
  border-top-right-radius: 7.5px;
}

.table__row:nth-of-type(odd) {
  background-color: var(--secondairy-ultra-light-gray);
}

.table a:nth-child(odd) div {
  background-color: var(--secondairy-ultra-light-gray);
}

.table a:nth-child(even) div {
  background-color: transparent;
}

.table__secondairy-header-3 {
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  width: 100%;
  font-weight: 500;
  font-size: 0.7rem;
  margin-bottom: 10px;
}

.table__secondairy-header-4 {
  display: grid;
  grid-template: auto / repeat(4, 1fr);
  width: 100%;
  font-weight: 500;
  font-size: 0.7rem;
  margin-bottom: 10px;
}

.table__secondairy-header-5 {
  display: grid;
  grid-template: auto / repeat(4, 1fr);
  width: 100%;
  font-weight: 500;
  font-size: 0.7rem;
  margin-bottom: 10px;
}

.table__secondairy-header-6 {
  display: grid;
  grid-template: auto / repeat(5, 1fr);
  width: 100%;
  font-weight: 500;
  font-size: 0.7rem;
  margin-bottom: 10px;
}

.table__secondairy-header--field {
  padding-left: 5px;
}

.table__secondairy-row-4 {
  display: grid;
  grid-template: auto / repeat(4, 1fr);
  font-size: 0.7rem;
  color: var(--secondairy-light-medium-gray);
  width: 100%;
  padding: 1.5px 10px;
}

/*  ELEMENTS  */

/*  Forms | Inputs | Dropdowns */

.input_field {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 0.7rem;
  width: 100%;
  margin-bottom: 7.5px;
}

.input_field__label {
  margin-left: 2.5px;
  font-size: 0.7rem;
  font-weight: 400;
}

.input_field__input_box--text {
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 4px;
  margin-top: 5px;
  width: 100%;
  height: 30px;
  resize: vertical;
  padding: 7.5px;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
}

.input_field__input_box--text.loading {
  background: linear-gradient(90deg, var(--secondairy-light-gray) 25%, var(--secondairy-ultra-light-gray) 50%, var(--secondairy-light-gray) 75%);
  background-size: 200% 100%;
  animation: skeleton-loading-input 1.5s infinite;
  pointer-events: none;
  /* Disable interactions */
  color: transparent;
  /* Hide text color */
}

.input_field__input_box--text.big {
  height: 40px;
}

.input_field__input_box--text.static {
  cursor: pointer;
}

.input_field__input_box--text--inrow {
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 4px;
  width: 100%;
  height: 25px;
  resize: vertical;
  padding: 7.5px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
  background-color: transparent;
  border-radius: 5px;
}

.input_field__input_box--text--inrow:focus {
  outline: none;
  border: var(--secondairy-light-medium-gray) solid 1.5px;

}

.input_field__input_box--text--inrow::placeholder {
  color: var(--secondairy-light-medium-gray);
}

.input_field__input_box--text::placeholder {
  font-style: italic;
  color: var(--secondairy-light-medium-gray);
}

.input_field__input_box--textarea {
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 4px;
  margin-top: 5px;
  width: 100%;
  min-height: 30px;
  resize: vertical;
  padding: 7.5px;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
}

.input_field__input_box--textarea:focus {
  outline: none;
  border: var(--secondairy-light-medium-gray) solid 1.5px;

}

.input_field__input_box--textarea::placeholder {
  font-style: italic;
  color: var(--secondairy-light-medium-gray);
}

.input_field__input_box--text.dropdown {
  cursor: pointer;
  height: 30px;
}

.input_field__input_box--text.filter {
  cursor: pointer;
  height: 40px;
  box-shadow: var(--border-shadow-min);
  background-color: white;
  border: none;
  height: 30px;
}

.input_field__input_box--text:focus {
  border: var(--primary-green) solid 1.5px;
  outline: none;
}

.input_field--combo-3-colmn {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 5px;
}

.input_field--combo-5050 {
  display: flex;
  width: 100%;
  gap: 5px;
}

.input_field--combo-5050 .input_field {
  width: 50%;
}

.input_field--combo-7030 {
  display: flex;
  width: 100%;
  gap: 5px;
}

.input_field--combo-7030 .input_field:first-child {
  width: 70%;
}

.input_field--combo-7030 .input_field:nth-child(2) {
  width: 30%;
}

.input_field--combo-3070 {
  display: flex;
  width: 100%;
  gap: 5px;
}

.input_field--combo-3070 .input_field:first-child {
  width: 30%;
}

.input_field--combo-3070 .input_field:nth-child(2) {
  width: 70%;
}

.input_field__dropdown {
  position: absolute;
  top: 45px;
  width: 100%;
  list-style: none;
  background-color: var(--secondairy-ultra-light-gray);
  max-height: 0px;
  display: none;
  pointer-events: none;
  /* padding: 3px 0px; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 100;
  border: var(--secondairy-light-gray) solid 1px;
  border-top: none;
  overflow-y: auto;
}

.input_field__dropdown::-webkit-scrollbar {
  width: 0.5rem;
}

.input_field__dropdown.active {
  max-height: 300px;
  height: fit-content;
  display: inline;
  pointer-events: all;
  transition: all 0.5s ease-in-out;
}

.input_field__dropdown li {
  color: var(--secondairy-medium-gray);
  padding: 8px 10px;
  font-size: 0.75rem;
  cursor: pointer;
}

.input_field__dropdown li:hover {
  background-color: white;
  color: black;
}

.input_field__dropdown.inherit {
  position: inherit;
}

.search-bar {
  background-color: white;
  box-shadow: var(--border-shadow-min);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px;
  font-size: 0.75rem;
  font-weight: 500;
  height: 30px;
  width: 120px;
  cursor: pointer;
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;
}

.search-bar.dropdown {
  box-shadow: none;
  border: none;
  border-radius: 0;
  width: 100%;
  border-bottom: var(--secondairy-light-gray) solid 1px;
  padding-left: 10px;
}

.search-bar.dropdown:focus {
  outline: none;
}

.form__section {
  padding: 10px;
  border: var(--secondairy-light-gray) solid 1px;
  border-radius: 10px;
  overflow-y: scroll;
}

.form__section::-webkit-scrollbar {
  display: none;
}

.form__section h1 {
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: var(--secondairy-medium-gray);
  width: 99%;
  text-align: right;
}

.input_error {
  display: none;
}

.input_error.active {
  display: block;
  font-size: 0.6rem;
  color: var(--red);
  padding: 0 0 15px 10px;
}

.floating__button--bottom-right {
  position: absolute;
  bottom: 50px;
  /* left: 92.5vw; */
  right: 50px;
  z-index: 49;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  border: none;
  box-shadow: var(--border-shadow-float);
  background-color: var(--primary-green);
  opacity: 1;
  cursor: pointer;
}

.floating__button--bottom-right:focus {
  outline: none;
}

#add--btn::after {
  content: "";
  position: absolute;
  top: 12.5px;
  left: calc(50% - 1px);
  width: 2px;
  height: 15px;
  background-color: white;
  cursor: pointer;
}

#add--btn::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 12.5px;
  width: 15px;
  height: 2px;
  background-color: white;
  cursor: pointer;
}

#scroll-down--btn::after {
  content: "";
  position: absolute;
  top: 12.5px;
  left: calc(50% - 1px);
  width: 2px;
  height: 15px;
  background-color: white;
  cursor: pointer;
}

#scroll-down--btn::before {
  content: "";
  position: absolute;
  top: 17px;
  left: 15px;
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-bottom: white 2px solid;
  border-right: white 2px solid;
  transform: rotate(45deg);
  background-color: none;
}

#scroll-up--btn::after {
  content: "";
  position: absolute;
  top: 12.5px;
  left: calc(50% - 1px);
  width: 2px;
  height: 15px;
  background-color: white;
  cursor: pointer;
}

#scroll-up--btn::before {
  content: "";
  position: absolute;
  top: 17px;
  left: 15px;
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-bottom: white 2px solid;
  border-right: white 2px solid;
  transform: rotate(45deg);
  background-color: none;
}

.secondairy-button {
  background-color: var(--secondairy-light-gray);
  color: var(--primary-black);
  border: none;
  border-radius: 5px;
  padding: 7.5px;
  font-size: 0.7rem;
  cursor: pointer;
  user-select: none;
}

.red-button-header {
  position: absolute;
  right: 20px;
  bottom: 0;
  background-color: var(--light-red);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px 15px;
  font-size: 0.7rem;
  font-weight: 700;
  height: 30px;
  min-width: 120px;
  box-shadow: var(--border-shadow);
  cursor: pointer;
  z-index: 100;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard syntax */
}

.gray-button-header {
  position: absolute;
  right: 20px;
  bottom: 0;
  background-color: var(--primary-black);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px 15px;
  font-size: 0.7rem;
  font-weight: 700;
  height: 30px;
  min-width: 120px;
  box-shadow: var(--border-shadow);
  cursor: pointer;
  z-index: 100;
  color: var(--secondairy-light-gray);
  user-select: none;
}

.green-button-header {
  position: absolute;
  right: 20px;
  bottom: 0;
  background-color: var(--primary-green);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px 15px;
  font-size: 0.7rem;
  font-weight: 700;
  height: 30px;
  min-width: 120px;
  box-shadow: var(--border-shadow);
  cursor: pointer;
  z-index: 100;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard syntax */
}

.green-button-header.popup {
  top: -50px;
  right: 0;
}

.green-button-header.popout {
  position: relative;
}

.options-button-header {
  position: relative;
  background-color: white;
  box-shadow: var(--border-shadow-min);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  width: auto;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7.5px;
  align-self: flex-end;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard syntax */
}

.options-button-header--red {
  position: relative;
  background-color: var(--ultra-light-red);
  box-shadow: var(--border-shadow-min);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  width: auto;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7.5px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard syntax */
}

.options-button-header--red:hover {
  background-color: var(--light-red);
}

.options-button-header.high-contrast {
  border: var(--secondairy-light-medium-gray) 1px solid;
}

.options-button-header.selected {
  background-color: var(--light-primary-green);
}

.options-button-header.selected svg {
  color: var(--primary-green);
}

.options-button-header.small {
  width: 50px;
}

.options-button-header.medium_small {
  width: 100px;
}

.options-button-header.medium {
  width: 150px;
}

.options-button-header.big {
  width: 250px;
}

.options-button-header svg {
  color: var(--secondairy-light-medium-gray);
  font-size: 1.5rem;
}

.options-button-header:hover {
  background-color: var(--secondairy-light-gray);
}

.add--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 400;
  cursor: pointer;

  background-color: var(--light-primary-green);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  height: 30px;

  transition: all 300ms ease-in-out;

  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard syntax */
}

.add--btn.mobile {
  width: 100%;
  height: 60px;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  background-color: var(--light-primary-green);
  color: white;
  border: none;
}

.add--btn.mobile svg {
  font-size: 1.8rem;
}

.more-products--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25px;
  background-color: var(--secondairy-ultra-light-gray);
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 2.5px;
  margin-top: 5px;
  font-size: 1.5rem;
  color: var(--secondairy-medium-gray);
  font-weight: 400;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard syntax */
}

.popup__options-button {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: var(--primary-green);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px;
  font-size: 0.75rem;
  font-weight: 700;
  height: 30px;
  width: 120px;
  cursor: pointer;
  text-transform: uppercase;
}

.popup__options-button--secondairy {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: var(--secondairy-light-gray);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px;
  font-size: 0.75rem;
  font-weight: 700;
  height: 30px;
  width: 120px;
  cursor: pointer;
  text-transform: uppercase;
}

.more-products--btn:hover {
  background-color: var(--secondairy-light-gray);
}

.green-action-button {
  display: flex;
  align-items: center;
  background-color: var(--light-primary-green);
  box-shadow: var(--border-shadow);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px 12.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  min-width: 90px;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--primary-black);
  gap: 7.5px;
}

.gray-action-button {
  display: flex;
  align-items: center;
  background-color: var(--secondairy-light-gray);
  box-shadow: var(--border-shadow);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px 12.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  min-width: 90px;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--primary-black);
  gap: 7.5px;
}

.red-action-button {
  display: flex;
  align-items: center;
  background-color: var(--light-red);
  box-shadow: var(--border-shadow);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px 12.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  min-width: 90px;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--primary-black);
  gap: 7.5px;
}

.red-action-button svg,
.gray-action-button svg,
.green-action-button svg {
  font-size: 1rem;
}

.gray-action-button.selecting {
  background-color: var(--secondairy-ultra-light-gray);
  box-shadow: none;
  border: 1px solid var(--secondairy-light-gray);
  border-radius: 7.5px;
  padding: 7.5px 12.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  min-width: 90px;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--primary-black);
}

.red-action-button.selecting {
  background-color: var(--secondairy-ultra-light-gray);
  box-shadow: none;
  border: 1px solid var(--light-red);
  border-radius: 7.5px;
  padding: 7.5px 12.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  min-width: 90px;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--primary-black);
  gap: 7.5px;
}

.red-action-button svg {
  font-size: 1rem;
}

.red-action-button.selected {
  border: 2px var(--dark-red) solid;
  background-color: var(--ultra-light-red);
}

.gray-action-button.selected {
  border: 2px var(--secondairy-medium-gray) solid;
  background-color: var(--secondairy-light-gray);
}

.green-action-button.selected {
  border: 2px var(--dark-green) solid;
  background-color: var(--ultra-light-green);
}

.button_create_new {
  color: var(--primary-black);
  width: 100%;
  padding: 2.5px 10px;
  font-size: 0.65rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: none;
  border-bottom: 1px solid var(--secondairy-light-gray);
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.button_create_new:hover {
  background-color: var(--secondairy-light-medium-gray);
}

.button_create_new svg {
  color: var(--secondairy-light-medium-gray);
}

.button_create_new:hover svg {
  color: var(--secondairy-light-gray);
}

.back_button {
  position: absolute;
  top: 15px;

  display: flex;
  align-items: center;
  font-size: 0.6rem;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--secondairy-light-medium-gray);
  cursor: pointer;
  z-index: 100;
  opacity: 0.75;
}

.back_button:hover {
  color: var(--primary-green);
}

.back_button svg {
  margin-right: 5px;
  width: 18px;
}

.load_more_button {
  border-radius: 5px;
  padding: 7.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--secondairy-medium-gray);
  background-color: transparent;
  border: var(--secondairy-light-medium-gray) solid 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  transition: all 125ms ease-in-out;
}

.load_more_button:hover {
  background-color: var(--secondairy-light-gray);
}

.load_more_button--table {
  border: none;
  padding: 7.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--secondairy-medium-gray);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  transition: all 125ms ease-in-out;
}

.load_more_button--table:hover {
  background-color: var(--secondairy-ultra-light-gray);
}

/*  Containers  */

.header__options {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  row-gap: 7.5px;
  min-width: 25%;
  flex-grow: 1;
  /* flex-direction: column; */
  column-gap: 7.5px;
  z-index: 40;
  height: 100%;
  padding: 0;
  left: 0;
}

.header__options.grid-2 {
  display: grid;
  grid-template: auto / 1fr 1fr;
}

.header__options--right {
  display: flex;
  align-items: flex-end;
  /* flex-direction: column; */
  column-gap: 7.5px;
  z-index: 60;
  height: 100%;
  padding: 0;
  right: 0;
  margin-left: auto;
  margin-right: 10px;
}

.header__options--top-right {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 202;
}

.header__options--top-right .option_button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondairy-medium-gray);
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  width: 27.5px;
  height: 27.5px;
  padding: 5px;
  box-shadow: var(--border-shadow-min);
}

.header__options--right .green-button-header,
.header__options--right .red-button-header,
.header__options--right .gray-button-header {
  position: relative;
  right: 0;
}


.header__options--right-template {
  position: absolute;
  top: 10vh;
  right: 20px;
  z-index: 202;
  transform: translateY(-100%);
  display: flex;
  align-items: flex-end;
  column-gap: 7.5px;
  padding: 0;
  max-width: 55%;
}

.header__option--slider {
  height: 30px;
  padding: 7.5px;
  border-radius: 7.5px;
  background-color: white;
  box-shadow: var(--border-shadow-min);
  color: var(--secondairy-medium-gray);
  text-transform: uppercase;
}

.header__option--slider .slider__text {
  font-weight: 600;
}

.header__filters {
  display: flex;
  align-items: flex-end;
  width: auto;
  max-width: 75%;
  padding: 0;
  right: 0;
  bottom: 0;
  gap: 7.5px;
  z-index: 40;
}

.header__options.hide {
  display: none;
  pointer-events: none;
}

.header__options--right.hide {
  display: none;
  pointer-events: none;
}

.header__filters.hide {
  display: none;
  pointer-events: none;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12.5px;
  width: 100%;
  height: 50px;
}

.pagination .pagination__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  padding: 5px;
  color: var(--secondairy-medium-gray);
  font-size: 2rem;
  border: solid 1px var(--secondairy-medium-gray);
  cursor: pointer;
}

.pagination .pagination__btn:hover {
  background-color: var(--secondairy-light-gray);
}

.pagination .pagination__btn.grayed-out {
  color: var(--secondairy-light-medium-gray);
  border: solid 1px var(--secondairy-light-medium-gray);
  cursor: not-allowed;
}

/*  Components  */

/*  Containers  */

.all_done__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.all_done__container img {
  height: 25%;
  opacity: 0.5;
}

.all_done__container p {
  color: var(--secondairy-medium-gray);
  margin-top: 15px;
}

/*  Animations  */

@keyframes skeleton-loading-input {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}