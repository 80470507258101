#new-customer-form-popup-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 501;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  opacity: 100%;
  transition: all 200ms ease-in-out;
  pointer-events: all;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#new-customer-form-popup-wrapper.hidden {
  opacity: 0%;
  pointer-events: none;
}

#new-customer-form-popup-wrapper #customers__btn-save--customer {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: var(--primary-green);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px 15px;
  font-size: 0.7rem;
}

#new-customer-form-popup-wrapper .customer_create_popup-header {
  position: relative;
  width: 80%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#new-customer-form-popup-wrapper .body__section__header {
  display: flex;
  justify-content: center;
  align-items: center;
}

#new-customer-form-popup-wrapper #new-customer-form-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 60%;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  border: var(--secondairy-light-medium-gray) solid 1px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

#new-customer-form-popup-wrapper #new-customer-form-popup .input_field__input_box--text {
  height: 30px;
  margin-top: 5px;
}

#new-customer-form-popup-wrapper .close_button {
  position: absolute;
  top: -1px;
  right: -1px;
  cursor: pointer;
  padding: 5px 7.5px;
  border-radius: 10px;

  transition: all 0.3s ease;
}

#new-customer-form-popup-wrapper .close_button svg {
  font-size: 1rem;
  color: var(--secondairy-medium-gray);
}

#new-customer-form-popup-wrapper .close_button:hover {
  background-color: var(--secondairy-light-gray);
}


#new-customer-form-popup-wrapper .form_wrapper {
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 0;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

#new-customer-form-popup-wrapper .form_wrapper .form__section {
  position: relative;
  padding: 10px !important;
  padding-top: 35px !important;
  border: var(--secondairy-light-gray) solid 1px !important;
  border-radius: 10px;
  overflow-y: scroll;
  height: auto;
}

#new-customer-form-popup-wrapper .form_wrapper .form__section h1 {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 700;
  text-transform: none;
  font-size: 0.75rem;
  width: auto;
}

#new-customer-form-popup-wrapper .form_wrapper .secondairy-button {
  /* requires index.css */
  height: 30px;
  margin-top: 5px;
  margin-left: 5px;
}

#new-customer-form-popup-wrapper .form_wrapper #popup__close-icon {
  position: absolute;
  color: var(--primary-black);
  font-size: 0.7rem;
  right: 8px;
  top: 8px;
  cursor: pointer;
  background-color: var(--secondairy-ultra-light-gray);
  border: 1px solid var(--secondairy-light-gray);
  border-radius: 20px;
  width: 25px;
  height: 18px;
  padding: 3px;
}

#new-customer-form-popup-wrapper .form_wrapper .dropdown_field {
  background-color: var(--secondairy-ultra-light-gray);
  border: 1px solid var(--secondairy-light-gray);
  padding: 0;
  width: 100%;
  height: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
}

#new-customer-form-popup-wrapper .form_wrapper .dropdown_field .limit {
  padding: 7.5px 5px;
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

#new-customer-form-popup-wrapper .form_wrapper .dropdown_field .limit > svg {
  font-size: 1rem;
}

#new-customer-form-popup .dropdown_field .circular_loading_spinner__wrapper {
  padding: 10px 0;
}

#new-customer-form-popup-wrapper .form_wrapper .dropdown_field__item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 10px 10px;
}

#new-customer-form-popup-wrapper .form_wrapper .dropdown_field__item:hover {
  background-color: var(--secondairy-light-gray);
}

#new-customer-form-popup-wrapper .form_wrapper .dropdown_field__item > div {
  color: var(--secondairy-medium-gray);
  cursor: pointer;
}

#new-customer-form-popup-wrapper .form_wrapper .other_options {
  padding: 10px 0;
}

#new-customer-form-popup-wrapper .form_wrapper .api_options {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  width: 100%;
}

#new-customer-form-popup-wrapper .form_wrapper .business-central-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border: 1px solid var(--secondairy-light-medium-gray);
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--secondairy-light-medium-gray);
}

#new-customer-form-popup-wrapper .form_wrapper .business-central-icon.clicked {
  background-color: var(--light-primary-green);
  border-color: var(--primary-green);
  color: white;
}

#new-customer-form-popup-wrapper .form_wrapper .business-central-icon svg {
  width: 20px;
  height: 20px;
}

#new-customer-form-popup-wrapper #create-customer-custom-details {
  display: grid;
  grid-template: auto / auto;
  margin-bottom: 0;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

#new-customer-form-popup-wrapper #create-customer-custom-details .custom_details__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 250px));
  gap: 10px;
}

#new-customer-form-popup-wrapper #create-customer-custom-details .custom_details__wrapper .checkbox__container {
  height: auto;
}

#new-customer-form-popup-wrapper #create-customer-custom-details .custom_details__wrapper .checkbox__container .checkbox--text,
#new-customer-form-popup-wrapper #create-customer-custom-details .custom_details__wrapper .input_box__container .input_box__title,
#new-customer-form-popup-wrapper #create-customer-custom-details .custom_details__wrapper .dropdown__container-dropdown .dropdown__title
 {
  font-size: 0.7rem;
  font-weight: 400;
  color: black;
}
