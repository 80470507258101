.image_uploader__container {
  position: relative;
  height: 100%;
  cursor: pointer;
}

.image_uploader__container > span {
  position: relative;
  height: 100%;
}

.image_uploader__container > span .ant-upload {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

.image_uploader__container > span .ant-upload:hover {
  border-color: var(--primary-green) !important;
}

.image_uploader__container > span .ant-upload > span {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}


