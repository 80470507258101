.popup_window__container.create-activity .popup_body {
  padding: 10px;
  padding-bottom: 50px;
}

.popup_window__container.create-activity .popup_body .action-buttons {
  display: flex;
  height: 50px;
  gap: 7.5px;
}

.popup_window__container.create-activity .popup_body .action-buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

.popup_window__container.create-activity .popup_body .action-buttons .gray-action-button.selecting {
  border-color: var(--secondairy-light-medium-gray);
}

.popup_window__container.create-activity .priority_container {
  display: grid;
  grid-template: 15px 1fr 1fr / 15px 1fr 1fr;
  grid-template-areas:
      "none urgent not-urgent"
      "important options options"
      "not-important options options"
  ;
}

.popup_window__container.create-activity .priority_container .priority_title {
  font-size: 0.6rem;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--secondairy-medium-gray)
}

.popup_window__container.create-activity .priority_container .priority_title.important {
  grid-area: important;
  transform: rotate(180deg);
  writing-mode: vertical-rl
}

.popup_window__container.create-activity .priority_container .priority_title.not-important {
  grid-area: not-important;
  transform: rotate(180deg);
  writing-mode: vertical-rl
}

.popup_window__container.create-activity .priority_container .priority_title.urgent {
  grid-area: urgent;
}

.popup_window__container.create-activity .priority_container .priority_title.not-urgent {
  grid-area: not-urgent;
}

.popup_window__container.create-activity .priority_options {
  grid-area: options;
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  gap: 5px;
}

.popup_window__container.create-activity .priority_container .priority_option {
  width: 100%;
  padding-top: 50%;
  position: relative;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  border-radius: 7.5px;
}

.popup_window__container.create-activity .priority_container .priority_option.selected {
  border: 2px solid var(--primary-black) !important;
}

.popup_window__container.create-activity .priority_container .priority_option .priority_option_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
}

.popup_window__container.create-activity .priority_container .priority_option.one {
  background-color: var(--light-red);
  color: var(--dark-red);
  border: 1px solid var(--dark-red)
}

.popup_window__container.create-activity .priority_container .priority_option.two {
  background-color: var(--special-gold-light);
  color: var(--special-gold-dark);
  border: 1px solid var(--special-gold-dark)
}

.popup_window__container.create-activity .priority_container .priority_option.three {
  background-color: var(--light-blue);
  color: var(--dark-blue);
  border: 1px solid var(--dark-blue)
}

.popup_window__container.create-activity .priority_container .priority_option.four {
  background-color: var(--light-green);
  color: var(--dark-green);
  border: 1px solid var(--dark-green)
}

.popup_window__container.create-activity .priority_container .priority_option .priority_option_content {
  font-size: 1.5rem;
  font-weight: 700;
}

.popup_window__container.create-activity .email-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
}

.popup_window__container.create-activity .email-message-container .download-email-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 7.5px;
  background-color: var(--light-primary-green);
  border: 1px solid var(--primary-green);
  color: var(--secondairy-medium-gray);
  width: 100%;
}

.popup_window__container.create-activity .email-message-container .download-email-button svg {
  font-size: 2.25rem;
  color: var(--secondairy-medium-gray);
}