.search_bar--in-list {
    position: relative;
    outline: none;
    border: none;
    border-bottom: solid 1px var(--secondairy-light-medium-gray);
    padding: 5px 0;
    margin-bottom: 7.5px;
    color: var(--secondairy-medium-gray);
}


.search_bar--in-list svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem;
    cursor: pointer;
}

.search_bar--in-list input {
    border: none;
    border-radius: 7.5px;
    width: 100%;
    height: 100%;
    padding: 7.5px 2.5px;
    font-size: 0.75rem;
    color: var(--secondairy-medium-gray);
    font-weight: 400;
    font-style: italic;
}

.search_bar--in-list input::placeholder {
    font-style: italic;
    color: var(--secondairy-medium-gray);
}

.search_bar--in-list input:focus {
    outline: none;
}

/* MOBILE */

.search_bar--in-list.mobile {
    height: 50px;
}

.search_bar--in-list.mobile svg {
    font-size: 1.7rem;
    margin-right: 15px;
}

.search_bar--in-list.mobile input {
    background-color: transparent;
    font-size: 1rem;
    font-style: normal;
}

.search_bar--in-list.mobile input::placeholder {
    font-style: normal;
    color: var(--secondairy-light-medium-gray);
}