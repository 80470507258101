.dropdown_customer_container--mobile {
    position: relative;
}

.dropdown_customer_container--mobile > svg {
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 10;
    font-size: 2rem;
    color: var(--secondairy-medium-gray);
}

.dropdown_customer_container--mobile .input_text--mobile {
    position: relative;
    z-index: 5;
}

.dropdown_customer_container--mobile .dropdown_list_container {
    position: inherit;
    top: -20px;
    max-height: 175px;
    overflow: scroll;
    padding-top: 5px;
    border: 1px solid var(--secondairy-light-gray-plus);
    border-radius: 5px;
}

.dropdown_customer_container--mobile .dropdown_list_container .dropdown_item {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 7.5px;
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--primary-black);
    padding: 10px 7.5px;
}

.dropdown_customer_container--mobile .create_new_customer--btn {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--secondairy-light-gray-plus);
    padding: 7.5px 0;
}

.dropdown_customer_container--mobile .create_new_customer--btn > svg {
    font-size: 2rem;
    color: var(--secondairy-medium-gray);
}

.dropdown_customer_container--mobile .create_new_customer--btn > p {
    margin-left: 15px;
    color: var(--secondairy-medium-gray);
    font-size: 0.9rem;
}

