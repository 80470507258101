/* Add this to your CSS file */
.activities-calendar {
  padding: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.activities-calendar .calendar {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
}