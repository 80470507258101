/* .dropdown__container.customer {
  position: relative;
  width: 150px;
  margin-left: 10px;
} */

.input_field__input_box--text.input-dropdown {
  /* requires index.css */
  display: flex;
  align-items: center;
  cursor: text;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
}

.input_field__dropdown {
  font-size: 0.75rem;
}

.input_field__input_box--text.input-dropdown::placeholder {
  color: var(--secondairy-medium-gray);
  font-weight: 400;
}

.customer-list-dropdown--item.customer {
  display: grid;
  grid-template: auto / 40% 5% 25% 5% 25%;
}

.customer-list-dropdown--item div {
  margin-right: 15px;
}

.dropdown__container.customer .input_field__dropdown {
  border: var(--secondairy-light-medium-gray) 1px solid;
  border-top: none;
  top: 40px;
  width: 100%;
}

.dropdown__container.customer .input_field__dropdown.inherit {
  top: 0;
}


/* ANCHOR MOBILE */

@media only screen and (max-width: 768px) {
  
  .input_field__input_box--text.input-dropdown {
    height: 50px;
    font-style: normal;
  }
  
  .input_field__input_box--text.input-dropdown::placeholder {
    font-style: normal;
  }

}

