.performance_detail__wrapper {
    height: 100%;
}

.performance_detail__wrapper .table__header-5 {
    grid-template-columns: 150px 200px 100px 100px 1fr;
}

.performance_detail__wrapper .table__row-5 {
    grid-template-columns: 150px 200px 100px 100px 1fr;
}

.performance_detail__wrapper .table__header-6 {
    grid-template-columns: 150px 200px 100px 100px 1fr 125px;
}

.performance_detail__wrapper .table__row-6 {
    grid-template-columns: 150px 200px 100px 100px 1fr 125px;
}

.performance_detail__wrapper .table__header-8 {
    grid-template-columns: 75px 150px 200px 100px 100px 1fr 75px 1fr;
}

.performance_detail__wrapper .table__row-8 {
    grid-template-columns: 75px 150px 200px 100px 100px 1fr 75px 1fr;
}