.body__users-admin {
    height: 100%;
}

.body__users-admin #body-section-admin-users-1 {
    height: 100%;
    overflow: hidden;
}

.body__users-admin .table__header-8 {
    grid-template: 35px / 0.35fr 1fr 1fr 0.8fr 100px 100px 1fr 1.7fr;
}

.body__users-admin .table__row-8 {
    grid-template: 35px / 0.35fr 1fr 1fr 0.8fr 100px 100px 1fr 1.7fr;
}

.body__users-admin .table__row-8 .table__row--field.actions {
    display: flex;
    align-items: center;
    justify-content: right;
}

.button-create {
    background-color: var(--primary-green);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
}

.app-version-container {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  width: 100%;
}

.app-version-container .interest-container {
  padding: 8px 16px;
  border: 1px solid var(--secondairy-light-medium-gray);
  border-radius: 7.5px;
  font-size: 0.8rem;
  color: var(--secondairy-medium-gray);
  cursor: pointer;
  transition: all 0.3s ease;
  user-select: none;
  width: 100%;
}

.app-version-container .interest-container:hover {
  background-color: var(--secondairy-light-gray);
}

.app-version-container .interest-container.selected {
    background-color: var(--secondairy-light-gray);
    border-color: var(--secondairy-light-medium-gray);
}