.alert--mobile__wrapper {
  display: flex;
  opacity: 0;
  justify-content: center;
  position: absolute;
  z-index: 1100;
  bottom: 20px;
  width: 100%;
  transition: all 400ms ease-in-out;
  pointer-events: none;
}

.alert--mobile__wrapper.active {
  pointer-events: all;
  opacity: 1;
  transform: translateY(-10px);
}

.alert--mobile__wrapper .alert--mobile {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  height: 100px;
  width: calc(100% - 20px);
  border-radius: 7.5px;
  padding: 7.5px;
}

.alert--mobile__wrapper .alert__close {
  position: absolute;
  top: 2.5px;
  right: 5px;
}

.alert--mobile__wrapper .alert__icon {
  font-size: 1.5rem;
  width: 50px;
}

.alert--mobile__wrapper.error .alert--mobile {
  background-color: var(--light-red);
  border: solid var(--red) 1px;
  color: var(--red);
}

.alert--mobile__wrapper.notification .alert--mobile {
  background-color: var(--secondairy-light-gray);
  border: solid var(--secondairy-medium-gray) 1px;
  color: var(--secondairy-medium-gray);
}

.alert--mobile__wrapper.success .alert--mobile {
  background-color: var(--green);
  border: solid var(--dark-green) 1px;
  color: var(--dark-green);
}

