#customers-to-contact-wrapper {
  height: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

#customers-to-contact-wrapper .flex {
  display: flex;
  align-items: center;
}

#customers-to-contact-wrapper h2 {
  margin-right: 20px;
}

#customers-to-contact-wrapper .filters-wrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

#customers-to-contact-wrapper .contact-filter {
  cursor: pointer;
  padding-bottom: 0px;
  color: var(--primary-green);
  font-variant: small-caps;
  font-size: 0.8rem;
  font-weight: 700;
}

#customers-to-contact-wrapper .contact-filter.underlined {
  border-bottom: 1px solid var(--primary-green);
}

#customers-to-contact-wrapper .customers-to-contact__list {
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}

#customers-to-contact-wrapper .customers-to-contact__list .all_done__container {
  position: absolute;
  top: calc(50% - 10px);
  left: 50%;
  transform: translate(-50%, -50%);
}

#customers-to-contact-wrapper .customers-to-contact__list ul {
  display: flex;
  flex-direction: column;
  gap: 2.5px;
}

#customers-to-contact-wrapper .customer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12.5px 10px;
  color: var(--dark-red);
  background-color: var(--hyper-light-red);
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
}

#customers-to-contact-wrapper .customer-item.grid-3 {
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.33fr;
}

#customers-to-contact-wrapper .customer-item.to_be_expired {
  background-color: var(--secondairy-light-gray);
}

#customers-to-contact-wrapper .customer-name {
  font-weight: 400;
}

#customers-to-contact-wrapper .days-left {
  color: var(--secondairy-medium-gray);
}

#customers-to-contact-wrapper .circular_loading_spinner__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}