#add-tag-popup {
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% + 10vh + 15px);
}

.popup__box {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    background-color: white;
    padding: 15px;
    cursor: auto;
    overflow: scroll;
}

.form__section_title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--primary-green);
    margin-bottom: 40px;
}

#add-tag-popup .create_tag_container {
    width: 90%;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

#add-tag-popup .create_tag_container .input_field__input_box--text {
    margin-top: 0px;
}

#add-tag-popup .create_tag_container .green-action-button {
    height: 40px;
}

#add-tag-popup .tag_list {
    margin-top: 15px;
    width: 25%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    max-height: 300px;
}

#add-tag-popup .tag_list--item {
    position: relative;
    padding: 7.5px;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#add-tag-popup .tag_list--item.delete {
    border: solid 1px var(--dark-red);
    color: var(--dark-red);
}

#add-tag-popup .tag_list--item.delete svg {
    position: absolute;
    color: var(--dark-red);
    right: 0;
}

#add-tag-popup .tag_list--item.normal {
    background-color: var(--secondairy-light-gray);
    color: var(--secondairy-medium-gray);
}

#add-tag-popup .tag_list--item.red {
    background-color: var(--light-red);
    color: var(--dark-red);
}

#add-tag-popup .tag_list--item.green {
    background-color: var(--light-green);
    color: var(--dark-green);
}

#add-tag-popup .tag_list--item.blue {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}

#add-tag-popup .tag_list--item.gold {
    background-color: var(--special-gold-light);
    color: var(--special-gold-dark);
}

#add-tag-popup .color_options {
    display: flex;
    gap: 10px;
}

#add-tag-popup .tag_color {
    padding: 7.5px;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    cursor: pointer;
}

#add-tag-popup .tag_color:hover {
    border-width: 3px !important;
}

#add-tag-popup .tag_color.normal {
    background-color: var(--secondairy-light-gray);
    border: 2px solid var(--secondairy-medium-gray);
}

#add-tag-popup .tag_color.red {
    background-color: var(--light-red);
    border: 2px solid var(--dark-red);
}

#add-tag-popup .tag_color.green {
    background-color: var(--light-green);
    border: 2px solid var(--dark-green);
}

#add-tag-popup .tag_color.blue {
    background-color: var(--light-blue);
    border: 2px solid var(--dark-blue);
}

#add-tag-popup .tag_color.gold {
    background-color: var(--special-gold-light);
    border: 2px solid var(--special-gold-dark);
}