.vendor__dropdown__container {
  position: relative;
  width: 100%;
}

.vendor__dropdown__container .input_field__input_box--text {
  /* requires index.css */
  display: flex;
  align-items: center;
}

.vendor__dropdown__container .input_field__input_box--text p {
  font-weight: 400;
  font-style: normal;
}

.vendor__dropdown__container .input_field__input_box--text svg {
  margin-left: auto;
  height: 120%;
}

.vendor__dropdown__container .input_field__dropdown {
  top: 35px;
}

.vendor__dropdown__container .input_field__dropdown.inherit {
  top: 0px;
}

.vendor__dropdown__container .input_field__dropdown li {
  width: 100%;
  display: flex;
  align-items: center;
}

.vendor__dropdown__container .input_field__dropdown li .supplier__item__company_name {
  padding: 5px 2px 5px 0;
}

.vendor__dropdown__container .input_field__dropdown li .label__vendors {
  position: relative;
  width: 100%;
}

.vendor__dropdown__container .input_field__dropdown li div:first-child {
  width: 100%;
}

.vendor__dropdown__container .dropdown__container .input_field__input_box--text .dropdown-input--text_field {
  width: 85%;
  justify-content: space-between;
}