
.checkbox__container.mobile {
  padding: 7.5px;
  height: 60px;
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 20px;
  background-color: var(--secondairy-light-gray-plus);
  border-radius: 5px;
}

.checkbox--box__container.mobile {
  margin-right: 5px;
  margin-left: auto;
}

.checkbox--box.mobile {
  border: var(--secondairy-medium-gray) 1px solid;
}

.checkbox--box.checked.mobile {
  background-color: var(--secondairy-medium-gray);
}

.checkmark.checked.mobile .checkmark_stem,
.checkmark.checked.mobile .checkmark_kick {
  background-color: var(--secondairy-light-gray);
}

.checkbox--text.mobile {
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary-black);
}


