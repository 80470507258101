.flatpickr_wrapper--date_picker {
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  align-self: flex-end;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
  border: var(--secondairy-light-gray) 1px solid;
  font-weight: 400;
  cursor: pointer;
  background-color: white;
  margin: 0;
}

.flatpickr_wrapper--date_picker .icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  margin-right: 5px;
  pointer-events: all;
}

.flatpickr_wrapper--date_picker input {
  border: none;
  border-radius: 7.5px;
  width: 100%;
  height: 100%;
  padding: 7.5px;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
  font-weight: 400;
  text-align: left;
}

/* .flatpickr_wrapper--date_picker input::placeholder {
  font-style: italic;
  color: var(--secondairy-medium-gray);
  text-align: left;
} */

.flatpickr_wrapper--date_picker input:focus {
  outline: none;
}

.flatpickr_wrapper--date_picker p {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 7.5px;
  font-style: italic;
  pointer-events: none;
  color: var(--secondairy-light-medium-gray);
  font-weight: 300;
  pointer-events: none;
  background-color: transparent;
}

.flatpickr_wrapper--date_picker .date_picker_input {
  cursor: pointer;
}

.flatpickr_wrapper--date_picker.mobile {
  background-color: var(--secondairy-light-gray-plus);
  border-radius: 5px;
  /* background-color: transparent; */
  border: none;
  /* border-bottom: 1px var(--secondairy-light-gray-plus) solid; */
  height: 60px;
  width: 100%;

  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 15px;
  color: var(--primary-black);
  text-align: left;
  padding: 7.5px;  
}

.flatpickr_wrapper--date_picker.mobile p {
  font-weight: 300;
  opacity: 1;
  color: rgba(30, 30, 30, 0.33);
  font-style: normal;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  background-color: transparent;

}

.flatpickr_wrapper--date_picker.mobile .icon {
  color: var(--secondairy-medium-gray);
  margin-right: 15px;
}

.flatpickr_wrapper--date_picker.mobile input {
  background-color: var(--secondairy-light-gray-plus);
  color: var(--primary-black);
  font-size: 1rem;
  padding: 0;
  opacity: 1;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: left;
}

.flatpickr_wrapper--date_picker.mobile .flatpickr-mobile {
  text-align: left;
}