#popout-create-order {
    top: 0;
    align-items: flex-start;
}

#popout-create-order .popout__box {
    left: 50%;
    transform: translateX(-60%);
    width: 75%;
    height: 70%;
    margin-top: 12.5vh;
}

#popout-create-order .dropdown__container {
    width: 100%;
    margin-left: 0;
}

#popout-create-order h1 {
    margin: 0;
    margin-bottom: 5px;
}

#popout-create-order #sales__dropdownList--customer {
    width: 30%;
}

#popout-create-order .dropdown__container .input_field__input_box--text {
    margin-bottom: 15px;
    font-size: 0.75rem;
    height: 35px;
    width: 30%;
}

#popout-create-order .product__dropdown__container ul {
    top: 35px;
}

#popout-create-order .product__dropdown__container .input_field__input_box--text.sales {
    margin: 0;
    font-size: 0.75rem;
    height: 35px;
    width: 100%;
}

#popout-create-order .product__dropdown__container {
    width: 100%;
    margin-left: 0;
}

#popout-create-order .dropdown__container ul {
    top: 40px;
}

#popout-create-order .dropdown__container li {
    font-size: 0.75rem;
}

#popout-create-order .input_field__input_box--text.filter {
    height: 35px;
}

#popout-create-order .input_field__input_box--text.product {
    height: 35px;
    width: 100%;
    font-size: 0.7rem;
}

#popout-create-order .more-products--btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(6fr + 1fr + 120px + 8fr + 0.5fr);
    height: 35px;
    background-color: var(--secondairy-ultra-light-gray);
    border: var(--secondairy-light-gray) solid 1.5px;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 1.5rem;
    color: var(--secondairy-medium-gray);
    font-weight: 400;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

#popout-create-order .more-products--btn:hover {
    background-color: var(--secondairy-light-gray);
}


#popout-create-order .salespage--titles {
    margin-top: 0px;
    display: grid;
    grid-template: 50px / 25% 60px 170px 200px 1fr 50px;
    gap: 10px;
    margin-top: 15px;
    font-size: 0.75rem;
    color: var(--secondairy-medium-gray);
    text-align: center;
    justify-content: center;
    align-items: center;
}

#popout-create-order .salespage--titles .salespage--title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5px;
    height: 37.5px;
    border-bottom: 1px solid var(--secondairy-light-gray);
}

#popout-create-order .salespage--titles .checkboxes {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5px;
    height: 37.5px;
    border-bottom: 1px solid var(--secondairy-light-gray);
    margin-top: 0px;
}

#popout-create-order .salespage--titles .checkboxes div {
    padding: 0 5px;
    font-size: 0.75rem;
}

#popout-create-order .vendor__dropdown__container .input_field__input_box--text.sales {
    margin: 0;
    height: 35px;
}