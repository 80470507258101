/* WeekCalendar.css */
.week-calendar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-header .month-year-title {
  display: flex;
  align-items: flex-end;
  color: var(--secondairy-medium-gray);
  font-weight: 700;
  font-size: 1.2rem;
  gap: 7.5px;
}

.calendar-header .year-number {
  font-size: 1rem;
  color: var(--secondairy-light-medium-gray);
}

.calendar-header button {
  background: none;
  border: none;
  cursor: pointer;
}

.calendar-header button svg {
  width: 10px;
  height: 10px;
  color: var(--secondairy-medium-gray);
}

.calendar-nav-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  gap: 5px;
}

.calendar-nav-buttons .nav-button {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 1px solid var(--light-primary-green);
  background-color: var(--ultra-light-primary-green);
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-nav-buttons .today-button {
  width: 100px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--light-primary-green);
  background-color: var(--ultra-light-primary-green);
  color: var(--secondairy-medium-gray);
}

.calendar-grid {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.day-header {
  display: flex;
  border-bottom: 1px solid var(--secondairy-medium-gray);
  color: var(--secondairy-medium-gray);
  padding-left: 40px;
  gap: 5px;
}

.day-header-item {
  display: flex;  
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  padding: 5px 0;
  font-weight: bold;
  gap: 2.5px;
  flex: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--secondairy-ultra-light-gray);
}

.day-header .day-name {
  font-size: 0.8rem;
  font-weight: 500;
}

.day-header .day-number {
  font-size: 0.9rem;
  font-weight: 800;
}

.calendar-content-wrapper {
  position: relative;
  display: flex;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden; /* Prevent horizontal scrolling here */
}

.time-slots {
  display: flex;
  flex-direction: column;
  width: 40px;
  flex-shrink: 0;
  height: 1440px; /* 24 hours * 60px per hour */
}

.time-slot {
  height: 60px;
  min-height: 60px;
  border-right: 1px solid var(--secondairy-light-gray);
  border-bottom: 1px solid var(--secondairy-light-gray);
  padding-top: 2.5px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 0.7rem;
  font-weight: 300;
  color: var(--secondairy-medium-gray);
}

.time-slot:nth-last-of-type(1) {
  border-bottom: none;
}

.calendar-content {
  position: relative;
  display: flex;
  flex: 1;
  gap: 5px;
  height: 1440px; /* 24 hours * 60px per hour */
}

.calendar-day {
  flex: 1;
  padding: 0;
  position: relative;
  height: 100%;
  min-width: 100px;
  background-color: var(--secondairy-ultra-light-gray);
  overflow: hidden;
}

.weekend {
  background-color: var(--secondairy-light-gray);
}

.calendar-activity {
  position: absolute;
  border-radius: 4px;
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
  color: var(--secondairy-medium-gray);
  box-sizing: border-box;
}

.calendar-activity-time {
  font-size: 0.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-activity-title {
  font-size: 0.7rem;
  margin-top: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.week-calendar .loading-spinner {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  color: var(--secondairy-light-medium-gray);
  top: calc(50% + 10vh);
  left: calc(50% + 10vw);
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 499;
}
