.popup__overlay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    opacity: 0;
    pointer-events: none;
}

.popup__overlay.active {
    position: fixed;
    pointer-events: all;
    opacity: 1;
    cursor: pointer;
}

.popup__container {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
    background-color: white;
    padding: 15px;

    opacity: 0;
    pointer-events: none;

    transition: all 200ms ease-in-out;
}

.popup__container.active {
    pointer-events: all;
    z-index: 202;
    opacity: 1; 
}

.popup__box {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    background-color: white;
    padding: 15px;
    cursor: auto;
    overflow: scroll;
}

.popup__container.overlay #popup__close-icon {
    position: absolute;
    color: var(--primary-black);
    font-size: 0.7rem;
    right: 16px;
    top: 8px;
    cursor: pointer;
    background-color: var(--secondairy-ultra-light-gray);
    border: 1px solid var(--secondairy-light-gray);
    border-radius: 20px;
    width: 25px;
    height: 18px;
    padding: 3px;
}

.form__section_title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--primary-green);
    margin-bottom: 40px;
}

.popup__container h2 {
    font-weight: 700;
    color: var(--primary-green);
    font-variant: all-small-caps;
    margin: 0 0 10px 0;
    font-size: 1.7rem;
}

.popup__container h3 {
    font-weight: 700;
    color: var(--primary-black);
    font-variant: all-small-caps;
    margin: 10px 0;
    font-size: 1.3rem;
}

.popup__container .form__section {
    padding: 20px;
}

.popup__container .popup__header {
    height: 10%;
    width: 100%;
}

.popup__container .popup__body {
    height: 90%;
    width: 100%;
    padding: 15px 0; 
}



