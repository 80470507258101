#popup-window--create-order-mobile .add_button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 2.5rem;
  font-weight: 200;
  padding: 0;
  user-select: none;
  background-color: var(--secondairy-light-gray-plus);
  background-color: var(--primary-black);
  color: var(--secondairy-light-gray);
  border-radius: 5px;
}