.body__sales--brandowner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
}

.body__sales--brandowner .body__section {
  /* reguires index.css */
  transition: height 500ms ease-in-out;
}

.body__sales--brandowner #new-sales-form-brandowner {
  width: 100%;
  height: 55%;
}

.body__sales--brandowner #new-sales-form-brandowner #visits__input-field-customer {
  height: 35px;
}

.body__sales--brandowner #new-sales-form-brandowner .form__section {
  border: none;
  padding: 0;
}

.body__sales--brandowner #new-sales-form-brandowner .form__section .grid {
  height: 100%;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 20px;
}

.body__sales--brandowner #new-sales-form-brandowner .dropdown__container {
  width: 30%;
  margin-left: 0;
}

.body__sales--brandowner #new-sales-form-brandowner .product__dropdown__container {
  width: 100%;
  margin-left: 0;
}

.body__sales--brandowner #new-sales-form-brandowner h1 {
  margin: 0;
  margin-bottom: 5px;
}

.body__sales--brandowner #new-sales-form-brandowner .dropdown__container .input_field__input_box--text.sales {
  margin-bottom: 15px;
  font-size: 0.75rem;
  height: 35px;
  width: 100%;
}

.body__sales--brandowner #new-sales-form-brandowner .product__dropdown__container .input_field__input_box--text.sales {
  font-size: 0.75rem;
  height: 35px;
  width: 100%;
}

.body__sales--brandowner #new-sales-form-brandowner .product__dropdown__container ul {
  top: 35px;
}

.body__sales--brandowner #new-sales-form-brandowner .vendor__dropdown__container .input_field__input_box--text.sales {
  margin: 0;
  height: 35px;
}

.body__sales--brandowner #new-sales-form-brandowner .dropdown__container ul {
  top: 40px;
}

.body__sales--brandowner #new-sales-form-brandowner .dropdown__container li {
  font-size: 0.65rem;
}

.body__sales--brandowner #new-sales-form-brandowner .input_field__input_box--text.filter {
  height: 35px;
}

.body__sales--brandowner #new-sales-form-brandowner .input_field__input_box--text.product {
  height: 35px;
  width: 100%;
  font-size: 0.7rem;
}

.body__sales--brandowner #new-sales-form-brandowner .more-products--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(6fr + 1fr + 120px + 8fr + 0.5fr);
  height: 35px;
  background-color: var(--secondairy-ultra-light-gray);
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 5px;
  margin-top: 15px;
  font-size: 1.5rem;
  color: var(--secondairy-medium-gray);
  font-weight: 400;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.body__sales--brandowner #new-sales-form-brandowner .more-products--btn:hover {
  background-color: var(--secondairy-light-gray);
}

.body__sales--brandowner #new-sales-form-brandowner .form__section {
  height: 100%;
}

.body__sales--brandowner #new-sales-form-brandowner .new-sales-form {
  height: 100%;
}

.body__sales--brandowner #sales-history-table-brandowner {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 45%;
  padding-right: 1px;
}

.body__sales--brandowner #sales_brandowner__comment-field--popup {
  height: 80%;
  margin-bottom: 5px;
}

.body__sales--brandowner .checkboxes {
  display: flex;
  margin: 0px;
  align-self: flex-start;
}

.body__sales--brandowner .checkbox__container {
  margin: 0;
}

.body__sales--brandowner .checkbox__container .checkbox--text {
  font-size: 0.7rem;
}

.body__sales--brandowner .table__header-11.all,
.body__sales--brandowner .table__header-11.completed,
.body__sales--brandowner .table__header-11.toDeliver,
.body__sales--brandowner .table__header-11.toInvoice {
  font-size: 0.7rem;
  grid-template: 35px / 30px 40px 100px 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr 0.25fr;
}

.body__sales--brandowner .table__row-11.all,
.body__sales--brandowner .table__row-11.completed,
.body__sales--brandowner .table__row-11.toDeliver,
.body__sales--brandowner .table__row-11.toInvoice {
  font-size: 0.7rem;
  grid-template: 35px / 30px 40px 100px 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr 0.25fr;
}

.body__sales--brandowner .table__header-11.new {
  font-size: 0.7rem;
  grid-template: 35px / 30px 40px 100px 1.2fr 1.2fr 0.75fr 0fr 0.25fr 2.8fr;
}

.body__sales--brandowner .table__row-11.new {
  font-size: 0.7rem;
  grid-template: 35px / 30px 40px 100px 1.2fr 1.2fr 0.75fr 0fr 0.25fr 2.8fr;
}

.body__sales--brandowner .table__row-11.removing {
  animation: removeAnimation 0.5s ease forwards;
}

.body__sales--brandowner .table__row-11 {
  cursor: pointer;
}

.body__sales--brandowner .table__row--field button {
  margin-right: 5px;
  height: 25px;
  display: flex;
  justify-content: center;
}

.body__sales--brandowner .table__row--field--uncenter {
  align-self: auto;
}

.body__sales--brandowner .table__row-11 svg {
  color: var(--secondairy-medium-gray);
  justify-self: flex-end;
}

.body__sales--brandowner .table__row-11 svg.transform {
  transform: rotate(180deg);
}

.body__sales--brandowner--table-rows__container {
  border-radius: 7.5px;
  cursor: pointer;
}

.body__sales--brandowner .table {
  max-width: 99%;
}

.body__sales--brandowner .table .body__sales--brandowner--table-rows__container:nth-child(even) {
  background-color: var(--secondairy-ultra-light-gray);
}

.body__sales--brandowner .table__secondairy-header-3 {
  width: 70%;
  grid-template: auto / 0.4fr 1.2fr 1fr;
  margin-bottom: 0;
}


.body__sales--brandowner .table__secondairy-header--field {
  border: var(--secondairy-light-gray) solid 1px;
  padding: 10px;
}

.body__sales--brandowner .product-details__container {
  color: var(--primary-black);
  margin: 15px 0;
}

.body__sales--brandowner .unfinished {
  background-color: var(--secondairy-light-gray) !important;
}

.body__sales--brandowner .progress__tracker--first {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--light-primary-green);
  border: white solid 1px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.body__sales--brandowner .progress__tracker--last {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  left: -10px;
  background-color: var(--light-primary-green);
  border-radius: 15px;
  border: white solid 1px;
}

.body__sales--brandowner .progress__tracker--first.disabled,
.body__sales--brandowner .progress__tracker.disabled,
.body__sales--brandowner .progress__tracker--last.disabled {
  background-color: var(--secondairy-light-gray);
}

.body__sales--brandowner .progress__tracker {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% + 10px);
  height: 100%;
  left: -10px;
  background-color: var(--light-primary-green);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border: white solid 1px;
}

.body__sales--brandowner .progress__tracker_circle {
  width: 25px;
  height: 90%;
  margin-left: 2px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body__sales--brandowner .progress__tracker_circle svg {
  fill: var(--light-primary-green);
  font-size: 1.2rem;
}

.body__sales--brandowner .progress__tracker_circle .disabled_icon {
  color: var(--secondairy-light-medium-gray);
  pointer-events: none;
}

.body__sales--brandowner .done_icon {
  opacity: 0;
}

.body__sales--brandowner .popup__container h1 {
  width: 100%;
  text-align: center;
}

.body__sales--brandowner .action-btns {
  padding: 15px;
}

.body__sales--brandowner .action-btns button {
  padding: 3px 7px;
  font-size: 0.7rem;
  font-weight: 500;
  outline: none;
  border: solid 1px var(--secondairy-medium-gray);
  border-radius: 4px;
  cursor: pointer;

  transition: all 250ms ease-in-out;
}

.body__sales--brandowner .action-btns button:hover {
  background-color: var(--light-primary-green);
  border-color: var(--primary-black);
}

.body__sales--brandowner .popup-elements {
  display: grid;
  grid-template: 1fr 0.4fr 1fr / 1.75fr 1fr;
  grid-template-areas:
    "client history"
    "promos history"
    "order tracking"
  ;
  column-gap: 30px;
  height: 100%;
  width: 100%;
}

.body__sales--brandowner .popup-elements .popup_window__sub-title {
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  width: 90%;
  left: 10px;
  margin: 10px 0;
  margin-left: -10px;
}

.body__sales--brandowner .popup-elements h2 {
  font-size: 0.70rem;
  color: var(--primary-black);
  font-weight: 300;
  cursor: default;
  width: auto;
  margin-right: auto;
}

.body__sales--brandowner .popup-elements .promos {
  grid-area: promos;
  padding-bottom: 15px;
}

.body__sales--brandowner .popup-elements .promos .promo_item {
  border: var(--secondairy-light-gray) solid 1px;
  background-color: var(--secondairy-ultra-light-gray);
  border-radius: 5px;
  font-weight: 300;
  color: var(--secondairy-medium-gray);
  font-size: 0.6rem;
  padding: 5px;
}

.body__sales--brandowner .popup-elements .tracking {
  grid-area: tracking;
}

.body__sales--brandowner .popup-elements .client {
  grid-area: client;

  display: grid;
  grid-template-areas:
    "title title"
    "column1 column2"
  ;
  gap: 10px;
}

.body__sales--brandowner .popup-elements .client .popup_window__sub-title {
  grid-area: title;
}

.body__sales--brandowner .popup-elements .client-info-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.body__sales--brandowner .popup-elements .client-tracking-info {
  display: grid;
  grid-template: auto / 50% 30% auto;
  column-gap: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.body__sales--brandowner .popup-elements .client-tracking-info input::placeholder {
  color: var(--secondairy-medium-gray);
}

.body__sales--brandowner .popup-elements .client-tracking-info .secondairy-button {
  font-size: 0.6rem;
}

.body__sales--brandowner .popup-elements .client-tracking-info input:first-child {
  margin-right: 10px;
}

.body__sales--brandowner .popup-elements .client-tracking-info input:nth-child(2) {
  margin-right: 10px;
}

.body__sales--brandowner .popup-elements .client-info-item p {
  font-size: 0.6rem;
  color: var(--secondairy-medium-gray);
  background-color: var(--secondairy-ultra-light-gray);
  border: 1px solid var(--secondairy-light-gray);
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  min-height: 24px;
  margin-top: 5px;
  cursor: copy;
}

.body__sales--brandowner .popup-elements .double-field-30-70 {
  display: flex;
  width: 100%;
}

.body__sales--brandowner .popup-elements .double-field-50-50 {
  display: flex;
  gap: 10px;
  width: 100%;
}

.body__sales--brandowner .popup-elements .double-field-50-50 input {
  height: 24px;
}

.body__sales--brandowner .popup-elements .double-field-30-70 p:first-child {
  width: 30%;
  margin-right: 3px;
}

.body__sales--brandowner .popup-elements .double-field-30-70 p:nth-child(2) {
  width: 70%;
}

.body__sales--brandowner .popup-elements .order {
  grid-area: order;
  padding-bottom: 40px;
}

.body__sales--brandowner .sales__orders--product-rows {
  display: grid;
  width: 70%;
  margin-right: auto;

  display: grid;
  grid-template: auto / 0.5fr 0.9fr 1.30fr 0.7fr 1fr 0.5fr;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
}

.body__sales--brandowner .table__secondairy-header--field {
  border: none;
  padding: 2.5px 2.5px 2.5px 0;
}

.body__sales--brandowner .sales__orders--product-rows:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
  border: 1px solid var(--secondairy-light-gray);
}

.body__sales--brandowner .table__secondairy-row--field {
  font-weight: 300;
  color: var(--secondairy-medium-gray);

  font-size: 0.6rem;
  padding: 0;
  margin-right: 3px;
  cursor: copy;

  padding: 2.5px 2.5px 2.5px 0;
}

.body__sales--brandowner .table__secondairy-header-6 {
  display: grid;
  grid-template: auto / 0.5fr 0.9fr 1.30fr 0.7fr 1fr 0.5fr;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  font-weight: 300;
  font-size: 0.7rem;
  padding: 5px;
}

.body__sales--brandowner .history {
  grid-area: history;

  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow-y: auto;
}

.body__sales--brandowner .timeline__container {
  position: relative;
  width: 90%;
  left: 10px;
  padding: 20px 0;
}

.body__sales--brandowner .timeline__event {
  position: relative;
  height: 75px;
  width: 100%;
  border-left: 2px var(--secondairy-light-gray) solid;
}

.body__sales--brandowner .timeline__event--date {
  position: absolute;
  font-size: 0.65rem;
  top: -3px;
  left: 15px;
}

.body__sales--brandowner .timeline__event::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  left: -6px;
  border: none;
  border-radius: 50px;
  background-color: var(--secondairy-light-gray);
}

.body__sales--brandowner .timeline__event--label {
  position: absolute;
  display: flex;
  justify-content: left;
  align-items: center;
  top: 17.5px;
  left: 15px;
  height: 20px;
  width: auto;
  border-radius: 15px;
  padding: 5px 22.5px;
}


.body__sales--brandowner .timeline__event--label h4 {
  font-size: 0.60rem;
}

.body__sales--brandowner .timeline__event--label .ball {
  position: absolute;
  height: 8px;
  width: 8px;
  left: 7.5px;
  border-radius: 50px;
}

.body__sales--brandowner .timeline__event--label.green {
  background-color: var(--light-green);
}

.body__sales--brandowner .timeline__event--label.green h4 {
  color: var(--dark-green);
}

.body__sales--brandowner .timeline__event--label.green .ball {
  background-color: var(--green);
}

.body__sales--brandowner .timeline__event--label.blue {
  background-color: var(--light-blue);
}

.body__sales--brandowner .timeline__event--label.blue h4 {
  color: var(--blue);
}

.body__sales--brandowner .timeline__event--label.blue .ball {
  background-color: var(--blue);
}

.body__sales--brandowner .timeline__event--label.grey {
  background-color: var(--secondairy-light-gray);
}

.body__sales--brandowner .timeline__event--label.grey h4 {
  color: var(--secondairy-medium-gray);
}

.body__sales--brandowner .timeline__event--label.grey .ball {
  background-color: var(--secondairy-medium-gray);
}

.body__sales--brandowner .timeline__event--label.red {
  background-color: var(--light-red);
}

.body__sales--brandowner .timeline__event--label.red h4 {
  color: var(--dark-red);
}

.body__sales--brandowner .timeline__event--label.red .ball {
  background-color: var(--red);
}

.body__sales--brandowner .timeline__event--comment {
  position: absolute;
  left: 17.5px;
  top: 42.5px;
  font-size: 0.6rem;
  font-style: italic;
}

.body__sales--brandowner .tracking_status {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 70%;
  align-self: center;
}

.body__sales--brandowner .table {
  display: grid;
  grid-template: auto / auto;
}

.body__sales--brandowner .table__row-10.changed-row {
  animation: shining 3.5s ease-in-out;
}

.body__sales--brandowner .salespage--titles {
  margin-top: 0px;
  display: grid;
  grid-template: auto / 25% 60px 170px 200px 1fr 50px;
  gap: 10px;
  margin-top: 15px;
  font-size: 0.6rem;
  color: var(--secondairy-medium-gray);
  text-align: center;
  justify-content: center;
  align-items: center;
}

.body__sales--brandowner .salespage--titles .salespage--title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px;
  height: 37.5px;
  border-bottom: 1px solid var(--secondairy-light-gray);
}

.body__sales--brandowner .salespage--titles .checkboxes {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px;
  height: 37.5px;
  border-bottom: 1px solid var(--secondairy-light-gray);
}

.body__sales--brandowner .salespage--titles .checkboxes div {
  padding: 0 5px;
  font-size: 0.6rem;
}

.body__sales--brandowner .body__section__header {
  display: grid;
  grid-template: auto / repeat(5, 1fr);
  margin-bottom: 7.5px;
  width: 100%;
  cursor: pointer;
}

.body__sales--brandowner .body__section__header_item {
  display: flex;
  gap: 10px;
  padding: 7.5px;
  border-bottom: 2px var(--secondairy-light-gray) solid;
  font-size: 0.70rem;
  text-transform: uppercase;
  color: var(--secondairy-medium-gray);
  width: 100%;
  justify-self: center;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease-in-out;
}

.body__sales--brandowner .body__section__header_item.hovered {
  border-bottom: 2px var(--secondairy-light-medium-gray) solid;
  color: var(--primary-black);
  font-weight: 500;
}

.body__sales--brandowner .body__section__header_item.selected {
  border-bottom: 2px var(--primary-green) solid;
  color: var(--primary-black);
  color: var(--primary-green);
  font-weight: 500;
}

.body__sales--brandowner .header_item--number {
  background-color: var(--secondairy-light-gray);
  color: var(--secondairy-light-medium-gray);
  padding: 2.5px 7.5px;
  font-size: 0.75rem;
  border-radius: 10px;
  transition: all 250ms ease-in-out;
}

.body__sales--brandowner .header_item--number.hovered {
  background-color: var(--secondairy-light-medium-gray);
  color: var(--secondairy-medium-gray);
}

.body__sales--brandowner .header_item--number.selected {
  background-color: var(--light-primary-green);
  color: var(--primary-green);
  font-weight: 700;
}

.body__sales--brandowner .table {
  height: 90%;
  display: flex;
  flex-direction: column;
}

.body__sales--brandowner .table.small {
  height: 80%;
}

.table__rows_container.sales {
  height: 100%;
}

.header__options--right #sales__btn-save {
  position: relative;
  right: 0;
}

.header__options .dropdown__container {
  margin-left: 0px;
  width: 150px;
}

@keyframes shining {
  0% {
    background-color: var(--secondairy-light-gray);
  }

  50% {
    background-color: white;
  }

  100% {
    background-position: var(--secondairy-ultra-light-gray);
  }
}

@keyframes removeAnimation {
  0% { opacity: 1; transform: translateX(0); }
  20% { transform: translateX(20%); }
  80% { transform: translateX(80%); }
  100% { opacity: 0; transform: translateX(100%); }
}
