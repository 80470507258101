.table_checkmark_row__container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_checkmark_row__circle {
  position: relative;
  background-color: white !important;
  border-radius: 50px;
  border: solid 1px var(--secondairy-light-medium-gray) ;
  z-index: 49;
}

.table_checkmark_row__circle.hovered {
  background-color: var(--primary-green) !important;
  border: solid 2px var(--primary-green) ;
}

.table_checkmark_row__circle.selected {
  background-color: var(--primary-green) !important;
  border: solid 2px var(--primary-green) ;
}

.table_checkmark_row__line {
  position: absolute;
  height: calc(100% + 50px);
  transform: translateY(-50%);
  width: 1px;
  background-color: var(--primary-green) !important;
  opacity: 0;
}

.table_checkmark_row__container .checkmark_stem {
  position: absolute;
  width: 1px;
  height: 50%;
  background-color: var(--secondairy-light-gray) !important;
  left: 58%;
  top: 30%;
  transform: rotate(30deg);
}

.table_checkmark_row__container .checkmark_kick {
  position: absolute;
  width: 35%;
  height: 1px;
  background-color: var(--secondairy-light-gray) !important;
  left: 20%;
  top: 60%;
  transform: rotate(35deg);
}