.body__subscription_plans {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 40px;
}

.subscription_plan__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  width: 25%;
  background-color: white;
  border-radius: 10px;
  box-shadow: var(--border-shadow-allround);
}

.subscription_plan__container.current {
  border: 5px solid var(--primary-green);
}

.subscription_plan__header h1 {
  padding: 30px 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-black);
  text-align: center;
}

.subscription_plan__price h2 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary-green);
  text-align: center;
}

.subscription_plan__price h3 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary-black);
  text-align: center;
}

.subscription_plan__price p {
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 0;
  color: var(--red);
  text-align: center;
}

.subscription_plan__features {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px 20px;
}

.subscription_plan__features .features__feature {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 10px 0;
}

.subscription_plan__features .features__feature h3 {
  color: var(--primary-black);
  font-size: 1rem;
  font-weight: 400;
}

.subscription_plan__features .features__feature svg {
  color: var(--secondairy-light-medium-gray);
}

.subscription_plan__button {
  padding: 40px 0;
}

.subscription_plan__button button {
  background-color: var(--light-primary-green);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  height: 30px;
  width: 120px;
  cursor: pointer;
  z-index: 100;
}

.subscription_plan__button button.downgrade {
  background-color: var(--light-red);
}

.yearly_slider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}