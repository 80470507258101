.customer-fiche {
  position: relative;
  background-color: white;
  padding: 10px;
  height: 55px;
  border-radius: 7.5px;
  box-shadow: var(--border-shadow-min);
  margin: 5px 0;
  cursor: pointer;
}

.customer-fiche .customer-fiche_customer {
  font-size: 0.7rem;
  height: 77.5%;
  width: 65%;
}

.customer-fiche_tags {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 5px;
  padding-right: 10px;
  padding-top: 10px;
  row-gap: 5px;
  position: absolute;
  height: 100%;
  width: 35%;
  right: 0;
  top: 0;
}

.customer-fiche_tag {
  right: 10px;
  top: 10px;
  font-size: 0.50rem;
  font-weight: 900;
  width: 60px;
  padding: 3px 12.5px;
  border-radius: 7.5px;
  text-transform: uppercase;
}

.customer-fiche_tag.normal {
  background-color: var(--secondairy-light-gray);
  color: var(--secondairy-medium-gray);
}

.customer-fiche_tag.red {
  background-color: var(--light-red);
  color: var(--dark-red);
}

.customer-fiche_tag.green {
  background-color: var(--light-green);
  color: var(--dark-green);
}

.customer-fiche_tag.blue {
  background-color: var(--light-blue);
  color: var(--dark-blue);
}

.customer-fiche_tag.gold {
  background-color: var(--special-gold-light);
  color: var(--special-gold-dark);
}

.customer-fiche .customer-fiche_sales-owner {
  margin-top: 2.5px;
  font-size: 0.65rem;
  color: var(--secondairy-light-medium-gray);
}