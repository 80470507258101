.coming-soon__container {
    position: absolute;
    z-index: 50;
    right: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(5px);
}

.coming-soon__text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--secondairy-light-medium-gray);
}