.loading_spinner_overlay {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 7.5px;
  width: 50%;
  height: 50%;
}


.loading_spinner_overlay .lds-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%; 
}

.loading_spinner_overlay .lds-ellipsis div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--secondairy-light-medium-gray);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

/* Remove "top: 0" from these rules */
.loading_spinner_overlay .lds-ellipsis div:nth-child(1) {
  top: 0;
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.loading_spinner_overlay .lds-ellipsis div:nth-child(2) {
  top: 0;
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loading_spinner_overlay .lds-ellipsis div:nth-child(3) {
  top: 0;
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loading_spinner_overlay .lds-ellipsis div:nth-child(4) {
  top: 0;
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  