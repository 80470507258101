.loading-container {
  background-color: black; /* Set background to black */
  color: white; /* Ensure text is visible */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
}

#loading-logo-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 100px;
    max-width: 300px;
}

#loading-logo-container svg {
    position: absolute;
    top: 0;
    left: 10px;
    width: 100%;
    height: 100px;
}

.loading-container .reload-text {
    position: relative;
    font-size: 12px;
    color: var(--secondairy-medium-gray);
    cursor: pointer;
}


