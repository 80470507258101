.dropdown__container {
  position: relative;
  align-self: flex-end;
  margin-left: 0px;
  width: auto;
}

.dropdown__container.filter_active .input_field__input_box--text {
  background-color: var(--light-primary-green);
}

.dropdown__container.small {
  width: 100px;
}

.dropdown__container.medium {
  min-width: 125px;
  max-width: 150px;
}

.dropdown__container.large {
  width: 200px;
}

.dropdown__container.extralarge {
  width: 250px;
}

.dropdown__container .input_field__input_box--text.filter {
  /* requires index.css */
  display: flex;
  align-items: center;
  border-radius: 7.5px;
  user-select: none;
}

.input_field__input_box--text p {
  font-weight: 400;
  font-style: italic;
}

.input_field__input_box--text svg {
  margin-left: auto;
  height: 120%;
  font-size: 1.2rem;
}

.dropdown__container .search-bar {
  width: 100%;
  font-size: 0.7rem;
  padding: 8px 10px;
  background-color: white;
  outline: none;
  border: none;
}

.dropdown__container .input_field__dropdown {
  top: 36px;
}

.dropdown__container .input_field__dropdown li {
  font-size: 0.7rem;
}