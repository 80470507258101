.body-loginpage {
  min-height: 100vh;
  background: var(--primary-black);
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  overflow: hidden;
}

.body-loginpage .logo {
  margin-top: 10vh;
  position: relative;
  width: 30vw;
  max-width: 475px;
}

.body-loginpage .logo img {
  width: 100%;
}

.login-form {
  height: 40vh;
  width: 35vw;
  max-width: 475px;
  background-color: var(--secondairy-ultra-light-gray);
  padding: 40px 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-login {
  background-color: var(--primary-green);
  color: #fff;
  border-style: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 2vmin;
  height: 20%;
  width: 80%;
  margin-top: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.button-login svg {
  vertical-align: middle;
}

.login-form input {
  background-color: var(--secondairy-light-gray);
  border-style: none;
  height: 20%;
  width: 80%;
  border-radius: 5px;

  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 1.5vmin;
  margin-bottom: 1.5%;
  color: var(--primary-black);
}

.login-form input:-webkit-autofill,
.login-form input:-webkit-autofill:hover,
.login-form input:-webkit-autofill:focus,
.login-form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--secondairy-light-gray) inset !important;
}

.login-form input:focus {
  outline: var(--primary-black) solid 1.5px;
}

.login-form.is-mobile input:focus {
  outline: none;
}

.login-links {
  width: 75%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  cursor: pointer;
}

.login-links a {
  color: var(--secondairy-medium-gray);
  font-size: 1.5vmin;
}

.login-links a:hover {
  border-bottom: solid black 1px;
}

/* ANCHOR MOBILE */

@media only screen and (max-width: 768px) {

  
  .body-loginpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
  }

  .body-loginpage .logo {
    margin-top: 10vh;
    position: relative;
    width: 80vw;
  }
    
  .body-loginpage .logo img {
    width: 100%;
  }
  
  .login-form {
    height: 35vh;
    width: 80vw;
  }
  
  .button-login {
    font-size: 0.85rem;
  }
  
  .button-login svg {
    vertical-align: middle;
  }
  
  .login-form input {
    font-size: 0.75rem;
  }
  
  .login-links {
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    cursor: pointer;
  }
  
  .login-links a {
    font-size: 0.75rem;
  }
  

}

