.dropdown_button_header__container {
  position: relative;
}

.dropdown_button_header {
  position: relative;
  background-color: white;
  box-shadow: var(--border-shadow-min);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  width: 160px;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7.5px;
  z-index: 100;
}

.dropdown_button_header.green {
  background-color: var(--light-primary-green);
  color: black;
}

.dropdown_button_header__list {
  position: absolute;
  display: none;
  width: 160px;
  height: 0;
  border-radius: 7.5px;
  background-color: white;
  border: var(--secondairy-light-gray) solid 1px;
  transition: all 1s ease-in-out;
  z-index: 101;
  overflow: hidden;
}

.dropdown_button_header__list.hovered {
  transform: translateY(0px);
  height: fit-content;
  display: block;
}

.dropdown_button_header__list_item {
  background-color: white;
  height: fit-content;
  cursor: pointer;
  list-style: none;
  font-size: 0.75rem;
  padding: 10px 7.5px;
  color: var(--secondairy-medium-gray);
  z-index: 90;
}

.dropdown_button_header__list_item:hover {
  background-color: var(--secondairy-light-gray);
}

/* green style */

.dropdown_button_header__container.green .dropdown_button_header {
  background-color: var(--light-primary-green);
  color: black;
}

.dropdown_button_header__container.green .dropdown_button_header__list,
.dropdown_button_header__container.green .dropdown_button_header__list_item
 {
  background-color: var(--ultra-light-primary-green);
  font-weight: 500;
}

.dropdown_button_header__container.green .dropdown_button_header__list_item:hover {
  background-color: var(--light-primary-green);
}