.body__home-salesforce {
  display: grid;
  grid-template: repeat(6, 1fr) / repeat(8, 1fr);
  grid-template-areas: 
    "sec1 sec1 sec1 sec1 sec2 sec2 sec3 sec3"
    "sec1 sec1 sec1 sec1 sec2 sec2 sec3 sec3"
    "sec1 sec1 sec1 sec1 sec2 sec2 sec3 sec3"
    "sec4 sec4 sec4 sec5 sec5 sec5 sec5 sec5"
    "sec4 sec4 sec4 sec5 sec5 sec5 sec5 sec5"
    "sec4 sec4 sec4 sec5 sec5 sec5 sec5 sec5"
  ;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.body__home-salesforce .body__section::-webkit-scrollbar {
  display: none;
}

.body__home-salesforce p {
  font-size: 0.8rem;
}

.body__home-salesforce .body__section h2 {
  font-weight: 700;
  color: var(--primary-green);
  font-variant: small-caps;
  margin-bottom: 10px;
}

.body__home-salesforce .body__section h2 p {
  font-size: 0.6rem;
  margin-left: 10px;
  margin-top: 7.5px;
}

.sales-period-filter {
  font-weight: 700;
  color: var(--primary-green);
  font-variant: small-caps; 
  font-size: 0.6rem;
  margin-left: 10px;
  margin-top: 7.5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.sales-period-filter.underlined {
  text-decoration: underline;
}

#section1 {
  position: relative;
  grid-area: sec1;
  height: 100%;
  width: 100%;
}

.chart {
  position: relative;
  height: 80%;
  width: 100%;
}

#section2 {
  grid-area: sec2;
  height: 100%;
  width: 100%;
}

#section3 {
  grid-area: sec3;
  height: 100%;
  width: 100%;
}

#section4 {
  grid-area: sec4;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#section5 {
  grid-area: sec5;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.body__home-salesforce #activities-list{
  padding: 0;
}

.body__home-salesforce #activities-list .table__row-4 {
  grid-template-columns: 50px 1fr 1.5fr 0.75fr;
  column-gap: 2.5px;
  border: solid 2px white;
  border-left: 5px solid var(--secondairy-light-gray);
  background-color: var(--secondairy-ultra-light-gray);
  cursor: pointer;
}

.body__home-salesforce #activities-list .table__row-4 img {
  height: 25px;
}

.body__home-salesforce #activities-list .table__row-4.overdue {
  background-color: var(--hyper-light-red);
  border: solid 2px white;
  border-left: var(--light-red) solid 5px;
}

.body__home-salesforce #activities-list .table__row-4.done {
  background-color: var(--ultra-light-green);
  border: solid 2px white;
  border-left: var(--light-green) solid 5px;
}

.body__home-salesforce #activities-list .no_task__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.body__home-salesforce #activities-list .no_task__container img {
  height: 20%;
  opacity: 0.5;
}

.body__home-salesforce #activities-list .no_task__container p {
  color: var(--secondairy-medium-gray);
  margin-top: 15px;
}




