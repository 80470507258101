.body__home-admin {
  display: grid;
  grid-template: repeat(6, 1fr) / repeat(8, 1fr);
  grid-template-areas: 
    "sec1 sec1 sec1 sec1 sec2 sec2 sec3 sec3"
    "sec1 sec1 sec1 sec1 sec7 sec7 sec3 sec3"
    "sec6 sec6 sec6 sec4 sec4 sec4 sec4 sec4"
    "sec6 sec6 sec6 sec4 sec4 sec4 sec4 sec4"
    "sec6 sec6 sec6 sec5 sec5 sec5 sec5 sec5"
    "sec6 sec6 sec6 sec5 sec5 sec5 sec5 sec5"
  ;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.body__home-admin .body__section.flex {
  display: flex;
  align-items: center;
  gap: 25px;
}

.body__home-admin .body__section h2 {
  font-weight: 700;
  color: var(--primary-green);
  font-variant: small-caps;
}

.body__home-admin .body__section h2 p {
  font-size: 0.6rem;
  margin-left: 10px;
  margin-top: 7.5px;
}

.body__home-admin .body__section h2 p {
  font-size: 0.6rem;
  margin-left: 10px;
  margin-top: 7.5px;
}

.body__home-admin .body__section .number {
  font-size: 2rem;
  font-weight: 800;
  color: var(--secondairy-medium-gray);
}

.body__home-admin #section1 {
  position: relative;
  grid-area: sec1;
  height: 100%;
  width: 100%;
}

.body__home-admin .chart {
  position: relative;
  height: 85%;
  width: 100%;
}

.body__home-admin #section2 {
  grid-area: sec2;
  height: 100%;
  width: 100%;
}

.body__home-admin #section3 {
  grid-area: sec3;
  height: 100%;
  width: 100%;
}

.body__home-admin #section4 {
  grid-area: sec4;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.body__home-admin #section6 {
  grid-area: sec6;
  height: 100%;
  width: 100%;
}

.body__home-admin #section5 {
  grid-area: sec5;
  height: 100%;
  width: 100%;
}

.body__home-admin #section7 {
  grid-area: sec7;
  height: 100%;
  width: 100%;
}

.body__home-admin h3 {
  color: var(--secondairy-medium-gray);
  font-size: 2rem;
}

.body__home-admin .indicator__container {
  display: flex;
  margin-left: 10%;
}


