.body__products-page--salesforce {
    position: relative;
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    grid-template-areas:
        "inventory inventory"
        "inventory inventory"
    ;

    height: 100%;
}

.body__products-page--salesforce #products-page--brandowner--products {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-area: products;
    width: 98%;
    overflow: hidden;
}

.body__products-page--salesforce #products-page--brandowner--products .table {
    overflow-y: auto;
}

.body__products-page--salesforce #products-page--brandowner--materials .table {
    overflow-y: auto;
}

.body__products-page--salesforce #products-page--brandowner--warehouses {
    position: relative;
}

.body__products-page--salesforce #products-page--brandowner--materials {
    position: relative;
    grid-area: materials;
}

.body__products-page--salesforce #products-page--salesforce--inventory {
    grid-area: inventory;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.body__products-page--salesforce #products-page--salesforce--inventory.big {
    grid-area: inventory;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-row: 1 / 3;
}

.body__products-page--salesforce .warehouses__section {
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.body__products-page--salesforce .warehouses__section .warehouse_container {
    position: relative;
}

.body__products-page--salesforce .warehouses__section .warehouse_container .plus_icon__container {
    width: 100%;
    height: 100%;
    font-size: 7rem;
    color: var(--secondairy-light-medium-gray);
    display: flex;
    align-items: center;
    justify-content: center;
}

.body__products-page--salesforce .warehouses__section .warehouse_container .warehouse_picto__container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.body__products-page--salesforce .placeholder_icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.body__products-page--salesforce .placeholder_icon svg {
    width: 33%;
    height: 33%;
}

.body__products-page--salesforce .placeholder_icon h3 {
    font-weight: 500;
    color: var(--secondairy-medium-gray);
    margin-bottom: 25px;
}

.body__products-page--salesforce .body__section h2 {
    font-weight: 700;
    color: var(--primary-green);
    font-variant: small-caps;
    margin-bottom: 10px;
}

.body__products-page--salesforce .table__header-4 {
    grid-template: 35px / 250px 1fr 150px 75px;
    width: 100%;
    font-size: 0.85rem;
}

.body__products-page--salesforce .table__row-4 {
    grid-template: auto / 250px 1fr 150px 75px;
    font-size: 0.75rem;
}

.body__products-page--salesforce .inventory__section {
    position: relative;
    width: 100%;
    height: 240px;
    border-radius: 10px;
    padding: 40px 10px 40px 20px;
    column-gap: 30px;
    border: var(--secondairy-light-gray) solid 1px;
    cursor: pointer;
    user-select: none;
}

.body__products-page--salesforce .inventory__section h3 {
    font-weight: 700;
    color: var(--primary-black);
    font-variant: all-small-caps;
    margin-bottom: 10px;
    font-size: 1.3rem;
}

.body__products-page--salesforce .inventory__section:nth-child(even) {
    background-color: var(--secondairy-ultra-light-gray);
}

.body__products-page--salesforce .inventory__section-picto {
    padding-top: 10px;
    height: 180px;
    grid-area: picto;
}

.body__products-page--salesforce .inventory__section-warehouse_details {
    position: absolute;
    top: -40px;
    left: -20px;

    display: grid;
    grid-template: 10% 20% 35% 35% / 1fr;

    margin-bottom: 10px;
    background-color: white;
    opacity: 0.95;
    border-radius: 10px;
    height: calc(100% + 80px);
    width: 250px;

    padding: 10px;
    transition: all 0.3s ease-in-out;
}

.body__products-page--salesforce .inventory__section-warehouse_details h5 {
    font-size: 0.95rem;
}

.body__products-page--salesforce .warehouse_picto__container {
    height: 100%;
}

.body__products-page--salesforce .inventory__section-warehouse_details .warehouse_details--address {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--primary-black);
    margin-bottom: 5px;
    margin-top: 10px;
}

.body__products-page--salesforce .inventory__section-warehouse_details h6 {
    margin-top: 12.5px;
    margin-bottom: 7.55px;
    font-size: 0.8rem;
}

.body__products-page--salesforce .inventory__section-warehouse_details .warehouse_details--max-stock .max_units_in_warehouse {
    display: flex;
    gap: 5px;
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--primary-black);
    margin-bottom: 5px;
}

.body__products-page--salesforce .inventory__section-warehouse_details.even {
    background-color: var(--secondairy-ultra-light-gray);
}

.body__products-page--salesforce .inventory__section-warehouse_details .warehouse_details--warehouse-load {
    height: 40px;
    margin-top: 40px;
}

.body__products-page--salesforce .inventory__section-warehouse_details .warehouse_details--warehouse-load .load-bar {
    position: relative;
    height: 50%;
    width: 90%;
    border: solid 1px var(--secondairy-light-medium-gray);
    border-radius: 20px;
    overflow: hidden;
}

.body__products-page--salesforce .inventory__section-warehouse_details .warehouse_details--warehouse-load .load-bar div:nth-child(1) {
    background: linear-gradient(90deg, rgba(7, 196, 0, 0.50) 0%, rgba(180, 231, 2, 0.50) 25%, rgba(192, 232, 0, 0.50) 50%, rgba(232, 222, 0, 0.50) 75%, rgba(255, 0, 0, 0.50) 100%);
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.body__products-page--salesforce .inventory__section-warehouse_details .warehouse_details--warehouse-load .load-bar div:nth-child(2) {
    position: absolute;
    background-color: var(--secondairy-ultra-light-gray);
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.body__products-page--salesforce .inventory__section-products {
    grid-area: products;
}

.body__products-page--salesforce .inventory__section-materials {
    grid-area: materials;
}

.body__products-page--salesforce .inventory__section .table {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.body__products-page--salesforce .inventory__section .table__header-3 {
    grid-template: 35px / 0.6fr 1fr 0.2fr;
    font-size: 0.6rem;
}

.body__products-page--salesforce .inventory__section .table__row-3 {
    grid-template: auto / 0.6fr 1fr 0.2fr;
    font-size: 0.6rem;
}

.body__products-page--salesforce .inventory__section:nth-child(even) .table__row-3:nth-child(even) {
    background-color: white;
}

.body__products-page--salesforce .popup__content_wrapper {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.body__products-page--salesforce .create_inbound__form .more-products--btn {
    height: 40px;
}

.body__products-page--salesforce .create_inbound__add_product-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.body__products-page--salesforce .create_inbound__add_product-container #delete-icon {
    font-size: 35px;
    color: var(--light-red);
    cursor: pointer;
    width: 100%;
}

.body__products-page--salesforce .create_inbound__add_product-container .delete {
    display: flex;
    justify-content: center;
}

.body__products-page--salesforce .create_inbound__add_product-container .input_field__input_box--text.product {
    height: 40px;
    width: 80px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 0.9rem;
    text-align: center;
}

.body__products-page--salesforce .create_inbound__form .create_inbound__pickup-container {
    width: 40%;
    margin: 30px 0 20px 0;
}

.body__products-page--salesforce .create_inbound__form .create_inbound__pickup-details {
    width: 100%;
}

.body__products-page--salesforce .create_inbound__form .create_inbound__pickup-details .input_field__input_box--text {
    font-size: 0.75rem;
}

#popout__inventory .input_field__input_box--text.big {
    margin-top: 20px;
    width: 100%;
    font-size: 0.75rem;
    height: 80px;
}

#popout__inventory .popout__box {
    height: 75%;
}

#popout__inventory #create_inbound__container {
    height: 100%;
}

.warehouse-title {
    position: absolute;
    left: -20px;
    top: -35px;
    color: var(--secondairy-medium-gray);
    font-weight: 600;
    font-size: 0.75rem;
    /* text-align: center; */
    width: 100%;
    padding: 5px 10px;
    border-radius: 20px;
}

.inventory_buttons {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 7.5px;
}

.inventory_buttons .settings_icon {
    color: var(--secondairy-medium-gray);
    font-size: 1.2rem;
    cursor: pointer;
}

.plus {
    top: 0;
    right: 0;
    z-index: 100;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    border: none;
    background-color: var(--primary-green);
    opacity: 0.8;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plus::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 8px;
    background-color: white;
    cursor: pointer;
}

.plus::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 2px;
    background-color: white;
    cursor: pointer;
}


.min {
    top: 0;
    right: 0;
    z-index: 100;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    border: none;
    background-color: var(--light-red);
    opacity: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.min::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 2px;
    background-color: white;
    cursor: pointer;
}

.body__section .unfold-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    transform: rotate(45deg);
    color: var(--secondairy-medium-gray);
    cursor: pointer;
}