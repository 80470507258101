.dropdown__container-dropdown {
  position: relative;
}

.dropdown__container-dropdown .dropdown__title {
  color: var(--secondairy-medium-gray);
  font-size: 0.75rem;
  font-weight: 300;
  margin-bottom: 10px;
}

.dropdown__container-dropdown .input_field__dropdown.classic {
  position: absolute;
  top: 43px;
}

.dropdown__container-dropdown .input_field__dropdown.classic.inherit {
  position: inherit;
  top: 0;
}

.dropdown__container-dropdown .input_field__dropdown.classic .dropdown__item {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
}

.dropdown__container-dropdown .input_field__dropdown.classic .dropdown__item--single {
  width: 100%;
  display: grid;
  grid-template: auto / 1fr;
}

.dropdown__container-dropdown .input_field__dropdown.classic .dropdown__item--single {
  width: 100%;
  display: grid;
  grid-template: auto / 1fr;
}