.popout_container__mobile {
    position: absolute;
    z-index: 500;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    opacity: 0;

    transition: all 300ms ease-in-out;
}

.popout_container__mobile.active {
    display: flex;
    opacity: 1;
}

.notification-popup__elements--mobile {
    background-color: var(--primary-black);
    width: 75%;
    min-height: 30%;
    max-height: 50%;
    border-radius: 7.5px;
    padding: 25px 20px;
}

.notification-popup__elements--mobile .notification-popup__elements__title--mobile {
    color: white;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}

.notification-popup__elements--mobile .notification-popup__elements__text--mobile {
    color: white;
    font-size: 0.85rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.notification-popup__elements--mobile .notification-popup__elements__text_item--mobile {
    border: 1px solid var(--secondairy-medium-gray);
    border-radius: 5px;
    padding: 7.5px;
}