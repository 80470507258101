.template__invoice {
    width: 595px;
    height: 842px;
    margin: auto;
    display: grid;
    grid-template: 20% 20% 50% 10% / 1.7fr 1fr;
    grid-template-areas: 
    "header header"
    "seller client"
    "order order"
    "footer footer"
    ;
}

.template__invoice .table__header-6 {
    background-color: var(--secondairy-ultra-light-gray);
    font-size: 0.7rem;
    grid-template: 35px / 2fr 1fr 1fr 1fr 1fr 1fr;

}

.template__invoice .table__row-6 {
    grid-template: auto / 2fr 1fr 1fr 1fr 1fr 1fr;

}

.template__invoice .flex p:first-child {
    margin-right: 7px;
}

.template__invoice .invoice__header {
    grid-area: header;
    padding-top: auto;
}

.template__invoice .invoice__header h1{
    margin-left: auto;
}

.template__invoice .invoice__details--seller {    
    grid-area: seller;
    font-size: 0.8rem;
    padding-top: 34.2px;
}

.template__invoice .invoice__details--client {    
    grid-area: client;
    font-size: 0.8rem;
    padding-top: 34.2px;
    padding-left: 30px;
}

.template__invoice .invoice__details--order {    
    grid-area: order;
}

.template__invoice .invoice__footer {  
    grid-area: footer;
    font-size: 0.7rem;
}

.template__invoice .invoice__footer div:last-child {    
    margin-left: auto;
}