.dropdown_display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondairy-light-gray-plus);
    border: none;
    border-radius: 5px;
    height: 60px;
    width: 100%;
  
    text-align: center;
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 15px;
    color: var(--primary-black);
    text-align: left;
    padding: 7.5px;
  }
  
  .dropdown_display svg {
    font-size: 2.5rem;
    color: var(--secondairy-medium-gray);
  }
  
  .dropdown_display .placeholder {
    font-weight: 300;
    color: var(--primary-black);
    opacity: 0.33;
    font-style: normal;
  }
  
  .dropdown_display.loading {
    background: linear-gradient(90deg, var(--secondairy-light-gray) 25%, var(--secondairy-ultra-light-gray) 50%, var(--secondairy-light-gray) 75%);
    background-size: 200% 100%;
    border: 1px var(--secondairy-light-gray-plus) solid;
    animation: skeleton-loading-input 1.5s infinite;
    pointer-events: none; /* Disable interactions */
    color: transparent; /* Hide text color */
  }
  
  .dropdown_display.loading svg,
  .dropdown_display.loading p {
    color: transparent; /* Hide text color */
  }

  /*  ANCHOR POPUP  */

  .popup_dropdown--mobile {
    position: relative;
    width: 100%;
  }
  
  .popup_dropdown--mobile.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .popup_dropdown--mobile .placeholder {
    color: #999;
  }
  
  .popup_dropdown__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(10px);
  }
  
  .popup_dropdown__container .clear_icon {
    position: absolute;
    top: 10%;
    right: 10%;
    color: var(--secondairy-light-medium-gray);
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }
  
  .popup_dropdown__container .search_bar__dropdown--mobile {
    position: absolute;
    top: 17.5%;
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .popup_dropdown__container .clear_icon > p {
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-right: 5px;
  }
  
  .popup_dropdown__list {
    height: 250px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 100px 0;
  }
  
  .popup_dropdown__item {
    height: 50px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #666;
    text-align: center;
    transition: transform 0.3s, font-size 0.3s, opacity 0.3s;
    opacity: 0.5;
  }
  
  .popup_dropdown__item.selected {
    font-size: 18px;
    color: var(--primary-black);
    opacity: 1;
    transform: scale(1.5);
  }
  
  .popup_dropdown__container .selected_view {
    position: absolute;
    background-color: var(--secondairy-light-medium-gray);
    opacity: 50%;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 20px);
    height: 50px;
  }
  
  /* ANCHOR DARK THEMED FILTER VERSION */
  
  .popup_dropdown__container.dark-theme {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 100%;
  }
  
  .popup_dropdown__container.dark-theme .popup_dropdown__item {
    color: var(--secondairy-light-medium-gray);
  }
  
  .popup_dropdown__container.dark-theme .popup_dropdown__item.selected {
    color: var(--secondairy-light-medium-gray);
  }
  
  .popup_dropdown__container.dark-theme .selected_view {
    background-color: var(--secondairy-medium-gray);
  }
  
  .no-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }