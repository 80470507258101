.search-bar--filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
    border: none;
    border-radius: 7.5px;
    width: 210px;
    height: 30px;
    font-size: 0.7rem;
    color: var(--secondairy-medium-gray);
    font-weight: 400;
    cursor: pointer;
    background-color: white;
    box-shadow: var(--border-shadow-min);
    margin: 0;
    padding: 7.5px;
}

.search-bar--filter.filter_active {
    background-color: var(--light-primary-green);
}

.search-bar--filter.filter_active input {
    background-color: var(--light-primary-green);
}

.search-bar--filter svg {
    font-size: 1.2rem;
}

.search-bar--filter input {
    border: none;
    border-radius: 7.5px;
    width: 100%;
    height: 100%;
    padding: 7.5px;
    font-size: 0.75rem;
    color: var(--secondairy-medium-gray);
    font-weight: 400;
    font-style: italic;
}

.search-bar--filter input::placeholder {
    font-style: italic;
    color: var(--secondairy-medium-gray);
}

.search-bar--filter input:focus {
    outline: none;
}