.login-form.landingpage p {
  color: var(--secondairy-medium-gray);
  line-height: 1.3rem;
  text-align: center;
  font-size: 1.1rem;
  margin: 0 0 25px 0;
}

.login-form.landingpage button {
  width: 100%;
}

.login-form.landingpage a {
  border-bottom: black solid 1px;
  cursor: pointer;
}

.login-form.landingpage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.logo {
  position: absolute;
  top: 40px;
  width: 42.5vh;
  border: none;
}

.verification_boxes {
  position: relative;
  display: flex;
  gap: 10px;
  height: 40%;
}

.login-form > p{
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.3rem;
  margin: 0 0 25px 0;
}

.login-form .verification_box {
  height: 100%;
  border-radius: 15px;
  font-size: 3rem;
  color: var(--secondairy-medium-gray);
}



