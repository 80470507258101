#mobile-activities-page {
  display: grid;
  grid-template: repeat(8, 1fr) / 1fr 1fr;
  gap: 7.5px;
}

#mobile-activities-page #header--mobile {
  position: relative;
  grid-column: span 2;
  grid-row: span 1;
  overflow: hidden;
}

#mobile-activities-page #activities-table {
  grid-column: span 2;
  grid-row: span 7;
  padding: 0;
  min-height: 100%;
}

#mobile-activities-page #activities-table .circular_loading_spinner__wrapper {
  display: flex;
  align-items: baseline;
  padding-top: 30px;
}

#mobile-activities-page .table {
  overflow: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

#mobile-activities-page .table .table__row_wrapper .table__row--field--arrow  {
  right: 10px;
}

#mobile-activities-page #activities-table .activity_container--mobile {
  display: grid;
  grid-template: auto auto 27.5px  / 50px 50px 1fr 1.5fr;
  min-height: 60px;
  margin-bottom: 10px;
  font-size: 0.9rem;
  border-left: 5px solid var(--secondairy-medium-gray);
  background-color: var(--secondairy-light-gray-plus);
  padding: 7.5px 5px;
  border-radius: 5px;
}

#mobile-activities-page #activities-table .activity_container--mobile.overdue {
  background-color: var(--mobile-light-red);
  border-left: var(--mobile-red) solid 5px;
}

#mobile-activities-page #activities-table .activity_container--mobile.done {
  background-color: var(--light-primary-green);
  border-left: var(--primary-green) solid 5px;
}

#mobile-activities-page #activities-table .activity_container--mobile .activity_data_field.title > svg {
  height: 20px;
  width: 20px;
  fill: var(--secondairy-medium-gray);
  margin-right: 7.5px;
}

#mobile-activities-page #activities-table .activity_container--mobile.overdue .activity_data_field.title > svg {
  height: 20px;
  width: 20px;
  fill: var(--mobile-red);
  margin-right: 7.5px;
}

#mobile-activities-page #activities-table .activity_container--mobile.done .activity_data_field.title svg {
  height: 20px;
  width: 20px;
  fill: var(--primary-green);
  margin-right: 7.5px;
}

#mobile-activities-page #activities-table .activity_container--mobile .activity_data_field.check-box {
  grid-row: span 3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

#mobile-activities-page #activities-table .activity_container--mobile .activity_data_field.picto {
  grid-row: span 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

#mobile-activities-page #activities-table .activity_container--mobile .activity_data_field.title {
  grid-area: 1/2/1/-1;
  display: flex;
  align-items: top;
  justify-content: left;
}


#mobile-activities-page #activities-table .activity_container--mobile .activity_data_field.customer {
  grid-area: 2/2/2/-1;
  color: var(--secondairy-medium-gray);
  font-size: 0.75rem;
  padding-top: 2.5px;
}

#mobile-activities-page #activities-table .activity_container--mobile .activity_data_field.owner {
  grid-area: 3/2/3/4;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  color: var(--secondairy-medium-gray);
  font-size: 0.75rem;
}

#mobile-activities-page #activities-table .activity_container--mobile .activity_data_field.date {
  grid-area: 3/4/3/-1;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
}

#mobile-activities-page #activities-table .activity_container--mobile .checkbox__container.mobile {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}