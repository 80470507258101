.dropdown__container-dropdown.multiselect .input_field__input_box--text.dropdown.classic {
  display: flex;
  
}

.dropdown__container-dropdown.multiselect .input_field__dropdown.classic {
  position: inherit;
  top: 0;
}

.dropdown__container-dropdown.multiselect .input_field__dropdown.classic .dropdown__item {
  position: relative;
  width: 100%;
  display: grid;
  grid-template: auto / 0fr 1fr 1fr;
}

.dropdown__container-dropdown.multiselect .input_field__dropdown.classic .dropdown__item--single {
  position: relative;
  width: 100%;
  display: grid;
  grid-template: auto / 1fr;
}

.dropdown__container-dropdown.multiselect .input_field__dropdown.classic .dropdown__item .dropdown_checkmark{
  display: none;
}

.dropdown__container-dropdown.multiselect .input_field__dropdown.classic .dropdown__item--single .dropdown_checkmark{
  display: none;
}

.dropdown__container-dropdown.multiselect .dropdown-input--text_field {
  position: relative;
  background-color: var(--light-primary-green);
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 7.5px;
}

.dropdown__container-dropdown.multiselect .dropdown-input--text_field.gray {
  background-color: var(--secondairy-light-gray);
}

.dropdown__container-dropdown.multiselect .dropdown-input--text_field svg {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8rem;
  background-color: white;
  border-radius: 50%;
  height: 10px;
  transform: translate(40%, -40%);
}




