.loading-overlay__container {
    position: absolute;
    z-index: 50;
    right: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
}

