.flatpickr_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  border: none;
  border-radius: 7.5px;
  width: 175px;
  height: 30px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 400;
  cursor: pointer;
  background-color: white;
  box-shadow: var(--border-shadow-min);
  margin: 0;
}

.flatpickr_wrapper.filter_active {
  background-color: var(--light-primary-green);
}

.flatpickr_wrapper.filter_active input {
  background-color: var(--light-primary-green);
}

.flatpickr_wrapper svg {
  margin-right: 5px;
  pointer-events: all;
}

.flatpickr_wrapper input {
  border: none;
  border-radius: 7.5px;
  width: 100%;
  height: 100%;
  padding: 7.5px;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
  font-weight: 400;
  font-style: italic;
}

.flatpickr_wrapper input::placeholder {
  font-size: 0.75rem;
  font-style: italic;
  color: var(--secondairy-medium-gray);
}

.flatpickr_wrapper input:focus {
  outline: none;
}

.flatpickr_wrapper p {
  position: absolute;
  left: 7.5px;
  font-style: italic;
  pointer-events: none;
  font-size: 0.75rem;
}