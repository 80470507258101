.body__admin-invoices-page {
    background-color: white;
    background-image: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 7.5px;
    overflow-y: scroll;
    overflow-x: none;
    height: 100%;
}

.loading_spinner_admin_invoice {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}