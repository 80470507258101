.popup_window__container {
  position: absolute;
  top: 50vh;
  right: -105vw;
  width: 100vw;
  height: 100vh;
  background-color: var(--secondairy-light-gray);
  z-index: 50;
  transition: all 600ms ease-in-out;
}

.popup_window__container.active {
  transform: translate(-105vw, -50vh);
}

.popup_window__container .popup_header {
  position: relative;
  height: 70px;
  padding: 10px;
}

.popup_window__container .popup_header .header__buttons {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
}

.popup_window__container .popup_header .header__buttons .green-button-header, 
.popup_window__container .popup_header .header__buttons .red-button-header {
  position: relative;
  right: 0;
}

.popup_window__container .popup_header .header__buttons .header__button {
  height: 50px;
  width: 50px;
  background-color: var(--primary-green);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondairy-light-gray);
  font-size: 1.8rem;
  box-shadow: var(--border-shadow-float);
}

.popup_window__container .popup_header .header__buttons .header__button.side-menu {
  z-index: 50;
}

.popup_window__container .go_back {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  gap: 5px;
  text-transform: uppercase;
  height: 50px;
  width: 50px;
  color: var(--secondairy-medium-gray);
  font-size: 1.2rem;
}

.popup_window__container .popup_body {
  height: calc(100% - 130px);
  overflow: scroll;
  padding: 10px;
}

.popup_window__container .popup_body::-webkit-scrollbar {
  display: none;
}

.popup_window__container .popup_body h1 {
  color: var(--secondairy-medium-gray);
  text-transform: uppercase;
  font-size: 1.2rem;
  margin: 30px 0;
  width: 100%;
  text-align: center;
}

.popup_window__container .popup_body h2 {
  color: var(--secondairy-medium-gray);
  text-transform: uppercase;
  font-size: 1rem;
  margin: 30px 0;
  width: 100%;
  text-align: center;
}