#mobile-sales-page--brandowner {
    display: grid;
    grid-template: repeat(8, 1fr) / 1fr 1fr;
    gap: 7.5px;
}

#mobile-sales-page--brandowner #header--mobile {
    position: relative;
    grid-column: span 2;
    grid-row: span 1;
    overflow: hidden;
}


#mobile-sales-page--brandowner #header--mobile .header__buttons {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 50px;

}

#mobile-sales-page--brandowner #header--mobile .header__buttons .header__button {
    height: 50px;
    width: 50px;
    background-color: var(--primary-green);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondairy-light-gray);
    font-size: 1.8rem;
    box-shadow: var(--border-shadow-float);
}

#mobile-sales-page--brandowner #sales-list {
    grid-column: span 2;
    grid-row: span 7;
    padding: 0;
}

#mobile-sales-page--brandowner #sales-list .circular_loading_spinner__wrapper {
    display: flex;
    align-items: baseline;
    padding-top: 30px;
}

#mobile-sales-page--brandowner .table {
    overflow: scroll;
    overflow-x: hidden;
}

#mobile-sales-page--brandowner .table__row-5--mobile {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1.5rem 30px;
}

#mobile-sales-page--brandowner .table__row-5--mobile>div:nth-child(1) {
    color: var(--secondairy-medium-gray);
    font-weight: 300;
}

#mobile-sales-page--brandowner .table__row-5--mobile>div:nth-child(3) {
    color: var(--secondairy-medium-gray);
    font-weight: 300;
}

#mobile-sales-page--brandowner .table__row-5--mobile>div:nth-child(2) {
    font-weight: 400;
    grid-column: span 2;
}

#mobile-sales-page--brandowner .table__row-5--mobile .label {
    height: 17.5px;
}