#mobile-homepage--brandowner {
    display: grid;
    grid-template: repeat(8, 1fr) / 1fr 1fr;
    gap: 7.5px;
}

#mobile-homepage--brandowner #header--mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--secondairy-medium-gray);
}

#mobile-homepage--brandowner #header--mobile > h1 {
    font-size: 1.75rem;
}

#mobile-homepage--brandowner #header--mobile > svg {
    font-size: 1.75rem;
    color: var(--mobile-red);
}

#mobile-homepage--brandowner #header--mobile {
    grid-column: span 2;
    grid-row: span 1;
    border: none;
}

#mobile-homepage--brandowner #total-sales{
    grid-column: span 2;
    grid-row: span 2;
}

#mobile-homepage--brandowner #activities {
    grid-column: span 2;
    grid-row: span 3;
    display: flex;
    flex-direction: column;
}

#mobile-homepage--brandowner #bestsellers {
    grid-column: span 1;
    grid-row: span 2;
}

#mobile-homepage--brandowner #my-sales {
    grid-column: span 1;
    grid-row: span 2;
}