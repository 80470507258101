.popout__container.active {
    pointer-events: all;
    opacity: 1; 
}

.popout__container .popup__options-button {
    position: relative;
    left: 0;
    top: 0;
}

.popout__container #popup__close-icon {
    position: absolute;
    color: var(--primary-black);
    font-size: 0.7rem;
    right: 8px;
    top: 8px;
    cursor: pointer;
    background-color: var(--secondairy-ultra-light-gray);
    border: 1px solid var(--secondairy-light-gray);
    border-radius: 20px;
    width: 25px;
    height: 18px;
    padding: 3px;
}

.popout__container h3 {
    font-weight: 700;
    color: var(--primary-black);
    font-variant: all-small-caps;
    margin: 10px 0;
    font-size: 1rem;
}

.popout__container .form__section {
    padding: 0;
    margin-top: 20px;
    padding-bottom: 40px;
    border: none;
    border-radius: 0;
    overflow: scroll;
}

.warehouse_settings__conflicts_table {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    gap: 10px;
    color: var(--red);
    font-weight: 500;
    font-size: 0.9rem;
}

.popup__content_wrapper .warehouse_pictos {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-evenly;
    gap: 10px;
    padding: 0 0 25px 0;
}

.popup__content_wrapper .warehouse_pictos .warehouse_img_parent {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--secondairy-light-gray) 1px solid;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.popup__content_wrapper .warehouse_pictos .warehouse_img_parent.selected {
    border: var(--secondairy-medium-gray) 1px solid;
    background-color: var(--secondairy-ultra-light-gray);
}

.popup__content_wrapper .warehouse_pictos .warehouse_img {
    height: 80%;
}