.label {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  padding: 5px 22.5px;
}

.label h4 {
  font-size: 0.60rem;
}

.label .ball {
  position: absolute;
  height: 8px;
  width: 8px !important;
  left: 7.5px;
  border-radius: 50px;
}

.label.purple {
  background-color: var(--light-purple) !important;
}

.label.purple h4 {
  color: var(--dark-purple) !important;
}

.label .ball.purple {
  background-color: var(--purple) !important;
}

.label .ball.purple.bright {
  border: 2px solid var(--purple) !important;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--purple) !important;
}

.label.green {
  background-color: var(--light-primary-green) !important;
}

.label.green h4 {
  color: var(--dark-green) !important;
}

.label .ball.green {
  background-color: var(--primary-green) !important;
}

.label .ball.green.bright {
  border: 2px solid var(--primary-green) !important;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--primary-green) !important;
}

.label.gold {
  background-color: var(--special-gold-ultra-light) !important;
}

.label.gold h4 {
  color: var(--special-gold-dark) !important;
}

.label .ball.gold {
  background-color: var(--special-gold) !important;
}

.label .ball.gold.bright {
  border: 2px solid var(--special-gold) !important;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--special-gold) !important;
}

.label.blue {
  background-color: var(--light-blue) !important;
}

.label.blue h4 {
  color: var(--blue) !important;
}

.label .ball.blue {
  background-color: var(--blue) !important;
}

.label .ball.blue.bright {
  border: 2px solid var(--blue) !important;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--blue) !important;
}

.label.grey {
  background-color: var(--secondairy-light-gray) !important;
}

.label.grey h4 {
  color: var(--secondairy-medium-gray) !important;
}

.label .ball.grey {
  background-color: var(--secondairy-medium-gray) !important;
}

.label .ball.grey.bright {
  border: 2px solid var(--secondairy-medium-gray) !important;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--secondairy-medium-gray) !important;
}

.label.red {
  background-color: var(--light-red) !important;
}

.label.red h4 {
  color: var(--dark-red) !important;
}

.label .ball.red {
  background-color: var(--red) !important;
}

.label .ball.red.bright {
  border: 2px solid #ff0000;
  border-radius: 10px;
  box-shadow: 0 0 5px #ff0000;
}

.label.grey.high-contrast {
  border: 1px solid var(--secondairy-medium-gray);
}

.label.blue.high-contrast {
  border: 1px solid var(--blue);
}

.label.green.high-contrast {
  border: 1px solid var(--dark-green);
}

.label.red.high-contrast {
  border: 1px solid var(--red);
}

.label.gold.high-contrast {
  border: 1px solid var(--special-gold-dark);
}
