.add-product__container {
  display: grid;
  grid-template: auto / 25% 60px 170px 200px 1fr 50px;
  margin-top: 10px;
  gap: 10px;
  box-sizing: border-box;
}


.add-product__container .delete {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-product__container #delete-icon {
  color: var(--light-red);
  cursor: pointer;
  min-width: 40px;
  width: 100%;
  font-size: 1.5rem;
}

.add-product__container .product__dropdown__container {
  width: 60px;
}

#new-sales-form-brandowner .add-product__container .product__dropdown__container .input_field__input_box--text {
  margin-bottom: 0;
  width: 100%;
  margin-top: 0;
}

.add-product__container .input_field__input_box--text.product {
  height: 56px;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0;
}

.add-product__container .checkboxes {
  display: flex;
  justify-content: center;
  margin-top: 0px;
}

.add-product__container .checkboxes .checkboxes_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.add-product__container .input_field__input_box--text.big {
  height: 35px;
  margin-top: 0;
  min-height: 35px;
  resize: vertical;
}

.add-product__container .checkbox__container {
  height: 35px;
  max-width: 45px;
  width: 100%;
  padding: 0 5px;
}

.add-product__container .checkbox--box {
  margin-right: 0;
}

.add-product__container .checkbox--text {
  display: none;
}

.add-product__container .vendor__dropdown__container.sales {
  width: 100%;
  margin-top: 0;
  cursor: pointer;
}


