/* requires LoginPage.css */

.message {
 width: 80%;
 margin-bottom: 25px;
 color: var(--secondairy-medium-gray);
 line-height: 1.2rem;
 text-align: center;
 font-size: 0.85rem;
 overflow: hidden;
 height: fit-content;
}

.body-loginpage.forgot_password .login-form {
  height: 40vh;
}

/* .body-loginpage .logo {
  position: absolute;
  top: 40px;
  width: 430px;
}

.body-loginpage .logo #forgot-password-logo {
  position: relative;
  top: 0;
  width: 430px;
} */