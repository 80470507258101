.search-bar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  border: none;
  border-radius: 7.5px;
  height: 30px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
  cursor: pointer;
  background-color: white;
  box-shadow: var(--border-shadow-min);
  margin: 0;
}

.search-bar-header svg {
  margin-right: 5px;

}

.search-bar-header input {
  border: none;
  border-radius: 7.5px;
  width: 100%;
  height: 100%;
  padding: 7.5px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
  font-style: italic;
}

.search-bar-header input::placeholder {
  font-style: italic;
  color: var(--secondairy-medium-gray);
}

.search-bar-header input:focus {
  outline: none;
}