.product__dropdown__container {
  position: relative;
  width: 100%;
  margin-left: 10px;
}

.product__dropdown__container .input_field__input_box--text.sales {
  /* requires index.css */
  display: flex;
  align-items: center;
  gap: 5px;
}

.product__dropdown__container .input_field__input_box--text p {
  display: flex;
  gap: 5px;
  font-weight: 400;
  font-style: normal;
}

.product__dropdown__container .input_field__input_box--text svg {
  margin-left: auto;
  height: 120%;
}

.product__dropdown__container .input_field__dropdown {
  max-height: 190px;
}

.product__dropdown__container .input_field__dropdown.inherit {
  top: 0;
  max-height: 30vh;
}

.product__dropdown__container .input_field__dropdown li {
  width: 100%;
  display: flex;
}

.product__dropdown__container .input_field__dropdown li div:first-child {
  width: 80%;
}

.product__dropdown__container .dropdown__container .input_field__input_box--text .dropdown-input--text_field {
  width: 85%;
  justify-content: space-between;
}

.product__dropdown__container .dropdown__list__tabs {
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color: white;
  padding-top: 5px;
}

.product__dropdown__container .dropdown__list__tab {
  display: flex;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  padding: 7.5px;
  background-color: white;
  font-size: 0.65rem;
  border: var(--secondairy-ultra-light-gray) solid 2px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  color: var(--secondairy-medium-gray);
  font-weight: 500;
}

.product__dropdown__container .dropdown__list__tab.selected {
  background-color: var(--secondairy-ultra-light-gray);
}
