#popup-window-create-customer {
  position: absolute;
  top: 50vh;
  right: -105vw;
  width: 100vw;
  height: 100vh;
  background-color: var(--secondairy-light-gray);
  z-index: 150;
  transition: all 600ms ease-in-out;
}

#popup-window-create-customer.active {
  transform: translate(-105vw, -50vh);
}

#popup-window-create-customer .popup_header {
  position: relative;
  height: 70px;
  padding: 10px;
}

#popup-window-create-customer .go_back {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  gap: 5px;
  text-transform: uppercase;
  height: 50px;
  width: 50px;
  color: var(--secondairy-medium-gray);
  font-size: 1.2rem;
}

#popup-window-create-customer .popup_body {
  position: relative;
  height: calc(100% - 130px);
}

#popup-window-create-customer .dropdown_field {
  background-color: var(--secondairy-light-gray);
  border: 1px solid var(--secondairy-light-gray-plus);
  padding: 0;
  width: 100%;
  height: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
  font-size: 0.85rem;
}

#popup-window-create-customer .dropdown_field .limit {
  padding: 7.5px 5px;
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

#popup-window-create-customer .dropdown_field .limit > svg {
  font-size: 1rem;
  color: var(--secondairy-light-gray-plus);
}

#popup-window-create-customer .dropdown_field .circular_loading_spinner__wrapper {
  padding: 10px 0;
}

#popup-window-create-customer .dropdown_field__item {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding: 10px 10px;
}

#popup-window-create-customer .dropdown_field__item:nth-child(even) {
  background-color: var(--secondairy-light-gray-plus);
}

#popup-window-create-customer .dropdown_field__item:hover {
  background-color: var(--secondairy-light-gray);
}

#popup-window-create-customer .dropdown_field__item > div {
  color: var(--secondairy-medium-gray);
  cursor: pointer;
}

#popup-window-create-customer .green-button-header {
  z-index: 150;
}

#popup-window-create-customer .api_options {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  width: 30%;
}

#popup-window-create-customer .business-central-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--secondairy-light-medium-gray);
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--secondairy-light-medium-gray);
}

#popup-window-create-customer .business-central-icon.clicked {
  background-color: var(--light-primary-green);
  border-color: var(--primary-green);
  color: white;
}

#popup-window-create-customer .business-central-icon svg {
  width: 20px;
  height: 20px;
}

#popup-window-create-customer .other_options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
}

#popup-window-create-customer .suppliers_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#popup-window-create-customer .suppliers_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#popup-window-create-customer .add-supplier {
  display: flex;
  flex-direction: column;
  gap: 7.5px;
}

#popup-window-create-customer .add-supplier .input_text--mobile {
  margin: 0;
}

#popup-window-create-customer .suppliers_list .supplier_item {
  display: grid;
  grid-template: 60px 60px / 1fr 0.25fr;
  gap: 5px;
}

#popup-window-create-customer .suppliers_list .supplier_item .supplier_item__name,
#popup-window-create-customer .suppliers_list .supplier_item .supplier_item__alias {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 7.5px;
  border-radius: 5px;
  background-color: var(--secondairy-light-gray-plus);
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary-black);
  width: 100%;
}

#popup-window-create-customer .suppliers_list .supplier_item .supplier_item__name {
  grid-area: 1 / 1 / 1 / 3;
}

#popup-window-create-customer .suppliers_list .supplier_item .supplier_item__alias {
  grid-area: 2 / 1 / 2 / 2;
}

#popup-window-create-customer .suppliers_list .supplier_item .delete-btn {
  grid-area: 2 / 2 / 2 / 3;
  background-color: var(--ultra-light-red);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  color: var(--dark-red);
}

#popup-window-create-customer .add--btn.mobile {
  height: 60px;
  border: none;
  border-radius: 5px;
  background-color: var(--light-primary-green);
  color: var(--primary-green);
  cursor: pointer;
}

#popup-window-create-customer .add--btn.mobile:disabled {
  background-color: var(--secondairy-light-gray-plus);
  color: var(--secondairy-light-medium-gray);
  cursor: not-allowed;
}
