.body__home-brandowner {
  display: grid;
  grid-template: repeat(6, 1fr) / repeat(8, 1fr);
  grid-template-areas: 
    "sec1 sec1 sec1 sec1 sec2 sec2 sec3 sec3"
    "sec1 sec1 sec1 sec1 sec2 sec2 sec3 sec3"
    "sec1 sec1 sec1 sec1 sec2 sec2 sec3 sec3"
    "sec4 sec4 sec4 sec5 sec5 sec5 sec5 sec5"
    "sec4 sec4 sec4 sec5 sec5 sec5 sec5 sec5"
    "sec4 sec4 sec4 sec5 sec5 sec5 sec5 sec5"
  ;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.body__home-brandowner .body__section h2 {
  font-weight: 700;
  color: var(--primary-green);
  font-variant: small-caps;
  margin-bottom: 10px;
}

.body__home-brandowner .body__section h2 p {
  font-size: 0.6rem;
  margin-left: 10px;
  margin-top: 7.5px;
}

.body__home-brandowner .sales-period-filter {
  font-weight: 700;
  color: var(--primary-green);
  font-variant: small-caps; 
  font-size: 0.6rem;
  margin-left: 10px;
  margin-top: 7.5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.body__home-brandowner .sales-period-filter.underlined {
  text-decoration: underline;
}

.body__home-brandowner #section1 {
  position: relative;
  grid-area: sec1;
  height: 100%;
  width: 100%;
}

.body__home-brandowner .chart {
  position: relative;
  height: 80%;
  width: 100%;
}

.body__home-brandowner #section2 {
  grid-area: sec2;
  height: 100%;
  width: 100%;
}

.body__home-brandowner #section3 {
  grid-area: sec3;
  height: 100%;
  width: 100%;
}

.body__home-brandowner #section4 {
  grid-area: sec4;
  height: 100%;
  width: 100%;
}

.body__home-brandowner #section6 {
  grid-area: sec6;
  height: 100%;
  width: 100%;
}

.body__home-brandowner #section6 .ranking {
  height: calc(100% - 29.6px - 10px);
  overflow: scroll;
}

.body__home-brandowner #section5 {
  grid-area: sec5;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}

.body__home-brandowner .centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8rem;
}

.body__home-brandowner p {
  font-size: 0.8rem;

}

.body__home-brandowner .indicator__container {
  display: flex;
  margin-left: 10%;
}

.body__home-brandowner .indicator {
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 7.5px;
  margin-left: 5px;
}

.body__home-brandowner .indicator__arrow-up {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 5px solid var(--primary-green);
}

.body__home-brandowner .indicator__arrow-down {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-top: 5px solid var(--red);
}

.body__home-brandowner .indicator__number {
  font-size: 0.6rem;
  margin-left: 2.5px;
  font-weight: 500;
}

.body__home-brandowner .indicator__number.red {
  color: var(--red);
}

.body__home-brandowner .indicator__number.green {
  color: var(--green);
}



