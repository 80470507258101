.body__customers {
  width: 100%;
  height: 100%;
}

.body__customers.hide {
  display: none;
  pointer-events: none;
}

.body__section  {
  /* reguires index.css */
  transition: height 500ms ease-in-out;
}

#new-customer-form {
  width: 100%;
  height: 65%;
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  gap: 10px;
}

#all-customers-table {
  width: 100%;
  height: 35%;
  overflow: hidden;
}

#customer--table {
  transition: transform 0.5s ease-in-out;
}

.body__customers .table__header-9 {
  /* requires index.css */
  display: grid;
  grid-template: 35px / 45px 0.8fr 0.9fr 0.8fr 0.7fr 0.6fr 1.1fr 0.6fr 0.7fr;
  font-size: 0.7rem;
}
.body__customers .table__header-9 .table__header--field {
  cursor: pointer;
}

.body__customers .table__row-9 {
  /* requires index.css */
  display: grid;
  grid-template: 30px / 45px 0.8fr 0.9fr 0.8fr 0.7fr 0.6fr 1.1fr 0.6fr 0.7fr;
  font-size: 0.7rem;
  word-break: break-all;
  padding-right: 10px;
  cursor: pointer;
}

.body__customers .table__row-9 .table__row--field {
  word-break: break-all;
}

#new-customer-form .secondairy-button {
  /* requires index.css */
  height: 30px;
  margin-top: 5px;
  margin-left: 5px;
}

#duplicate-btn-delivery {
  margin: 0 !important;
  width: 100%;
}

#customers__expand_icon, #customers__shrink_icon {
  transform: rotate(45deg);
  font-size: 1.3rem;
  cursor: pointer;
}

#customer__table-btns {
  align-items: flex-end;
  justify-content: flex-end;
}

#customers__search-bar--table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  border: none;
  border-radius: 7.5px;
  width: 25%;
  height: 30px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
  cursor: pointer;
  background-color: white;
  box-shadow: var(--border-shadow-min);
  margin: 0;
}

#customers__search-bar--table svg {
  font-size: 1.2rem;
}

#customers__search-bar--table input {
  border: none;
  border-radius: 7.5px;
  width: 100%;
  height: 100%;
  padding: 7.5px;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
  font-weight: 400;
  font-style: italic;
}

#customers__search-bar--table input::placeholder {
  font-style: italic;
  color: var(--secondairy-medium-gray);
}

#customers__search-bar--table input:focus {
  outline: none;
}

.template__body #scroll-down--btn {
  bottom: 100px;
  background-color: var(--secondairy-light-medium-gray);
  opacity: 0.75;
}

.template__body #scroll-up--btn {
  bottom: 150px;
  transform: rotate(180deg);
  background-color: var(--secondairy-light-medium-gray);
  opacity: 0.75;
}

.body__customers .input_field__dropdown {
  top: 50px;
}

.body__customers .dropdown_field {
  background-color: var(--secondairy-ultra-light-gray);
  border: 1px solid var(--secondairy-light-gray);
  padding: 0;
  width: 100%;
  height: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
}

.body__customers .dropdown_field .limit {
  padding: 7.5px 5px;
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.body__customers .dropdown_field .limit > svg {
  font-size: 1rem;
}

.body__customers .dropdown_field .circular_loading_spinner__wrapper {
  padding: 10px 0;
}

.body__customers .dropdown_field__item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 10px 10px;
}

.body__customers .dropdown_field__item:hover {
  background-color: var(--secondairy-light-gray);
}

.body__customers .dropdown_field__item > div {
  color: var(--secondairy-medium-gray);
  cursor: pointer;
}

.body__customers #delivery_address_info {
  position: relative;
}

.body__customers .other_options {
  padding: 10px 0;
}

.body__customers .api_options {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  width: 100%;
}

.body__customers .business-central-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border: 1px solid var(--secondairy-light-medium-gray);
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--secondairy-light-medium-gray);
}

.body__customers .business-central-icon.clicked {
  background-color: var(--light-primary-green);
  border-color: var(--primary-green);
  color: white;
}

.body__customers .business-central-icon svg {
  width: 20px;
  height: 20px;
}

#export-popup .export-popup__content--buttons {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  padding: 10px;
}


