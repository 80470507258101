.popup_window__container.menu {
  position: absolute;
  top: 105vh;
  left: -1px;
  width: calc(100% + 1px);
  height: calc(100vh + 1px);
  background-color: var(--primary-dark-theme);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  z-index: 49;
  transition: all 600ms ease-in-out;
  /* border-top-right-radius: 10px;
  border-top-left-radius: 10px; */
}

.popup_window__container.menu .cut-out {
  position: absolute;
  right: 7.5px;
  top: 7.5px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: var(--secondairy-light-gray);
}

.popup_window__container.menu.active {
  transform: translateY(-105vh);
}

.popup_window__container.menu .popup_header {
  position: relative;
  height: 70px;
  padding: 10px;
}

.popup_window__container.menu .popup_body {
  position: relative;
  height: calc(100% - 150px);
  padding: 10px;
  overflow: scroll;
}

.popup_window__container.menu .popup_body::-webkit-scrollbar {
  display: none;
}

.popup_window__container.menu .side_menu__titles {
  padding-left: 20px;
}

.popup_window__container.menu .menu_title {
  display: flex;
  align-items: center;
  gap: 25px;
  color: var(--secondairy-light-gray);
  font-size: 1.8rem;
  padding: 20px 0;
}

.popup_window__container.menu .menu_title.selected {
  color: var(--primary-green);
}