#popup-window-customer-info {
  position: absolute;
  top: 50vh;
  right: -105vw;
  width: 100vw;
  height: 100vh;
  background-color: var(--secondairy-light-gray);
  z-index: 50;
  transition: all 600ms ease-in-out;
}

#popup-window-customer-info.active {
  transform: translate(-105vw, -50vh);
}

#popup-window-customer-info .popup_header {
  position: relative;
  height: 70px;
  padding: 10px;
}

#popup-window-customer-info .go_back {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  gap: 5px;
  text-transform: uppercase;
  height: 50px;
  width: 50px;
  color: var(--secondairy-medium-gray);
  font-size: 1.2rem;
}

#popup-window-customer-info .popup_body .popup_body__content_wrapper {
  padding: 10px;
}

.popup_body__content_wrapper .availability_section__content_container > .loading_circle_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondairy-light-gray-plus);
}

.popup_body__content_wrapper .availability_section__content_container .load_more_button {
  height: 60px;
  border: none;
  /* background-color: var(--secondairy-light-gray-plus); */
}

.popup_body__content_wrapper .availability_section__content_container .product {
  display: grid;
  grid-template-columns: 4fr 1fr;
  height: 60px;
  padding: 7.5px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.popup_body__content_wrapper .availability_section__content_container .product > p {
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary-black);
  align-self: center;
}

.popup_body__content_wrapper .availability_section__content_container .product:nth-child(odd) {
  background-color: var(--secondairy-light-gray-plus);
}

#popup-window-customer-info .popup_window__container .supplier_list__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

#popup-window-customer-info #activities-of-customer {
  grid-column: span 2;
  grid-row: span 7;
  padding: 0;
}

#popup-window-customer-info #activities-of-customer .circular_loading_spinner__wrapper {
  display: flex;
  align-items: baseline;
  padding-top: 30px;
}

#popup-window-customer-info #activities-of-customer .table {
  overflow: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

#popup-window-customer-info #activities-of-customer .table .table__row_wrapper .table__row--field--arrow  {
  right: 10px;
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile {
  display: grid;
  grid-template: 2fr 1fr  / 50px 50px 1fr 1.5fr;
  min-height: 60px;
  margin-bottom: 10px;
  font-size: 0.9rem;
  border-left: 5px solid var(--secondairy-medium-gray);
  background-color: var(--secondairy-light-gray-plus);
  padding: 7.5px 5px;
  border-radius: 5px;
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile.overdue {
  background-color: var(--mobile-light-red);
  border-left: var(--mobile-red) solid 5px;
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile.done {
  background-color: var(--light-primary-green);
  border-left: var(--primary-green) solid 5px;
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile .activity_data_field.title > svg {
  height: 20px;
  width: 20px;
  fill: var(--secondairy-medium-gray);
  margin-right: 7.5px;
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile.overdue .activity_data_field.title > svg {
  height: 20px;
  width: 20px;
  fill: var(--mobile-red);
  margin-right: 7.5px;
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile.done .activity_data_field.title svg {
  height: 20px;
  width: 20px;
  fill: var(--primary-green);
  margin-right: 7.5px;
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile .activity_data_field.check-box {
  grid-row: span 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile .activity_data_field.picto {
  grid-row: span 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile .activity_data_field.title {
  grid-column: span 3;
  display: flex;
  align-items: top;
  justify-content: left;
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile .activity_data_field.owner {
  grid-column: span 2;
  display: flex;
  align-items: center;
  justify-content: left;
  color: var(--secondairy-medium-gray);
  font-size: 0.75rem;
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile .activity_data_field.date {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
}

#popup-window-customer-info #activities-of-customer .activity_container--mobile .checkbox__container.mobile {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

#popup-window-customer-info .suppliers_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#popup-window-customer-info .suppliers_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#popup-window-customer-info .suppliers_list .supplier_item {
  display: grid;
  grid-template: 60px 60px / 1fr 0.25fr;
  gap: 5px;
}

#popup-window-customer-info .add-supplier {
  display: flex;
  flex-direction: column;
  gap: 7.5px;
}

#popup-window-customer-info .add-supplier .input_text--mobile {
  margin: 0;
}

#popup-window-customer-info .suppliers_list .supplier_item .supplier_item__name,
#popup-window-customer-info .suppliers_list .supplier_item .supplier_item__alias
{
  display: flex;
  align-items: center;
  height: 60px;
  padding: 7.5px;
  border-radius: 5px;
  background-color: var(--secondairy-light-gray-plus);
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary-black);
  width: 100%;
}

#popup-window-customer-info .suppliers_list .supplier_item .supplier_item__name {
  grid-area: 1 / 1 / 1 / 3;
}

#popup-window-customer-info .suppliers_list .supplier_item .supplier_item__alias {
  grid-area: 2 / 1 / 2 / 2;
}

#popup-window-customer-info .suppliers_list .supplier_item .delete-btn {
  grid-area: 2 / 2 / 2 / 3;
  background-color: var(--ultra-light-red);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  color: var(--dark-red);
}

