#bestsellers-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

#bestsellers-wrapper .bestsellers_doughnut {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#bestsellers-wrapper .doughnut_container {
    position: relative;
    padding: 0px;
    height: 100%;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    align-self: center;
    justify-self: center;
}

#bestsellers-wrapper .doughnut_container.is_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 1px);
    width: 90%;
}

.podium__container {
    display: grid;
    grid-template: 25px / 1fr 1fr;
    column-gap: 30px;
    grid-auto-flow: row;
    width: 100%;
    height: 30%;
    overflow: auto;
}

.podium__container > p {
    grid-column: span 2;
}

.podium__element {
    display: flex;
    gap: 5px;
    font-size: 0.75rem;
    width: 100%;
}

.podium__element--best-clients {
    display: flex;
    gap: 5px;
    font-size: 0.85rem;
    padding: 5px 0;
    width: 100%;
}

.podium__element--counter {
    font-weight: 700;
    width: 40px;
}

.podium__element--amount {
    margin-left: auto;
    font-weight: 700;
}