.checkbox__container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.checkbox__container.vert {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
}

.checkbox__container.no-text {
  width: fit-content;
}

.checkbox--box {
  height: 100%;
  width: 100%;
  border: var(--secondairy-light-gray) solid 1.5px;
  margin-right: 10px;
  transition: all 200ms ease-in-out;
  border-radius: 5px;
}

.checkbox--box.hybrid {
  border: var(--secondairy-light-medium-gray) solid 1px;
}

.checkbox--box.checked {
  background-color: var(--secondairy-light-gray);
  width: 100%;
  height: 100%;
}

.checkbox--box.checked.dark {
  background-color: var(--secondairy-medium-gray);
}

.checkbox--box.checked.hybrid {
  background-color: var(--secondairy-light-medium-gray);
}

.checkbox--text {
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
}

.checkbox--text.dark {
  color: white;
}

.checkbox--box__container {
  position: relative;
  height: 100%;
  width: 100%;
}

.checkmark {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: rotate(45deg);
  transform-origin: center center;
  margin-left: -30%;
  margin-top: -30%;
  width: 60%;
  height: 60%;
  display: none;
}

.checkmark.dark .checkmark_stem, .checkmark.dark .checkmark_kick {
  background-color: var(--primary-green);
}

.checkmark.checked {
  display: block;
}

.checkmark_stem {
  position: absolute;
  width: 15%;
  height: 70%;
  background-color: white;
  left: 52%;
  top: 9%;
}

.checkmark_kick {
  position: absolute;
  width: 25%;
  height: 15%;
  background-color: white;
  left: 30%;
  top: 65%;
}

.checkbox__container.mobile {
  margin-left: auto;
  justify-self: right;
}

.checkbox__container.mobile .checkbox--box__container {
  margin-left: auto;
}

.checkbox__container.mobile .checkbox--box {
  border: 1px solid var(--secondairy-medium-gray);
  margin-right: 0;
}

.checkbox__container.mobile .checkbox--box.checked {
  background-color: var(--secondairy-medium-gray);
}

.checkbox__container.mobile.sales {
  margin-left: auto;
  justify-self: right;
  height: auto;
  background-color: transparent;
  padding: 0;
}

.checkbox__container.mobile.sales .checkbox--text {
  color: var(--secondairy-medium-gray);
  font-size: 0.85rem;
}



