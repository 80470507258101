.advanced-filters__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0;
}

.advanced-filters__body .sliders__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50%;
}

.advanced-filters__body .report-items__container {
    padding: 15px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

