.template__menu--mobile {
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 30px 20px;
}

.template__menu--mobile .menu__item {
    font-size: 2rem;
    color: var(--secondairy-medium-gray);
}

.template__menu--mobile .menu__item svg {
    font-size: 2rem;
    color: var(--secondairy-medium-gray);
}

.template__menu--mobile .menu__item.selected svg {
    color: var(--primary-green);
}