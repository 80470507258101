.body__sales-pipe {
  position: relative;
  display: grid;
  grid-template: 95% 5% / 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 5px;
  height: 100%;
  width: 100%;
}

.body__sales-pipe .body__section {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  display: grid;
  grid-template: 47.5px calc(100% - 47.5px) / 1fr;
  overflow: hidden;
  width: 100%;
  flex: 1;
  transition: flex 250ms ease;
}

.body__sales-pipe .body__section.hidden {
  display: none;
}

.body__sales-pipe .body__section.big {
  grid-column: span 5;
  grid-row: span 2;
}

#sales-pipe-graveyard.big {
  grid-column: span 5;
  grid-row: span 2;
  width: 100%;
}

.body__sales-pipe .body__section .shark-list__title {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--secondairy-light-gray);
  position: relative;
  border-radius: 5px;
  box-shadow: var(--border-shadow-min);
  padding: 6px 20px;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-bottom: 7.5px;
  cursor: pointer;
}

.body__sales-pipe .body__section .shark-list__title img {
 margin-left: auto;
}

#sales-pipe-graveyard {
  width: 50%;
  margin-bottom: 0;
}

.body__sales-pipe .body__section .shark-list__title.graveyard.dragover {
  border: var(--dark-red) 2px solid;
}

.body__sales-pipe .body__section .shark-list__title.graveyard {
  width: 100%;
  height: 75%;
  display: flex;
  font-size: 0.8rem;
  padding: 5px 20px;
  gap: 5px;
  justify-content: space-between;
  box-shadow: none;
  margin-bottom: 0;
  border: 1px var(--secondairy-light-medium-gray) solid;
  background-color: var(--light-red);
}

.body__sales-pipe .body__section .shark-list__title.graveyard img {
  margin-left: 0;
  height: 95%;
 }

.body__sales-pipe .body__section .shark-list__section {
  overflow-y: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  width: 100%;
  transition: flex 500ms ease-in-out;
}

.body__sales-pipe .body__section .shark-list__section.dragover {
  border-style: solid;
  border-width: 2px;
  animation: border-wave 3s linear infinite;
  border-radius: 15px;
  opacity: 50%;
}

.body__sales-pipe .body__section .shark-list__section::-webkit-scrollbar {
  display: none;
}

.body__sales-pipe .body__section .shark-list__section .loading_spinner_circle {
  color: var(--secondairy-light-gray);
  width: 100%;
  height: 30px;
  text-align: center;
  margin-top: 15px;
}

.body__sales-pipe .customer-fiche_tag {
  font-size: 0.50rem;
  font-weight: 900;
  width: 70px;
  padding: 3px 12.5px;
  border-radius: 7.5px;
  text-transform: uppercase;
}

.body__sales-pipe .customer-fiche_tag.red {
  background-color: var(--light-red);
  color: var(--dark-red);
}
.body__sales-pipe .customer-fiche_tag.green {
  background-color: var(--light-green);
  color: var(--dark-green);
}
.body__sales-pipe .customer-fiche_tag.blue {
  background-color: var(--light-blue);
  color: var(--dark-blue);
}
.body__sales-pipe .customer-fiche_tag.gold {
  background-color: var(--special-gold-light);
  color: var(--special-gold-dark);
}

.body__sales-pipe .table {
  background-color: white;
  padding: 15px;
  border-radius: 7.5px;
  min-height: 100%;
}

.body__sales-pipe .table .table__header-9 {
  grid-template: 30px / 45px 0.8fr 0.9fr 0.8fr 0.8fr 1.4fr 0.8fr 0.5fr 0.5fr;
}

.body__sales-pipe .table .table__header-9 .table__header--field {
  cursor: pointer;
}

.body__sales-pipe .table .table__row-9 {
  grid-template: 30px / 45px 0.8fr 0.9fr 0.8fr 0.8fr 1.4fr 0.8fr 0.5fr 0.5fr;
}

.body__sales-pipe .header_item--number {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondairy-light-medium-gray);
  color: var(--secondairy-medium-gray);
  font-weight: 700;
  width: 25px;
  height: 25px;
  font-size: 0.65rem;
  border-radius: 50%;
  overflow: hidden;
}

@keyframes border-wave {
  0% {
    border-color: var(--primary-green);
  }
  50% {
    border-color: transparent; /* This creates the "gap" in the wave */
  }
  100% {
    border-color: var(--primary-green);
  }
}