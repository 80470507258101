.body__salesforce--brandowner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.body__salesforce--brandowner #section1 {
  height: 50%;
  width: 50%;
}