.body__salesforce--brandowner {
  position: relative;
  display: grid;
  grid-template: repeat(6, 1fr) / 1.2fr repeat(6, 1fr);
  gap: 10px;
  width: 100%;
  height: 100%;
  grid-template-areas: 
  "visits unique unique-percent ranking ranking ranking ranking"
  "clients lost net ranking ranking ranking ranking"
  "listings lost-listings net-listings ranking ranking ranking ranking"
  "leader leader leader ranking ranking ranking ranking"
  "leader leader leader ranking ranking ranking ranking"
  "leader leader leader ranking ranking ranking ranking"
  ;
}

.body__salesforce--brandowner .body__section {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.body__salesforce--brandowner .body__section .performance_content_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body__salesforce--brandowner .body__section .performance_content--number {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.9rem;
  font-weight: 700;
  color: var(--secondairy-medium-gray);
}

.body__salesforce--brandowner .body__section .performance_content--number::before {
  content: "";
  position: absolute;
  background-color: var(--secondairy-light-gray);
  height: 100%;
  left: -15px;
  width: calc(100% + 30px);
  opacity: 0.2;
}

.body__salesforce--brandowner .body__section h2 {
  font-weight: 700;
  color: var(--primary-green);
  font-variant: small-caps;
  margin-bottom: 7.5px;
  text-align: center;
  min-height: auto;
  font-size: 1.2rem;
}

.body__salesforce--brandowner .body__section {
  position: relative;
}

.body__salesforce--brandowner .body__section .title_tag {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--light-primary-green);
  height: 100%;
  width: 17.5%;
  writing-mode: vertical-lr;
  text-orientation: upright;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  color: var(--secondairy-medium-gray);
  font-size: 0.6rem;
  letter-spacing: 0.01rem;
}

.body__salesforce--brandowner #total-visits {
  grid-area: visits;
  padding-left: 20%;
}

.body__salesforce--brandowner #total-new {
  grid-area: clients;
  padding-left: 20%;
}

.body__salesforce--brandowner #total-visits-unique {
  grid-area: unique;
}

.body__salesforce--brandowner #total-visits-unique-percent{
  grid-area: unique-percent;
}

.body__salesforce--brandowner #total-lost {
  grid-area: lost;
}

.body__salesforce--brandowner #net-new {
  grid-area: net;
}

.body__salesforce--brandowner #total-lost-listings {
  grid-area: lost-listings;
}

.body__salesforce--brandowner #total-new-listings {
  grid-area: listings;
  padding-left: 20%;
}

.body__salesforce--brandowner #net-new-listings {
  grid-area: net-listings;
}

.body__salesforce--brandowner #all-time-leaderbord {
  grid-area: leader;
}

.body__salesforce--brandowner #leaderboard {
  grid-area: ranking;
}

.body__salesforce--brandowner .performance_content_wrapper {
  display: block !important;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.body__salesforce--brandowner .performance_content--leaderboard {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.body__salesforce--brandowner .sales_rep__fiche {
  position: relative;
  width: 100%;
  height: 90px;
  display: grid;
  grid-template: 1fr / 50px repeat(7, 1fr);
  gap: 15px;
  align-items: center;
  margin: 2.5px 0;
  padding: 15px 7.5px;
  background-color: var(--secondairy-ultra-light-gray);
  border-radius: 7.5px;
  cursor: pointer;
}

.body__salesforce--brandowner .sales_rep__fiche .medal {
  position: absolute;
  height: 30px;
  bottom: 5px;
  left: 18px;
}

.body__salesforce--brandowner .sales_rep__fiche img {
  height: 50px;
  border-radius: 50%;
}

.body__salesforce--brandowner .sales_rep__fiche .data {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.body__salesforce--brandowner .sales_rep__fiche .data h3 {
  font-size: 0.85rem;
  color: var(--secondairy-medium-gray);
  font-weight: 400;
}

.body__salesforce--brandowner .sales_rep__fiche .data p {
  font-size: 0.9rem;
  color: var(--secondairy-medium-gray);
  font-weight: 700;
}

.body__salesforce--brandowner .performance_content--leaderboard.alltime .sales_rep__fiche {
  position: relative;
  width: 97.5%;
  height: 45px;
  display: grid;
  grid-template: 1fr / 25px repeat(6, 1fr);
  gap: 10px;
  padding: 5px 7.5px;
  background-color: transparent;
  border-bottom: var(--secondairy-light-gray) 1px solid;
}

.body__salesforce--brandowner .performance_content--leaderboard.alltime .sales_rep__fiche .name {
  font-size: 0.75rem;
}

.body__salesforce--brandowner .performance_content--leaderboard.alltime .sales_rep__fiche .data h3,
.body__salesforce--brandowner .performance_content--leaderboard.alltime .sales_rep__fiche .data p {
  font-size: 0.75rem;
}

.body__salesforce--brandowner .performance_content--leaderboard.alltime .sales_rep__fiche img {
  height: 25px;
}

.body__salesforce--brandowner .popup__body {
  height: 95%;
}

.body__salesforce--brandowner .popup__body .performance_detail {
  height: 100%;
}

.body__salesforce--brandowner .popup__header {
  display: flex;
  height: 7.5%;
}

.body__salesforce--brandowner .popup__header .body__section__header_item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  cursor: pointer;
}
