.check-box-static__container {
  position: relative;
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.check-box-static--box {
  height: 20px;
  width: 20px;
  position: relative;
  border: var(--secondairy-light-gray) solid 1px;
  margin-right: 10px;
  transition: all 200ms ease-in-out;
  border-radius: 5px;
}

.check-box-static--box.checked {
  position: relative;
  background-color: var(--secondairy-light-gray);
}

.check-box-static--text {
  font-size: 0.75rem;
  margin-right: 10px;
  color: var(--secondairy-medium-gray);
  width: 100px;
}

.check-box-static--text.disabled {
  display: none;
}

.check-box-static--box__container {
  position: relative;
  width: 100%;
  height: 100%;
}

.check-box-static__container .checkmark {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: rotate(45deg);
  transform-origin: center center;
  margin-left: -30%;
  margin-top: -30%;
  width: 60%;
  height: 60%;
  display: none;
}

.check-box-static__container .checkmark.checked {
  display: block;
}

.check-box-static__container .checkmark_stem {
  position: absolute;
  width: 15%;
  height: 70%;
  background-color: white;
  left: 52%;
  top: 9%;
}

.check-box-static__container .checkmark_kick {
  position: absolute;
  width: 25%;
  height: 15%;
  background-color: white;
  left: 30%;
  top: 65%;
}

.check-box-static__container.mobile {
  margin-left: auto;
  justify-self: right;
}

.check-box-static__container.mobile .check-box-static--box__container {
  margin-left: auto;
  margin-right: 10px;
}

.check-box-static__container.mobile .check-box-static--box {
  border: 1px solid var(--secondairy-medium-gray);
  margin-right: 0;
}

.check-box-static__container.mobile .check-box-static--box.checked {
  background-color: var(--secondairy-medium-gray);
}



