.body__sales {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
}

.body__section  {
  /* reguires index.css */
  transition: height 500ms ease-in-out;
}

#new-sales-form {
  width: 100%;
  height: 55%;
}

#new-sales-form .dropdown__container.customer {
  width: 30%;
}

#new-sales-form::-webkit-scrollbar {
  display: none;
}

#new-sales-form .form__section {
  border: none;
  padding: 0;
}

#new-sales-form .form__section .grid {
  height: 100%;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 20px;
}

#new-sales-form .dropdown__container{
  width: 100%;
  margin-left: 0;
}

#new-sales-form h1 {
  margin: 0;
  margin-bottom: 5px;
}

#new-sales-form .dropdown__container .input_field__input_box--text{
  margin-bottom: 15px;
  font-size: 0.75rem;
  height: 35px;
}

#new-sales-form .product__dropdown__container ul {
  top: 35px;
}

#new-sales-form .product__dropdown__container .input_field__input_box--text.sales {
  margin: 0;
  font-size: 0.75rem;
  height: 35px;
  width: 100%;
}

#new-sales-form .product__dropdown__container {
  width: 100%;
  margin-left: 0;
}

#new-sales-form .dropdown__container ul{
  top: 40px;
}

#new-sales-form .dropdown__container li{
  font-size: 0.75rem;
}

#new-sales-form .input_field__input_box--text.filter {
  height: 35px;
}

#new-sales-form .input_field__input_box--text.product {
  height: 35px;
  width: 100%;
  font-size: 0.7rem;
}

#new-sales-form .more-products--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(6fr + 1fr + 120px + 8fr + 0.5fr);
  height: 35px;
  background-color: var(--secondairy-ultra-light-gray);
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 5px;
  margin-top: 15px;
  font-size: 1.5rem;
  color: var(--secondairy-medium-gray);
  font-weight: 400;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

#new-sales-form .more-products--btn:hover {
  background-color: var(--secondairy-light-gray);
}

#sales_brandowner__comment-field--popup {
  height: 80%;
  margin-bottom: 5px;
}

.body__sales .checkboxes {
  display: flex;
  margin: 0px;
  align-self: flex-start;
}

.body__sales .checkbox__container {
  margin: 0;
}

.body__sales .checkbox__container .checkbox--text {
  font-size: 0.7rem;
}

#sales-history-table {
  width: 100%;
  height: 45%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.body__sales .unfold-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  transform: rotate(45deg);
  color: var(--secondairy-medium-gray);
  z-index: 10;
}

.checkboxes {
  margin-top: 80px;
}

.body__sales .table__header-8 {
  grid-template: 35px /70px 100px 150px 1.25fr 1.25fr 1fr 1fr 70px;
}

.body__sales .table__row-8 {
  grid-template: 35px / 70px 100px 150px 1.25fr 1.25fr 1fr 1fr 70px;
  align-items: center;
  cursor: pointer;
}

.body__sales .table__row-8 .table__row--field {
  grid-template: auto / 0.5fr 1fr 1fr 1fr;
  align-items: center;
  font-weight: 300;
}

.body__sales .table__row-4 svg{
  color: var(--secondairy-medium-gray);
  justify-self: flex-end;
}

.body__sales .table__row-4.odd {
  background-color: var(--secondairy-ultra-light-gray);
  border-radius: 7.5px;
}

.table__row--field--options {
  justify-self: flex-end;
  display: flex;
  align-items: center;
  gap: 7.5px;
}

#editIconSalesPage {
  font-size: 1.1rem;
  color: var(--secondairy-medium-gray);
}

#editIconSalesPage.disabled {
  color: var(--secondairy-light-gray);
  pointer-events: all;
}

.body__sales .table__secondairy-header-3 {
  width: 100%;
  grid-template: auto / 1fr 1.2fr 1fr;
  margin-bottom: 0;
}

.body__sales--table-rows__container {
  border-radius: 7.5px;
  cursor: pointer;
}

.body__sales .table .product-details__container:nth-child(even) {
  background-color: var(--secondairy-ultra-light-gray);
}


.body__sales .table__secondairy-header--field {
  border: var(--secondairy-light-gray) solid 1px;
  padding: 10px;
  font-size: 0.7rem;
}

.product-details__container {
  color: var(--primary-black);
  margin: 15px 0;
}

.body__sales .popup-elements {
  display: grid;
  grid-template: 1fr 0.4fr 1fr / 1.75fr 1fr;
  grid-template-areas: 
  "client history"
  "tracking history"
  "order history"
  ;
  column-gap: 30px;
  height: 100%;
  width: 100%;
}

.body__sales .popup-elements .popup_window__sub-title {
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  width: 90%;
  left: 10px;
  margin: 10px 0;
  margin-left: -10px;
}

.body__sales .popup-elements h2 {
  font-size: 0.70rem;
  color: var(--primary-black);
  font-weight: 300;
  cursor: default;
  width: auto;
  margin-right: auto;
}

.body__sales .popup-elements .tracking {
  grid-area: tracking;
}

.body__sales .popup-elements .client{
  grid-area: client;

  display: grid;
  grid-template-areas:
  "title title"
  "column1 column2"
  ;
  gap: 10px;
}

.body__sales .popup-elements .client .popup_window__sub-title {
  grid-area: title;
}

.body__sales .popup-elements .client-info-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.body__sales .popup-elements .client-tracking-info {
  display: grid;
  grid-template: auto / 50% 30% auto;
  column-gap: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.body__sales .popup-elements .client-tracking-info .secondairy-button {
  font-size: 0.6rem;
}

.body__sales .popup-elements .client-tracking-info input:first-child {
  margin-right: 10px;
}

.body__sales .popup-elements .client-tracking-info input:nth-child(2) {
  margin-right: 10px;
}

.body__sales .popup-elements .client-info-item p {
  font-size: 0.6rem;
  color: var(--secondairy-medium-gray);
  background-color: var(--secondairy-ultra-light-gray);
  border: 1px solid var(--secondairy-light-gray);
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  min-height: 24px;
  margin-top: 5px;
  cursor: copy;
}

.body__sales .popup-elements .double-field-30-70 {
  display: flex;
  width: 100%;
}

.body__sales .popup-elements .double-field-50-50 {
  display: flex;
  gap: 10px;
  width: 100%;
}

.body__sales .popup-elements .double-field-50-50 input {
  height: 24px;
}

.body__sales .popup-elements .double-field-30-70 p:first-child {
  width: 30%;
  margin-right: 3px;
}

.body__sales .popup-elements .double-field-30-70 p:nth-child(2) {
  width: 70%;
}

.body__sales .popup-elements .order{
  grid-area: order;
  padding-bottom: 40px;
}

.body__sales .sales__orders--product-rows {
  display: grid;
  width: 70%;
  margin-right: auto;

  display: grid;
  grid-template: auto / 0.5fr 0.9fr 1.30fr 0.7fr 1fr 0.5fr;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
}

.body__sales .table__secondairy-header--field {
  border: none;
  padding: 2.5px 2.5px 2.5px 0;
}

.body__sales .sales__orders--product-rows:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
  border: 1px solid var(--secondairy-light-gray);
}

.body__sales .table__secondairy-row--field {
  font-weight: 300;
  color: var(--secondairy-medium-gray);

  font-size: 0.6rem;
  padding: 0;
  margin-right: 3px;
  cursor: copy;

  padding: 2.5px 2.5px 2.5px 0;
}

.body__sales .table__secondairy-header-6 {
  display: grid;
  grid-template: auto / 0.5fr 0.9fr 1.30fr 0.7fr 1fr 0.5fr;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  font-weight: 300;
  font-size: 0.7rem;
  padding: 5px;
}


.body__sales .popup-elements .salespage--titles {
  margin-top: 0px;
  display: grid;
  grid-template: auto / 30% 1.2fr 120px 8fr 0.75fr;
  gap: 5px;
  font-size: 0.6rem;
  color: var(--secondairy-medium-gray);
  margin-bottom: 10px;
  text-align: center;
}

.salespage--titles--update {
  margin-top: 0px;
  display: grid;
  grid-template: auto / 20% 35px 100px 25% 1fr 0.15fr;
  gap: 10px;
  margin-top: 15px;
  font-size: 0.55rem;
  color: var(--secondairy-medium-gray);
  text-align: center;
  justify-content: center;
  align-items: center;
}
.salespage--titles--update .salespage--title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px;
  height: 37.5px;
  border-bottom: 1px solid var(--secondairy-light-gray);
}

.salespage--titles--update .checkboxes {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px;
  height: 37.5px;
  border-bottom: 1px solid var(--secondairy-light-gray);
}

.salespage--titles--update .checkboxes div {
  padding: 0 5px;
  font-size: 0.55rem;
}

.body__sales .salespage--titles {
  margin-top: 0px;
  display: grid;
  grid-template: auto / 25% 60px 170px 200px 1fr 50px;
  gap: 10px;
  margin-top: 15px;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
  text-align: center;
  justify-content: center;
  align-items: center;
}
.body__sales .salespage--titles .salespage--title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px;
  height: 37.5px;
  border-bottom: 1px solid var(--secondairy-light-gray);
}

.body__sales .salespage--titles .checkboxes {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px;
  height: 37.5px;
  border-bottom: 1px solid var(--secondairy-light-gray);
}

.body__sales .salespage--titles .checkboxes div {
  padding: 0 5px;
  font-size: 0.75rem;
}

.body__sales .vendor__dropdown__container .input_field__input_box--text.sales {
  margin: 0;
  height: 35px;
}

.body__sales .progress__tracker--first {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--light-primary-green);
  border: white solid 1px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.body__sales .progress__tracker--last {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  left: -10px;
  background-color: var(--light-primary-green);
  border-radius: 15px;
  border: white solid 1px;
}

.body__sales .progress__tracker--first.disabled,
.body__sales .progress__tracker.disabled,
.body__sales .progress__tracker--last.disabled {
  background-color: var(--secondairy-light-gray);
}

.body__sales .progress__tracker {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% + 10px);
  height: 100%;
  left: -10px;
  background-color: var(--light-primary-green);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border: white solid 1px;
}

.body__sales .progress__tracker_circle {
  width: 25px;
  height: 90%;
  margin-left: 2px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body__sales .progress__tracker_circle svg {
  fill: var(--light-primary-green);
  font-size: 1.2rem;
}

.body__sales .progress__tracker_circle .disabled_icon {
  color: var(--secondairy-light-medium-gray);
  pointer-events: none;
}

.body__sales .done_icon {
  opacity: 0;
}

.body__sales .table__row--field--uncenter {
  align-self: auto;
  height: 35px;
}

.body__sales .unfinished {
  background-color: var(--secondairy-light-gray) !important;
}

.body__sales .table__row-9.removing {
  animation: removeAnimation 0.5s ease forwards;
}

.body__sales .table__row-9.changed-row {
  animation: shining 3.5s ease-in-out;
}

.notification-popup__elements__text .notification-popup__elements__text_item {
  width: 100%;
  border: var(--secondairy-medium-gray) solid 1px;
  border-radius: 5px;
  padding: 7.5px 5px;
  text-align: left;
  margin-bottom: 5px;
  cursor: pointer;
}

.notification-popup__elements__text .notification-popup__elements__text_item:hover {
  background-color: var(--secondairy-medium-gray);
}



@keyframes shining {
  0% {
    background-color: var(--secondairy-light-gray);
  }

  50% {
    background-color: white;
  }

  100% {
    background-position: var(--secondairy-ultra-light-gray);
  }
}

@keyframes removeAnimation {
  0% { opacity: 1; transform: translateX(0); }
  20% { transform: translateX(20%); }
  80% { transform: translateX(80%); }
  100% { opacity: 0; transform: translateX(100%); }
}