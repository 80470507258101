.add-product__container--update {
  display: grid;
  grid-template: auto / 20% 35px 100px 25% 1fr 0.15fr;
  margin-top: 10px;
  gap: 10px;
  box-sizing: border-box;
}

.add-product__container--update #delete-icon {
  font-size: 2rem;
  color: var(--light-red);
  cursor: pointer;
  width: 100%;
}

.add-product__container--update .delete {
  display: flex;
  justify-content: center;
}

.add-product__container--update .product__dropdown__container {
  margin-left: 0;
  width: 100%;
}

.add-product__container--update .product__dropdown__container .input_field__input_box--text {
  margin-bottom: 0;
  width: 100%;
  margin-top: 0;
  height: 35px;
  font-size: 0.6rem;
}

.add-product__container--update .input_field__input_box--text.product {
  height: 35px;
  width: 100%;
  font-size: 0.6rem;
  text-align: center;
  margin-top: 0;
}

.add-product__container--update .checkboxes {
  display: flex;
  justify-content: center;
}

.add-product__container--update .checkboxes .checkboxes_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.add-product__container--update textarea {
  height: 35px !important;
  margin-top: 0;
  min-height: 35px;
  resize: vertical;
  font-size: 0.6rem;
}

.add-product__container--update .checkbox__container {
  height: 35px;
  max-width: 45px;
  width: 100%;
  padding: 0 5px;
}

.add-product__container--update .checkbox--box {
  margin-right: 0;
}

.add-product__container--update .checkbox--text {
  display: none;
}

.add-product__container--update .vendor__dropdown__container .input_field__input_box--text.sales {
  margin-top: 0;
  height: 35px;
}

.add-product__container--update .vendor__dropdown__container .supplier__item {
  font-size: 0.6rem;
}

.add-product__container--update .vendor__dropdown__container .input_field__input_box--text.sales {
  font-size: 0.6rem;
}

.add-product__container--update .vendor__dropdown__container .supplier__item .label__vendors h4 {
  font-size: 0.5rem;
}

.add-product__container--update .vendor__dropdown__container .input_field__dropdown li .supplier__item__company_name {
  min-width: 40px;
}




