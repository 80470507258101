.Hamburger_menu {
    display: inline-block;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .Hamburger_menu .bar1,
  .Hamburger_menu .bar2,
  .Hamburger_menu .bar3 {
    width: 30px;
    height: 1px;
    background-color: var(--secondairy-light-gray);
    margin: 6px 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  .Hamburger_menu.open .bar1 {
    transform: translateY(7px) rotate(45deg);
  }
  
  .Hamburger_menu.open .bar2 {
    opacity: 0;
  }
  
  .Hamburger_menu.open .bar3 {
    transform: translateY(-7px) rotate(-45deg);
  }
  