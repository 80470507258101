.popout__container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 501;
    width: 105%;
    left: -20px;
    height: calc(100% + 10vh + 40px);
    top: -12.5vh;
    background-color: transparent;
    backdrop-filter: blur(10px);
    padding: 15px;
    /* padding-top: calc(20vh); */

    opacity: 0;
    pointer-events: none;

    transition: all 200ms ease-in-out;
}

.popout__container.active {
    pointer-events: all;
    opacity: 1;
}

.popout__container .popup__options-button {
    position: relative;
    left: 0;
    top: 0;
}

.popout__box {
    position: relative;
    width: 50%;
    max-height: 75%;
    border: var(--secondairy-light-medium-gray) solid 1px;
    box-shadow: var(--border-shadow-min);
    border-radius: 10px;
    overflow-y: scroll;
    top: 0;
    left: 0;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    cursor: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.popout__container #popup__close-icon {
    position: absolute;
    color: var(--primary-black);
    font-size: 0.7rem;
    right: 8px;
    top: 8px;
    cursor: pointer;
    background-color: var(--secondairy-ultra-light-gray);
    border: 1px solid var(--secondairy-light-gray);
    border-radius: 20px;
    width: 25px;
    height: 18px;
    padding: 3px;
}

.popout__container .popout__box .popout__header {
    position: relative;
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    min-height: 40px;
}

.popout__container .popout__box .popout__body {
    position: relative;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(80% -50px -20px - 20px );
}

.popout__container .form__section_title {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    color: var(--secondairy-medium-gray);
    margin: 15px 0 5px 0;
    font-variant: all-small-caps;
}

.popout__container .form__section_title > p {
    font-size: 0.85rem;
    margin: 0 10px;
    text-transform: none;
}

.popout__container h3 {
    font-weight: 700;
    color: var(--primary-black);
    font-variant: all-small-caps;
    margin: 10px 0;
    font-size: 1rem;
}

.popout__container h2 {
    font-weight: 500;
    color: var(--secondairy-medium-gray);
    text-transform: all-small-caps;
    margin: 0;
    font-size: 1.1rem;
    width: 100%;
    text-align: left;
}

.popout__container h1 {
    font-weight: 500;
    color: var(--secondairy-medium-gray);
    text-transform: uppercase;
    margin: 10px 0;
    font-size: 1.1rem;
    width: 100%;
    text-align: center;
}

.popout__container .form__section {
    padding: 0;
    margin-top: 20px;
    padding-bottom: 40px;
    border: none;
    border-radius: 0;
    overflow: scroll;
}

.popout__container .form__section .input_field__input_box--text {
    height: 40px;
}

.popout__container .popout__header .options-button-header {
    position: absolute;
    top: 0;
    right: 45px;
    margin-left: 15px;
}

.popout__container .popout__header .popout_buttons {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 10px;
}

.popout__container .popout__header .popout_buttons button {
    position: relative;
    right: 0;
}