.todo_today__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

#dashboard-activities-list {
    height: 100%;
    display: flex;
    flex-direction: column;
}

#dashboard-activities-list {
    padding: 0;
}

#dashboard-activities-list .table__row-4 {
    grid-template-columns: 50px 1fr 1.5fr 0.75fr;        
    column-gap: 2.5px;
    border: solid 2px white;
    border-left: 5px solid var(--secondairy-light-gray);
    background-color: var(--secondairy-ultra-light-gray);
    cursor: pointer;
}

#dashboard-activities-list .table__row-4 img {
    height: 25px;
}

#dashboard-activities-list .table__row-4.overdue {
    background-color: var(--hyper-light-red);
    border: solid 2px white;
    border-left: var(--light-red) solid 5px;
}

#dashboard-activities-list .table__row-4.done {
    background-color: var(--ultra-light-green);
    border: solid 2px white;
    border-left: var(--light-green) solid 5px;
}

 #dashboard-activities-list .no_task__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#dashboard-activities-list .no_task__container img {
    height: 20%;
    opacity: 0.5;
}

#dashboard-activities-list .no_task__container p {
    color: var(--secondairy-medium-gray);
    margin-top: 15px;
}

@media only screen and (max-width: 768px) {

    #dashboard-activities-list .table__row-4 {
        grid-template-columns: 30px 1fr 1.5fr 0.75fr;
        border: solid 1px var(--secondairy-light-medium-gray);
        border-left: 5px solid var(--secondairy-light-medium-gray);
        background-color: var(--secondairy-light-gray);
        margin-bottom: 2px;
        font-size: 0.75rem;
        word-wrap: break-word;
    }

    #dashboard-activities-list .table__row-4.overdue {
        border: solid 1px var(--light-red);
        border-left: var(--red) solid 5px;
    }
}  