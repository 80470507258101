.popout__container.active {
    pointer-events: all;
    opacity: 1;
    z-index: 501;
}

.popout__container .popup__options-button {
    position: relative;
    left: 0;
    top: 0;
}

.popout__container #popup__close-icon {
    position: absolute;
    color: var(--primary-black);
    font-size: 0.7rem;
    right: 8px;
    top: 8px;
    cursor: pointer;
    background-color: var(--secondairy-ultra-light-gray);
    border: 1px solid var(--secondairy-light-gray);
    border-radius: 20px;
    width: 25px;
    height: 18px;
    padding: 3px;
}

.popout__container h3 {
    font-weight: 700;
    color: var(--primary-black);
    font-variant: all-small-caps;
    margin: 10px 0;
    font-size: 1rem;
}

.popout__container .form__section {
    padding: 0;
    margin-top: 20px;
    padding-bottom: 40px;
    border: none;
    border-radius: 0;
    overflow: scroll;
}