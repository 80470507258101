.body__customer-info {
  display: flex;
  flex-direction: column;
  position: relative;
  /* display: grid;
  grid-template: 60% 40% / 1fr 1fr;
  grid-template-areas: 
    "general general"
    "visits suppliers";
  ; */
  width: 100%;
  height: 100%;
}

#customer-info__section {
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  gap: 10px;
  height: 100%;
  overflow: hidden;
}

#customer-info__section .form__section {
  height: 100%;
}

#customer-info__section .secondairy-button {
  /* requires index.css */
  height: 30px;
  margin-top: 5px;
  margin-left: 5px;
}

.body__customer-info .popup-window__details__item--report-container {
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  gap: 12.5px;
  height: fit-content;
}

.body__customer-info .popup-window__details__container__item--comment {
  grid-column: 1 / 3;
}

.body__customer-info .popup-window__details__container__item--comment .comment {
  min-height: 40px;
  width: 100%;
  border-radius: 5px;
  border: var(--secondairy-light-gray) solid 1px;
  padding: 5px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
}

.body__customer-info .visit-details__container {
  display: grid;
}

.body__customer-info .popup-window__details__container {
  margin-bottom: 10px;
  display: grid;
  grid-template: auto / 1fr 1fr;
  column-gap: 25px;
}

.body__customer-info .popup-window__details__item--title {
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 0 10px 0;
  color: var(--primary-black);
  margin: 10px 0;
  font-variant: all-small-caps;
}

.body__customer-info .popup-window__details__container__item--text {
  display: grid;
  grid-template: auto / 1fr 1fr;
  column-gap: 10px;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
}

.body__customer-info .popup-window__details__container__item--checkbox {
  min-height: 30px;
}

.body__customer-info .popup-window__details__container__item--text .value {
  min-height: 100%;
  width: 100%;
  border-radius: 5px;
  border: var(--secondairy-light-gray) solid 1px;
  padding: 5px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
}

.body__customer-info .popup-window__details__container__item--text .mini_title {
  color: var(--secondairy-medium-gray);
  width: 100px;
  min-height: 28px;
  margin-bottom: 0;
}

.body__customer-info .mini_title {
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
  margin-bottom: 5px;
}

#customer-availability__section {
  height: 100%;
  overflow: hidden;

}

#customer-suppliers__section {
  height: 100%;
  overflow: hidden;
}

#customer-suppliers__section .input_field__label {
  align-self: center;
}

.body__customer-info .popup_window__suppliers_list .supplier__item {
  width: 100%;
  display: grid;
  grid-template: auto / 0.5fr 1fr 0.8fr;
  font-size: 0.75rem;
  padding: 10px 5px;
  border-radius: 7.5px;
  cursor: pointer;
}

.body__customer-info .popup_window__suppliers_list .supplier__item:hover {
  background-color: var(--secondairy-ultra-light-gray);
}

.body__customer-info .popup_window__suppliers_list .supplier__item .supplier__item__company_name {
  padding: 2.5px 0;
}

.body__customer-info .popup_window__suppliers_list .supplier__item .supplier__item__contact_email {
  padding: 2.5px 0;
  color: var(--secondairy-light-medium-gray);
}

.body__customer-info .popup_window__suppliers_list .label__vendors .label {
  width: 90%;
}

.body__customer-info .popup_window__suppliers_list .label__vendors {
  position: relative;
}

#dropdown__customers_type,
#dropdown__customers_sub_type {
  top: 50px;
}

.body__customer-info .body__section__header {
  display: flex;
  margin-bottom: 7.5px;
  width: 100%;
  cursor: pointer;
}

.body__customer-info .order {
  grid-column: span 2;
}

.body__customer-info .table__secondairy-header-6 {
  display: grid;
  grid-template: auto / 1fr 0.9fr 2fr 1fr 1fr;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 5px;
}

.body__customer-info .table__secondairy-header-6 .table__secondairy-header--field {
  padding: 0;
}

.body__customer-info .sales__orders--product-rows {
  display: grid;
  width: 70%;
  margin-right: auto;
  font-size: 0.75rem;
  display: grid;
  grid-template: auto / 1fr 0.9fr 2fr 1fr 1fr;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
}

.body__customer-info .sales__orders--product-rows.odd {
  background-color: var(--secondairy-ultra-light-gray);
}

.body__section--board {
  display: grid;
  grid-template: repeat(7, 1fr) / repeat(5, 1fr);
  gap: 12.5px;
}

.availability_section,
.classification_section {
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: var(--border-shadow-min);
  background-color: white;
  border-radius: 7.5px;
  padding: 15px;
  min-height: 150px;
  font-size: 0.75rem;
  color: var(--primary-black);
  font-weight: 300;
}

.availability_section h1,
.classification_section h1 {
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.availability_section h2,
.classification_section h2 {
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 15px;
}

.availability_section__content_container,
.classification_section__content_container {
  overflow-y: scroll;
  overflow-x: hidden;
}

.availability_section__content_container::-webkit-scrollbar,
.classification_section__content_container::-webkit-scrollbar {
  display: none;
}


#customer-details__section {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-template-areas:
    "owner owner rhythm rhythm estimates estimates segmentation segmentation"
    "owner owner rhythm rhythm estimates estimates segmentation segmentation"
    "custom custom custom custom custom details details details"
    "custom custom custom custom custom details details details"
    "custom custom custom custom custom details details details"
    "custom custom custom custom custom details details details"
    "custom custom custom custom custom details details details";
}

#contact-rhythm {
  grid-area: rhythm;
}

#custom-details {
  grid-area: custom;
}

#ownership {
  grid-area: owner;
}

#estimates {
  grid-area: estimates;
}

#segmentation {
  grid-area: segmentation;
}

#business-details {
  grid-area: details;
}

#days-to-next-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: var(--secondairy-medium-gray);
  font-weight: 600;
  width: 100%;
  height: 40px;
  background-color: var(--secondairy-ultra-light-gray);
  padding: 5px;
  border-radius: 5px;
}

.availability_section .product {
  display: grid;
  grid-template: 1fr / 1fr 0.25fr;
  gap: 10px;
  padding: 5px 0;
  border-bottom: var(--secondairy-light-gray) 1px solid;
}

.availability_section .product p {
  display: flex;
  align-items: center;
}

#latest-report .availability_section__content_container {
  overflow-y: scroll;
  overflow-x: hidden;
}

#latest-report .availability_section__content_container .latest_report {
  display: grid;
  grid-template: auto / 1fr 1fr;
  margin: 10px 0;
  gap: 10px;
}

#latest-report .availability_section__content_container .popup-window__details__container__item--text {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
}

#latest-report .availability_section__content_container .popup-window__details__container__item--checkbox .check-box-static__container {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
}

#customer-availability__section {
  grid-template-areas:
    "products products report report report"
    "products products report report report"
    "products products report report report"
    "products products report report report"
    "posm posm report report report"
    "posm posm report report report"
    "posm posm report report report"
  ;
}

#customer-availability__section .load_more_button {
  border: none;
  margin-top: 15px;
}

#latest-report {
  grid-area: report;
}

#available-POSM {
  grid-area: posm;
}

#available-products {
  grid-area: products;
}


.latest_report--no-visit {
  display: flex;
  flex-direction: column;
}

#customer-visits__section {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#customer-visits__section .table {
  min-height: 100%;
}

#customer-visits__section .table__rows_container {
  min-height: 100%;
}

#customer-visits__section .table__header-9 {
  grid-template-columns: 30px 70px 0.75fr 1.5fr 0.75fr 0.75fr 60px 60px 30px;
  border-left: 5px solid white;
}

#customer-visits__section .table__row-9 {
  grid-template-columns: 30px 70px 0.75fr 1.5fr 0.75fr 0.75fr 60px 60px 30px;
  border: solid 2px white;
  border-left: 5px solid var(--secondairy-light-gray);
  background-color: var(--secondairy-ultra-light-gray);
  cursor: pointer;
}

#customer-visits__section .table__row-9 img {
  height: 25px;
}

#customer-visits__section .table__row-9 svg {
  color: var(--secondairy-medium-gray);
  font-size: 1.2rem;
}

#customer-visits__section .table__row-9.overdue {
  background-color: var(--hyper-light-red);
  border: solid 2px white;
  border-left: var(--light-red) solid 5px;
}

#customer-visits__section .table__row-9.done {
  background-color: var(--ultra-light-green);
  border: solid 2px white;
  border-left: var(--light-green) solid 5px;
}

#customer-visits__section .activity__detail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  background-color: var(--secondairy-ultra-light-gray);
  padding: 15px 20px;
  color: var(--secondairy-medium-gray);
}

#customer-visits__section .activity__detail--comment {
  grid-column: 1;
}

#customer-visits__section .activity__detail h3 {
  margin-bottom: 2.5px;
}

#custom-details .custom_details__wrapper {
  display: grid;
  grid-template: auto / 1fr 1fr;
  gap: 10px;
  margin-bottom: 25px;
}

#ownership h2 {
  margin: 15px 0;
}

#ownership .history_line {
  display: flex;
  gap: 20px;
  padding: 5px 0;
}

#ownership .history_line:nth-child(even) {
  background-color: var(--secondairy-ultra-light-gray);
}

#customer-visits__section .activity__detail--email {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

#customer-visits__section .activity__detail--email .email-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

#customer-suppliers__section .suppliers_container {
  position: relative;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 50px;
  height: 100%;
}

#customer-suppliers__section .customer_suppliers {
  display: flex;
  flex-direction: column; 
  gap: 10px;
}

.body__section_title {
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#customer-suppliers__section .suppliers_container .add-supplier,
#customer-suppliers__section .suppliers_container .supplier_item {
  display: grid;
  grid-template: 36px / 2fr 1fr 0.5fr;
  gap: 10px;
}

#customer-suppliers__section .suppliers_container .add-supplier .add--btn,
#customer-suppliers__section .suppliers_container .add-supplier .input_field__input_box--text {
  margin: 0;
  height: 100%;
  font-size: 0.75rem;
}

#customer-suppliers__section .suppliers_container .suppliers_list {
  overflow-y: auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#customer-suppliers__section .suppliers_container .suppliers_list .delete-btn {
  grid-column: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-red);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#customer-suppliers__section .suppliers_container .supplier_item .supplier_item__alias,
#customer-suppliers__section .suppliers_container .supplier_item .supplier_item__name {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
  padding: 10px;
  border: var(--secondairy-light-gray) 1px solid;
  border-radius: 5px;
}

#customer-suppliers__section .supplying_customers {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 100%;
  overflow-y: hidden;
}

#customer-suppliers__section .supplying_customers_list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  min-height: 0;
}

#customer-suppliers__section .supplying_customers_list .supplying_customer {
  display: grid;
  grid-template: 1fr / 2fr 1fr;
  gap: 10px;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

#customer-suppliers__section .supplying_customers_list .supplying_customer:nth-child(odd) {
  background-color: var(--secondairy-ultra-light-gray);
}

.supplier_item__name.skeleton,
.supplier_item__alias.skeleton {
  background: linear-gradient(90deg, var(--secondairy-light-gray) 25%, var(--secondairy-ultra-light-gray) 50%, var(--secondairy-light-gray) 75%);
  background-size: 200% 100%;
  animation: skeleton-loading-input 1.5s infinite;
  pointer-events: none;
  color: transparent;
  border: none;
}

.delete-btn.hide {
  display: none;
}

#customer-suppliers__section .suppliers_my_products_container {
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

#customer-suppliers__section .suppliers_my_products_list {
  position: relative;
  overflow-y: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

#customer-suppliers__section .suppliers_my_products_list h1 {
  margin-bottom: 10px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 600;
  text-transform: uppercase;
}

#customer-suppliers__section .suppliers_my_products_list .products_list {
  overflow-y: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#customer-suppliers__section .suppliers_my_products_list .products_list .products_list_container {
  overflow-y: auto;
}

#customer-content__section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

#customer-content__section h2 {
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 600;
  text-transform: uppercase;
}

/* Dropdown for business name search */
.body__customer-info .dropdown_field {
  background-color: var(--secondairy-ultra-light-gray);
  border: 1px solid var(--secondairy-light-gray);
  padding: 0;
  width: 100%;
  height: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
}

.body__customer-info .dropdown_field .limit {
  padding: 7.5px 5px;
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.body__customer-info .dropdown_field .limit > svg {
  font-size: 1rem;
}

.body__customer-info .dropdown_field .circular_loading_spinner__wrapper {
  padding: 10px 0;
}

.body__customer-info .dropdown_field__item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 10px 10px;
}

.body__customer-info .dropdown_field__item:hover {
  background-color: var(--secondairy-light-gray);
}

.body__customer-info .dropdown_field__item > div {
  color: var(--secondairy-medium-gray);
  cursor: pointer;
}

.body__customer-info .new-suppliers-container {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.body__customer-info .add-supplier {
  display: flex;
  gap: 10px;
}

.set_all_suppliers {
  margin-bottom: 20px;
  width: 25%;
}

.set_all_suppliers button {
  width: 100%;
  font-weight: 500;
}

.body__customer-info .api_options {
  position: relative;
  top: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 35px;
  margin: 10px 0;
}

.body__customer-info .api_options .business-central-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border: 1px solid var(--secondairy-light-medium-gray);
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--secondairy-light-medium-gray);
}

.body__customer-info .api_options .business-central-icon.clicked {
  background-color: var(--light-primary-green);
  border-color: var(--primary-green);
  color: white;
}

.body__customer-info .api_options .business-central-icon svg {
  width: 20px;
  height: 20px;
}
