#my-sales-wrapper .my_sales__graph {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#my-sales-wrapper .chart__container {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
}

#my-sales-wrapper .chart__container > p {
 align-self: flex-start;
}

#my-sales-wrapper .chart__container canvas {
  width: 100%;
  height: 100%;
}

#my-sales-wrapper .chart__container.is_mobile {
  height: 100%;
}