.google_map_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.google_map_container .loading_overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  color: var(--secondairy-medium-gray);
}

.google_map_container .loading_overlay::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: var(--secondairy-light-medium-gray);
  opacity: 0.4;
}

.google_map_container .map_ui_elements {
  position: absolute;
  left: 25px;
  top: 25px;
  width: 20%;
  min-height: 20px;
  z-index: 1;
}

.google_map_container .map_ui_element {
  width: 100%;
  height: 35px;
  padding: 7.5px;
  background-color: var(--secondairy-ultra-light-gray);
  border: var(--secondairy-light-medium-gray) 1px solid;
  border-radius: 12.5px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.google_map_container .map_ui_element .title {
  font-size: 0.85rem;
  font-weight: 500;
}

.google_map_container .map_ui_element .content {
  font-size: 0.85rem;
}

.google_map_container .load_more_button {
  background-color: var(--secondairy-medium-gray);
  color: var(--secondairy-ultra-light-gray);
  border: none;
  border-radius: 10px;
  padding: 7.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  width: 100%;

  transition: all 400ms ease-in-out;
}

.google_map_container .load_more_button:hover { 
  background-color: var(--secondairy-light-medium-gray);
}

