.admin-consent-message {
  margin-top: 1rem;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.admin-consent-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.admin-consent-header .info-icon {
  color: var(--primary-green);
}


.admin-consent-header h4 {
  margin: 0;
  color: var(--primary-green);
  font-weight: 600;
}


.admin-consent-message p {
  margin: 0.5rem 0;
  color: #495057;
  line-height: 1.5;
}

.admin-consent-url {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
  background-color: white;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid #dee2e6;
}

.admin-consent-url input {
  flex: 1;
  padding: 0.5rem;
  border: none;
  background: transparent;
  color: #495057;
  font-family: monospace;
  font-size: 0.9rem;
}

.admin-consent-url input:focus {
  outline: none;
}

.admin-consent-url .copy-button {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.admin-consent-url .copy-button:hover {
  background-color: #e9ecef;
  color: #0066cc;
}

.admin-note {
  font-size: 0.9rem;
  color: #6c757d !important;
  font-style: italic;
}

/* Existing calendar connection styles */
.calendar-connection {
  margin-bottom: 1.5rem;
}

.microsoft-calendar-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 6px;
  background-color: #0078d4;
  color: white;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.microsoft-calendar-btn:hover {
  background-color: #106ebe;
}

.connection-status {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.connected-text {
  color: #28a745;
  font-weight: 500;
}

.disconnect-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #dc3545;
  border-radius: 4px;
  background: none;
  color: #dc3545;
  cursor: pointer;
  transition: all 0.2s ease;
}

.disconnect-btn:hover {
  background-color: #dc3545;
  color: white;
} 