#export-popup .loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: var(--secondairy-light-medium-gray);
}

#export-popup h2 {
  margin: 10px 0;
}

#export-popup .options-button-header.full-width {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  padding: 15px 0;
}

.groups-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.interest-container {
  padding: 8px 16px;
  border: 1px solid var(--secondairy-light-medium-gray);
  border-radius: 7.5px;
  font-size: 0.8rem;
  color: var(--secondairy-medium-gray);
  cursor: pointer;
  transition: all 0.3s ease;
  user-select: none;
}

.interest-container:hover {
  background-color: var(--secondairy-light-gray);
}

.interest-container.selected {
  background-color: var(--light-primary-green);
  border-color: var(--light-primary-green);
}


