.popup_window__container.filters {
  position: absolute;
  top: 105vh;
  left: 0;
  width: 100vw;
  height: 70vh;
  background-color: var(--primary-dark-theme);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  z-index: 49;
  transition: all 600ms ease-in-out;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.popup_window__container.filters.active {
  transform: translateY(-70vh);
}

.popup_window__container.filters .popup_header {
  position: relative;
  height: 70px;
  padding: 10px;
}

.popup_window__container.filters .go_back {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  gap: 5px;
  text-transform: uppercase;
  height: 50px;
  width: 50px;
  color: var(--secondairy-light-gray);
  font-size: 1.2rem;
}

.popup_window__container.filters .popup_body {
  position: relative;
  height: calc(100% - 150px);
  padding: 10px;
  overflow: scroll;
}

.popup_window__container.filters .popup_body .table {
  min-height: 100%;
}

.popup_window__container.filters .popup_body::-webkit-scrollbar {
  display: none;
}

.popup_window__container.filters .popup_filter__searchbar_wrapper {
  position: relative;
}

.popup_window__container.filters .popup_filter__searchbar_wrapper svg {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(calc(-50% - 7.5px));
  color: var(--secondairy-light-medium-gray-plus);
}

.popup_window__container.filters .popup_filter__searchbar {
  width: 100%;
  height: 60px;
  background-color: transparent;
  border: none;
  border: var(--secondairy-light-medium-gray-plus) solid 1px;
  color: var(--secondairy-light-gray);
  background-color: var(--secondairy-medium-gray);
  padding: 7.5px;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  margin-bottom: 15px;
}

.popup_window__container.filters .popup_filter__searchbar::-webkit-input-placeholder, .popup_window__container.filters .popup_filter__searchbar::placeholder {
  color: var(--secondairy-light-medium-gray-plus);
}

.popup_window__container.filters .popup_filter__searchbar:focus {
  outline: none;
  border-radius: 5px;
  background-color: var(--secondairy-medium-gray);
  border: var(--secondairy-light-medium-gray) solid 1px;
}

.popup_window__container.filters .form_dropdown--mobile {
  background-color: var(--secondairy-medium-gray);
  border: 1px solid var(--secondairy-medium-gray-plus);
}

.popup_window__container.filters .form_dropdown--mobile p {
  color: var(--secondairy-light-gray);
  font-weight: 400;
}

.popup_window__container.filters .form_dropdown--mobile .placeholder {
  color: var(--secondairy-medium-gray-plus);
  font-weight: 400;
}

.popup_window__container.filters .sliders {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 7.5px;
}

.popup_window__container.filters .sliders .slider  {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.popup_window__container.filters .sliders .slider .slider__bar  {
  justify-self: right;
}