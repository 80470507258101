.pagination {
    font-size: 0.85rem;
    padding: 7.5px 0 0 0;
}

.pagination .pagination__btn {
    font-size: 0.85rem;
}

.pagination .pagination__btn.selected {
    background-color: var(--light-primary-green);
}