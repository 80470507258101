.template__invoice-admin-annexe {
    width: 217mm;
    height: fit-content;
    /* height: 279.25mm; */
    margin: auto;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.template__invoice-admin-annexe .invoice__header {
    position: relative;
    padding-top: auto;
    background-color: var(--primary-black);
}

.template__invoice-admin-annexe .invoice__header img {
    margin-left: 10px;
    height: 140px;
}

.invoice__details--annexe {
    margin-top: 10px;    
    font-size: 0.9rem;
    line-height: 20px;
    font-weight: 300;
    padding: 20px;
}

.template__invoice-admin-annexe .table__header-4 {
    background-color: var(--secondairy-light-gray);
    font-size: 0.65rem;
    grid-template: 35px / 5% 15% 30% 50%;
    border-radius: 5px;
}

.template__invoice-admin-annexe .table__row-4.background {
    background-color: var(--secondairy-ultra-light-gray);
    grid-template: auto / 5% 15% 30% 50%;
    font-size: 0.65rem;
    padding: 2.5px 5px;
}

.template__invoice-admin-annexe .table__row--field {
    padding: 0 5px;
}

.template__invoice-admin-annexe .table__secondairy-row-4 {
    width: 35%;
    grid-template: auto / 0.3fr 1fr 0.5fr;
    margin-left: auto;
    padding: 0;
    margin-right: 5px;
}

.template__invoice-admin-annexe .table__secondairy-row--field {
    /* border: solid var(--secondairy-light-gray) 1px; */
    padding: 2.5px;
    font-size: 0.65rem;
    font-style: italic;
}

