.body__my_team {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#new-salesforce-form {
  width: 100%;
  height: 30%;
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  gap: 10px;
}

#my-team-table {
  width: 100%;
  height: 70%;
}

.body__my_team .table__header-4 {
  grid-template: 35px / 50px 0.7fr 1fr 0.5fr 0.5fr 0.5fr;
}

.body__my_team .table__row-4 {
  grid-template: 35px / 50px 0.7fr 1fr 0.5fr 0.5fr 0.5fr;
}

.body__my_team .table__row-4 .table__row--field:last-child {
  justify-self: end;
  margin-right: 20px;
}

#free-user-restriction--my-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#free-user-restriction--my-team .body__section_content {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}

#free-user-restriction--my-team .body__section_content p {
  width: 50%;
  text-align: center;
  color: var(--secondairy-medium-gray);
}

#free-user-restriction--my-team .body__section_content h3 {
  font-weight: 500;
  color: var(--secondairy-medium-gray);
  margin-bottom: 25px;
}

#free-user-restriction--my-team img {
  height: 55%;
}

@media screen and (min-width: 980px) {
  .body__my_team .table__header-4 {
    grid-template: 35px / 50px 0.7fr 1fr 0.5fr 0.5fr 3fr;
  }

  .body__my_team .table__row-4 {
    grid-template: 35px / 50px 0.7fr 1fr 0.5fr 0.5fr 3fr;
  }
}