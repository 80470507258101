.template__invoice-admin {
    width: 217mm;
    height: 279.25mm;
    margin: auto;
    display: grid;
    grid-template: 20% 17.5% 40% 22.5% / 1.7fr 1fr;
    grid-template-areas: 
    "header header"
    "details details"
    "order order"
    "footer footer"
    ;
    margin: 0;
    padding: 0;
}

.template__invoice-admin .table__header-6 {
    background-color: var(--secondairy-ultra-light-gray);
    font-size: 0.7rem;
    grid-template: 35px / 2.6fr 1fr 1fr 1fr 1fr 1fr;
    border-radius: 5px;
}

.template__invoice-admin .table__row-6.no-background {
    background-color: white;
    grid-template: auto / 2.6fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0 10px;
}

.template__invoice-admin .table__header-5 {
    background-color: var(--secondairy-ultra-light-gray);
    font-size: 0.7rem;
    grid-template: 35px / 2.6fr 1fr 1fr 1fr 1fr;
    border-radius: 5px;
}

.template__invoice-admin .table__row-5 {
    grid-template: auto / 2.6fr 1fr 1fr 1fr 1fr;
    padding: 0 10px;
}

.template__invoice-admin .invoice__details .flex,
.template__invoice-admin .invoice__invoicing_details .flex{
    gap: 7px;
}

.template__invoice-admin .invoice__header {
    position: relative;
    grid-area: header;
    padding-top: auto;
    background-color: var(--primary-black);
}

.template__invoice-admin .invoice__header img {
    margin-left: 10px;
    height: 140px;
}

.invoice__details {    
    position: absolute;
    right: 90px;
    top: 60px;
    color: white;
    font-size: 0.9rem;
    line-height: 20px;
    font-weight: 300;
}

.template__invoice-admin .invoice__details h1{
    color: var(--primary-green);
    font-size: 2.5rem;
    margin-bottom: 15px;
    line-height: auto;
}

.invoice__details h4 {    
    text-transform: capitalize;
    font-size: 0.9rem;
    font-weight: 400;
    width: 90px;
}

.invoice__invoicing_details {
    width: 100%;
    display: flex;
    grid-area: details;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
}

.template__invoice-admin .invoice__details--client {    
    font-size: 0.9rem;
    line-height: 20px;
    width: 30%;
}

.template__invoice-admin .invoice__details--client h2 {    
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--primary-black);
}   

.template__invoice-admin .invoice__details--stoqup {    
    font-size: 0.9rem;
    line-height: 20px;
    width: 30%;
}

.template__invoice-admin .invoice__details--stoqup h2 {    
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--primary-black);
}

.template__invoice-admin .invoice__details--order {    
    grid-area: order;
}

.template__invoice-admin .invoice_totals {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: right;
    margin-left: auto;
    width: 200px;
    font-size: 0.7rem;
    font-weight: 300;
    margin-top: 25px;
    margin-right: 25px;
    line-height: 20px;
    background-color: var(--secondairy-ultra-light-gray);
    padding: 15px;
    border-radius: 5px;
}

.template__invoice-admin .invoice_totals h4 {
    font-weight: 500;
    width: 100px;
}

.template__invoice-admin .invoice_totals p {
    width: 100px;
    text-align: left;
}

.template__invoice-admin .invoice__footer {
    position: relative;
    grid-area: footer;
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
}

.template__invoice-admin .contact-info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.template__invoice-admin .contact-info--element {
    display: flex;
    height: 30px;
    width: 50%;
    margin: 0 0 0 25px;
}

.template__invoice-admin .contact-info--element div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30;
    width: 22.5px;
    background-color: var(--primary-black);
}

.template__invoice-admin .contact-info--element div:nth-child(2) {
    width: 100%;
    text-align: left;
    padding: 5px 10px;
    color: var(--primary-black);
}

.template__invoice-admin .contact-info--element div:first-child svg {
    color: var(--primary-green);
    height: 60%;
    width: 60%;
}

.template__invoice-admin .contact-info--element:first-child div {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.template__invoice-admin .contact-info--element:last-child div {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.template__invoice-admin .invoice__footer--payment {
    margin: 30px 0 30px 0;
    text-align: center;
    color: var(--secondairy-medium-gray);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1rem;
}