.body__inventory_logs {
  height: 100%;
}

.body__inventory_logs #circular-progress {
  color: var(--secondairy-light-gray);
}

.body__inventory_logs .body__section {
  height: 100%;
}

.body__inventory_logs .inventory_logs__warehouse {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  background-color: var(--secondairy-ultra-light-gray);
  padding: 10px 15px;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--primary-black);
  margin: 7.5px 0;
  border-radius: 7.5px;
  cursor: pointer;
}

.body__inventory_logs .inventory_logs__data .no_data {
  font-size: 0.75rem;
  font-style: italic;
  width: 100%;
  text-align: center;
  color: var(--secondairy-medium-gray);
}

.body__inventory_logs .inventory_logs__warehouse svg {
  color: var(--secondairy-light-medium-gray);
  transition: transform 250ms ease-in-out;
}

.body__inventory_logs .inventory_logs__warehouse svg.selected {
  transform: rotateZ(135deg);
}

.body__inventory_logs .inventory_logs__data .table__row-8 {
  grid-template: 20px / 1fr 1fr 1fr 1fr 1fr 0.5fr 0.8fr 1.3fr;
}

.body__inventory_logs .inventory_logs__data .table__header-8 {
  grid-template: 20px / 1fr 1fr 1fr 1fr 1fr 0.5fr 0.8fr 1.3fr;
}