.body__settings {
  display: grid;
  grid-template: auto / 15% 85%;
  gap: 10px;
  height: 100%;
}

.body__settings .body__section {
  height: 100%;
}

.body__settings .tertiairy_menu_title {
  font-size: 0.8rem;
  width: 100%;
  font-weight: 500;
  padding: 10px;
  text-align: left;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.body__settings .tertiairy_menu_title:hover {
  background-color: var(--secondairy-light-gray);
}

.body__settings .tertiairy_menu_title.selected {
  border-left: var(--secondairy-light-gray) solid 5px;
}

.body__settings #body-section-settings {
  position: relative;
  padding: 25px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.body__settings #body-section-settings .settings_item {
  width: 100%;
  margin-bottom: 15px;
}

.body__settings #body-section-settings .settings_item--25 {
  width: 25%;
  margin-bottom: 15px;
}

.body__settings #body-section-settings .settings_item--50 {
  width: 50%;
  margin-bottom: 15px;
}

.body__settings #body-section-settings .settings_item--100 {
  width: 100%;
  margin-bottom: 15px;
}

.body__settings #body-section-settings .input_box__title,
.body__settings #body-section-settings .input_field__label {
  font-size: 0.8rem;
  font-weight: 400;
  color: black;
}

.body__settings #body-section-settings .settings_customer_details {
  overflow: auto;
  padding-bottom: 30px;
}

.body__settings #body-section-settings .settings_item .input_box__container {
  display: grid;
  grid-template: 1fr / 1fr 60px;
  gap: 20px;
  margin-top: 10px;
}


.body__settings .settings_details__container {
  height: 100%;
  overflow: auto;
}

.body__settings .settings_details__container h2 {
  font-size: 0.85rem;
  color: var(--secondairy-medium-gray);
  font-weight: 500;
}

.body__settings #settings_account .settings_forms__wrapper {
  width: 100%;
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  gap: 15px;
}

.body__settings #settings_customer_database .settings_forms__wrapper {
  width: 100%;
  display: grid;
  grid-template: 1fr / 500px;
  gap: 15px;
}

.body__settings .settings_details__container .settings_forms__wrapper {
  width: 100%;
  display: grid;
  grid-template: 1fr / repeat(2, 1fr);
  gap: 15px;
}

.body__settings .settings_details__container .settings_forms__wrapper .settings_form {
  width: 100%;
  border: var(--secondairy-light-gray) solid 1px;
  border-radius: 15px;
  padding: 10px;
}

.body__settings .settings_details__container .settings_form h2 {
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: var(--secondairy-medium-gray);
  width: 99%;
  text-align: right;
}

.body__settings .settings_details__container .settings_form {
  width: 33%;
  padding: 15px 0;
}

.body__settings .settings_details__container .settings_form--50 {
  width: 50%;
}

.body__settings .settings_details__container .settings_form label {
  margin-top: 10px;
}

.body__settings .settings_details__container .settings_form .dropdown_wrapper {
  width: 50%;
}

.body__settings .api_services__wrapper {
  width: 50%;
}

.body__settings .api_service {
  position: relative;
  padding: 10px;
  font-size: 0.7rem;
  border: solid var(--secondairy-light-gray) 1px;
  border-radius: 5px;
  margin-bottom: 5px;
  line-height: 1.5rem;
}

.body__settings .api_service .api_key {
  font-size: 0.7rem;
  color: var(--secondairy-light-medium-gray);
  padding: 3px 5px;
  line-height: normal;
  border: var(--secondairy-light-gray) 1px solid;
  background-color: var(--secondairy-ultra-light-gray);
  border-radius: 5px;
  height: auto;
  margin-right: 5px;
  font-style: italic;
  cursor: text;
}

.body__settings .report-item {
  position: relative;
  padding: 10px;
  font-size: 0.7rem;
  border: solid var(--secondairy-light-gray) 1px;
  border-radius: 5px;
  margin-bottom: 5px;
  line-height: 1.5rem;
}

.body__settings .report-item .type_selector {
  margin: 15px 0;
}

.body__settings .action__buttons {
  display: flex;
  gap: 5px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.body__settings .action__buttons button {
  border: 1px var(--secondairy-light-medium-gray) solid;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.body__settings .action__buttons button svg {
  font-size: 1rem;
}

.body__settings .action__buttons .mini_action__buttons--edit {
  color: var(--secondairy-medium-gray);
  background-color: var(--secondairy-light-gray);
}

.body__settings .mini_action__buttons--delete {
  border: 1px var(--secondairy-light-medium-gray) solid;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  color: var(--dark-red);
  background-color: var(--ultra-light-red);
}

.body__settings .mini_action__buttons--save {
  color: var(--dark-green);
  background-color: var(--light-primary-green);
}

.body__settings .report-item h4 {
  margin-right: 7.5px;
}

.body__settings .report-item__type {
  display: flex;
  margin: 10px 0;
}

.body__settings .report-item .dropdown_values input {
  padding: 1px 3px;
  border: solid 1px var(--secondairy-light-gray);
  background-color: var(--secondairy-ultra-light-gray);
  border-radius: 5px;
  margin-bottom: 2px;
  min-height: 25px;
  width: 100%;
}

.body__settings .pick-option-button {
  font-size: 0.7rem;
  color: var(--secondairy-light-medium-gray);
  padding: 3px 5px;
  line-height: normal;
  border: var(--secondairy-light-gray) 1px solid;
  background-color: var(--secondairy-ultra-light-gray);
  border-radius: 5px;
  height: auto;
  margin-right: 5px;
  font-style: italic;
  cursor: pointer;
  user-select: none;
}

.body__settings .pick-option-button.hover {
  background-color: var(--secondairy-light-gray);
  font-style: normal;
  color: var(--secondairy-medium-gray);
}

.body__settings .pick-option-button.selected {
  background-color: var(--secondairy-light-gray);
  font-style: normal;
  color: var(--secondairy-medium-gray);
}

.body__settings .dropdown_value {
  border: 1px solid var(--secondairy-light-gray);
  background-color: var(--secondairy-ultra-light-gray);
  font-size: 0.7rem;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 2px;
}

.body__settings .dropdown_value::placeholder {
  font-style: italic;
  color: var(--secondairy-light-medium-gray);
}

.body__settings .dropdown_value:focus {
  outline: none;
}

.body__settings .add-report-item__title {
  font-size: 0.75rem;
  font-weight: 500;
}

.body__settings .add-report-item__title.editing {
  padding: 5px;
  border-radius: 5px;
  background-color: var(--secondairy-ultra-light-gray);
  border: var(--secondairy-light-gray) solid 1px;
  width: 50%;
  outline: none;
}

.body__settings .add-report-item__input {
  border: none;
  font-size: 0.7rem;
}

.body__settings .add-report-item__input::placeholder {
  color: var(--secondairy-light-medium-gray);
  font-style: italic;
}

.body__settings .add-report-item__input:focus {
  outline: none;
}

.body__settings .add-report-item__type {
  display: flex;
  margin: 10px 0;
}

.body__settings h1 {
  font-size: 0.85rem;
  font-weight: 600;
  margin: 20px 0;
  color: var(--secondairy-medium-gray);
  width: 100%;
  text-align: left;
  text-transform: uppercase;
}

#settings_account {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

#settings_account .settings_forms__wrapper {
  position: relative;
  height: calc(100% - 31px);
}

#settings_account .settings_forms__wrapper .settings_form {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

#settings_account .avatars_container {
  width: 100%;
  display: grid;
  grid-template: 1fr / repeat(auto-fill, minmax(50px, 1fr));
  gap: 15px;
  padding: 15px 10px;
  overflow: auto;
}

#settings_account .avatars_container img {
  width: 60px;
  border-radius: 50%;
  cursor: pointer;
}

#settings_account .avatars_container img.selected {
  border: black 3px solid;
}

#settings_customer_database .customer_types__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#settings_customer_database .add-customer-type,
#settings_customer_database .add-subtype {
  display: flex;
  align-items: center;
  gap: 10px;
}

#settings_customer_database .add-customer-type .input_field__input_box--text {
  margin: 0;
}

#settings_customer_database .customer_type {
  position: relative;
  border: 1px solid var(--secondairy-light-gray);
  padding: 10px;
  border-radius: 5px;
}

#settings_customer_database .customer_type h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  height: 30px;
}

#settings_customer_database .subtypes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  height: 30p;
}

#settings_customer_database .subtypes .input_field__input_box--text,
#settings_customer_database .subtypes .add--btn {
  height: 30px;
  margin: 0;
}

#settings_customer_database .subtype > .input_field__input_box--text.editing {
  padding: 5px;
  border-radius: 5px;
  border: var(--secondairy-light-gray) solid 1px;
  background-color: var(--secondairy-ultra-light-gray);
  margin-bottom: 0;
  font-weight: 500;
}

#settings_customer_database .customer_type > .input_field__input_box--text.editing {
  padding: 5px;
  border-radius: 5px;
  border: var(--secondairy-light-gray) solid 1px;
  background-color: var(--secondairy-ultra-light-gray);
  margin-bottom: 10px;
  margin-top: 0;
  width: 80%;
  font-weight: 500;
}


#settings_customer_database .subtype {
  font-size: 0.7rem;
  height: 30px;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 5px;
}

#settings_customer_database .subtype svg {
  font-size: 1.1rem;
  cursor: pointer;
}

#settings_customer_database .delete-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--red);
}

#settings_customer_database .add--btn {
  background-color: var(--light-primary-green);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  height: 30px;
}

#settings_customer_database h4 {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--secondairy-medium-gray);
}

.customer_settings__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.customer_settings__wrapper h1 {
  font-size: 0.85rem;
  font-weight: 600;
  margin: 20px 0;
  color: var(--secondairy-medium-gray);
  width: 100%;
  text-align: left;
  text-transform: uppercase;
}

.body__settings .google-calendar-connection {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.body__settings .google-calendar-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.body__settings .google-calendar-btn:hover {
  background-color: #f5f5f5;
}

.body__settings .calendar-connection {
  margin-bottom: 1rem;
}

.body__settings .calendar-connection h3 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #666;
}

.body__settings .microsoft-calendar-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #0078d4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.body__settings .microsoft-calendar-btn:hover {
  background-color: #106ebe;
}

.body__settings .calendar-connection .connection-status {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.body__settings .connected-text {
  color: #4caf50;
}

.body__settings .connection-status {
  display: flex;
  align-items: center;
  gap: 15px;
}

.body__settings .connected-text {
  color: var(--green);
  font-size: 14px;
}

.body__settings .disconnect-btn {
  padding: 10px;
  background-color: var(--light-red);
  color: var(--red);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.body__settings .salesforce-connection {
  margin-top: 1rem;
}

.body__settings .salesforce-btn {
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid var(--secondairy-light-gray);
  gap: 8px;
  padding: 8px 16px;
  color: var(--secondairy-medium-gray);
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  width: auto;
  height: 50px;

  transition: all 0.2s ease;
}

.body__settings .salesforce-btn svg {
  width: 50px;
  height: 50px;
}

.body__settings .salesforce-btn:hover {
  background-color: var(--secondairy-light-gray);
}

.body__settings .connection-status {
  display: flex;
  align-items: center;
  gap: 12px;
}

.body__settings .connected-text {
  color: #4caf50;
  font-size: 14px;
}

.body__settings .disconnect-btn {
  padding: 4px 12px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.body__settings .calendar-integrations {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.body__settings .calendar-connection {
  flex: 1;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  transition: all 0.2s ease;
}

.body__settings .calendar-connection h3 {
  margin-bottom: 15px;
  font-size: 0,9rem;
  color: var(--secondairy-medium-gray);
  font-weight: 500;
}

.body__settings .google-calendar-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 12px 24px;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: #3c4043;
  transition: all 0.2s ease;
}

.body__settings .microsoft-calendar-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 12px 24px;
  background-color: #0078d4;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: white;
  transition: all 0.2s ease;
}

.body__settings .microsoft-calendar-btn:hover {
  background-color: #106ebe;
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
}

.body__settings .connection-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.body__settings .connected-text {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #34a853;
  font-size: 15px;
  font-weight: 500;
}

.body__settings .disconnect-btn {
  padding: 8px 16px;
  background-color: #fff;
  color: #d93025;
  border: 1px solid #d93025;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.body__settings .disconnect-btn:hover {
  background-color: #fce8e6;
}