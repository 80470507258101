
#salespipe--table .table__row-10 {
  cursor: pointer;
}

#salespipe--table .table__header-10 {
  width: calc(100% - 1em); /* subtract the scrollbar */
  grid-template: 35px / 30px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.7fr 0.7fr;
}

#salespipe--table .table__row-10 {
  grid-template: 30px / 30px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.7fr 0.7fr;
}