#popout-create-activity .popout__box {
    width: 33%;
    max-height: 70%;
    margin-top: 12.5vh;
}

#popout-create-activity .popout__body {
    padding-top: 20px;
    padding-right: 30px;
}


#popout-create-activity .popout__box .popout__body .input_field__input_box--text,
#popout-create-activity .popout__box .popout__body .input_field__input_box--text-dropdown
{
    margin-top: 0;
}


#popout-create-activity .input_field__input_box--textarea {
    min-height: 80px;
}

#popout-create-activity .wdth75 {
    max-width: 150px;
}

#popout-create-activity .priority_container {
    display: grid;
    grid-template: 15px 1fr 1fr / 15px 1fr 1fr;
    grid-template-areas:
        "none urgent not-urgent"
        "important options options"
        "not-important options options"
    ;
}

#popout-create-activity .priority_container .priority_title {
    font-size: 0.5rem;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    color: var(--secondairy-medium-gray)
}

#popout-create-activity .priority_container .priority_title.important {
    grid-area: important;
    transform: rotate(180deg);
    writing-mode: vertical-rl
}

#popout-create-activity .priority_container .priority_title.not-important {
    grid-area: not-important;
    transform: rotate(180deg);
    writing-mode: vertical-rl
}

#popout-create-activity .priority_container .priority_title.urgent {
    grid-area: urgent;
}

#popout-create-activity .priority_container .priority_title.not-urgent {
    grid-area: not-urgent;
}

#popout-create-activity .priority_options {
    grid-area: options;
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    gap: 5px;
}

#popout-create-activity .priority_container .priority_option {
    background-color: blue;
    width: 100%;
    padding-top: 50%;
    position: relative;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

#popout-create-activity .priority_container .priority_option.selected {
    border: 2px solid var(--primary-black);
}

#popout-create-activity .priority_container .priority_option .priority_option_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
}

#popout-create-activity .priority_container .priority_option.one {
    background-color: var(--light-red);
    color: var(--dark-red);
}

#popout-create-activity .priority_container .priority_option.two {
    background-color: var(--special-gold-light);
    color: var(--special-gold-dark);
}

#popout-create-activity .priority_container .priority_option.three {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}

#popout-create-activity .priority_container .priority_option.four {
    background-color: var(--light-green);
    color: var(--dark-green);
}

#popout-create-activity .priority_container .priority_option.one:hover {
    background-color: var(--dark-red);
    color: var(--light-red);
}

#popout-create-activity .priority_container .priority_option.two:hover {
    background-color: var(--special-gold-dark);
    color: var(--special-gold-light);
}

#popout-create-activity .priority_container .priority_option.three:hover {
    background-color: var(--dark-blue);
    color: var(--light-blue);
}

#popout-create-activity .priority_container .priority_option.four:hover {
    background-color: var(--dark-green);
    color: var(--light-green);
}

#popout-create-activity .priority_container .priority_option .priority_option_content {
    font-size: 1.5rem;
    font-weight: 700;
}

#popout-create-activity .action-buttons {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 25px 0;
    height: 50px;
}

#popout-create-activity .options-button-header--red {
    margin-left: auto;
    margin-right: 45px;
}