.dropdown__container-dropdown--warehouse {
  position: relative;
}

.dropdown__container-dropdown--warehouse .input_field__input_box--text.dropdown.classic {
  display: flex;
  align-items: center;
}

.input_field__dropdown.classic {
  position: inherit;
  top: 0;
}

.input_field__dropdown.classic .dropdown__item {
  width: 100%;
  display: grid;
  grid-template: auto / 0fr 1fr 1fr;
}

.input_field__dropdown.classic .dropdown__item--single {
  width: 100%;
  display: grid;
  grid-template: auto / 1fr;
}

