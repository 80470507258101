#errorBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  height: 20vh;
  width: 45vh;
  background-color: var(--secondairy-light-gray);
  top: 0;
  left: 50%;
  padding: 2.25vmin;
  border-radius: 25px;
  transform: translate(-50%, -20vh);
  transition: all 0.25s ease-in-out;
  color: black;
  font-weight: 500;
  text-align: center;
  font-size: 1.5vmin;
  z-index: 1000;
}

#errorBox.red {
  background-color: var(--light-red);
}

#errorBox.green {
  background-color: var(--light-primary-green);
}

#errorBox.active {
  transform: translate(-50%, -13.5vh);
}