.body__activities {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.body__activities .body__section__header {
    grid-template: auto / repeat(5, 1fr);
}

.body__activities .body__section {
    /* reguires index.css */
    transition: height 500ms ease-in-out;
}

#activities-list {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#activities-list .table {
    min-height: 100%;
}

#activities-list .table__header-9 {
    grid-template-columns: 30px 70px 0.75fr 1.5fr 0.75fr 0.75fr 60px 60px 30px;
    border-left: 5px solid white;
}

#activities-list .table__row-9 {
    grid-template-columns: 30px 70px 0.75fr 1.5fr 0.75fr 0.75fr 60px 60px 30px;
    border: solid 2px white;
    border-left: 5px solid var(--secondairy-light-gray);
    background-color: var(--secondairy-ultra-light-gray);
    cursor: pointer;
}

#activities-list .table__row-9 img {
    height: 25px;
}

#activities-list .table__row-9 svg {
    color: var(--secondairy-medium-gray);
    font-size: 1.2rem;
}

#activities-list .table__row-9.overdue {
    background-color: var(--hyper-light-red);
    border: solid 2px white;
    border-left: var(--light-red) solid 5px;
}

#activities-list .table__row-9.done {
    background-color: var(--ultra-light-green);
    border: solid 2px white;
    border-left: var(--light-green) solid 5px;
}

#activities-list .activity__detail {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    background-color: var(--secondairy-ultra-light-gray);
    padding: 15px 20px;
    color: var(--secondairy-medium-gray);
}

#activities-list .activity__detail--comment {
    grid-column: 1;
    grid-row: 1;
    font-size: 0.75rem;
}

#activities-list .activity__detail h3 {
    margin-bottom: 2.5px;
    font-size: 1rem !important;
}

#activities-list .activity__detail--email {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

#activities-list .activity__detail--email .email-icon {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

#activities-calendar-view {
    width: 100%;
    height: 100%;
    overflow: hidden;
}



