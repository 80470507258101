/*  ANCHOR GLOBAL  */

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

button {
  -webkit-appearance: none; /* Remove default styling on iOS */
  appearance: none; /* Remove default styling on modern browsers */
  -webkit-text-fill-color: inherit;
  color: var(--primary-black);
}

.template--mobile::-webkit-scrollbar {
    display: none;
}

/*  ANCHOR TEMPLATE  */

.template--mobile {
  position: relative;
  display: grid;
  grid-template: auto auto / 1fr;
  grid-template-areas:
    "body"
    "menu";
  background: var(--secondairy-light-gray);
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.template__body--mobile {
  position: relative;
  grid-area: body;
  height: 90vh;
  padding: 10px;
}

.template__menu--mobile {
  grid-area: menu;
  height: 10vh;
}

#header--mobile {
  position: relative;
  padding: 0;
  display: flex;
  align-items: end;
  border-bottom: var(--secondairy-medium-gray) solid 2px;
  border-radius: 0;
}

#header h1 {
  color: var(--secondairy-medium-gray);
  font-size: 2.3rem;
}

/*  ANCHOR FORM ELEMENTS  */

.template--mobile form {
  padding: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
}

.template--mobile form::-webkit-scrollbar {
  display: none;
}

form h1 {
  color: var(--secondairy-medium-gray);
  text-transform: uppercase;
  font-size: 0.9rem;
  margin: 15px 0;
  width: 100%;
  text-align: center;
}

.input_field__wrapper.mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7.5px;
  height: 60px;
  border-bottom: 1px var(--secondairy-light-gray-plus) solid;
  margin-bottom: 15px;
  padding: 7.5px;
}

.input_field__wrapper.mobile.special {
  background-color: var(--secondairy-light-gray-plus);
  border-radius: 5px;
}

.input_field--mobile {
  margin-bottom: 15px;
}

.input_field--mobile > .input_text--mobile {
  margin-bottom: 0;
}

.input_text--mobile {
  background-color: var(--secondairy-light-gray-plus);
  border-radius: 5px;
  /* background-color: transparent; */
  border: none;
  /* border-bottom: 1px var(--secondairy-light-gray-plus) solid; */
  height: 60px;
  width: 100%;

  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 15px;
  color: var(--primary-black);
  text-align: left;
  padding: 7.5px;
}

.input_text--mobile.loading {
  background: linear-gradient(90deg, var(--secondairy-light-gray) 25%, var(--secondairy-ultra-light-gray) 50%, var(--secondairy-light-gray) 75%);
  background-size: 200% 100%;
  border: 1px var(--secondairy-light-gray-plus) solid;
  animation: skeleton-loading-input 1.5s infinite;
  pointer-events: none; /* Disable interactions */
  color: transparent; /* Hide text color */
}

.input_text--mobile.loading::placeholder {
  color: transparent; /* Hide text color */
}

.input_text--mobile::placeholder {
  font-weight: 300;
  opacity: 0.33;
  color: var(--primary-black);
}

.input_text--mobile:-webkit-autofill,
.input_text--mobile:-webkit-autofill:hover,
.input_text--mobile:-webkit-autofill:focus,
.input_text--mobile:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--secondairy-light-gray) inset !important;
}

.input_text--mobile:focus {
  border-radius: 5px;
  /* background-color: var(--secondairy-light-gray-plus); */
  border: 1px solid var(--secondairy-medium-gray);
  outline: none;
}

.input_text--mobile::placeholder {
  font-weight: 300;
  opacity: 0.33;
  color: var(--primary-black);
}

.input_text--mobile.textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 120px;
  border: 1px var(--secondairy-light-gray-plus) solid;
  border-radius: 5px;
}

.input_text--mobile.textarea:focus {
  border-radius: 5px;
  border: 1px solid var(--secondairy-medium-gray);
}

.input_field--combo-3070--mobile {
  display: flex;
  width: 100%;
  gap: 5px;
}

.input_field--combo-3070--mobile input:first-child {
  width: 30%;
}

.input_field--combo-3070--mobile input:nth-child(2) {
  width: 70%;
}

.input_field--combo-7030--mobile {
  display: flex;
  width: 100%;
  gap: 5px;
}

.input_field--combo-7030--mobile input:first-child {
  width: 70%;
}

.input_field--combo-7030--mobile input:nth-child(2) {
  width: 30%;
}

.input_field--combo-5050--mobile {
  display: flex;
  width: 100%;
  gap: 5px;
}

.input_field--combo-5050--mobile input:first-child {
  width: 50%;
}

.input_field--combo-5050--mobile input:nth-child(2) {
  width: 50%;
}


.input_field__label.mobile {
  display: flex;
  align-items: center;
  margin-left: 0;
  font-size: 1rem;
}

.input_field__input_box--text.mobile {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  height: auto;
  resize: vertical;
  font-size: 1rem;
  color: var(--secondairy-medium-gray);
  font-weight: 400;
  background-color: var(--secondairy-light-gray-plus);
  border-radius: 5px;
  padding: 0 7.5px;
}

.input_field__input_box--text.mobile.special {
  background-color: var(--secondairy-light-gray);
}

.search_bar__dropdown--mobile {
  width: calc(100% - 20px); 
  height: 50px;
  outline: none;
  border: var(--secondairy-light-medium-gray) solid 1px;
  background-color: var(--secondairy-medium-gray);
  border-radius: 5px;
  color: var(--secondairy-light-medium-gray);
  padding: 0 7.5px;
  font-size: 1rem;

}

.form_dropdown--mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondairy-light-gray-plus);
  border: none;
  border-radius: 5px;
  height: 60px;
  width: 100%;

  text-align: center;
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 15px;
  color: var(--primary-black);
  text-align: left;
  padding: 7.5px;
}

.form_dropdown--mobile svg {
  font-size: 2.5rem;
  color: var(--secondairy-medium-gray);
}

.form_dropdown--mobile .placeholder {
  font-weight: 300;
  color: var(--primary-black);
  opacity: 0.33;
  font-style: normal;
}

.form_dropdown--mobile.loading {
  background: linear-gradient(90deg, var(--secondairy-light-gray) 25%, var(--secondairy-ultra-light-gray) 50%, var(--secondairy-light-gray) 75%);
  background-size: 200% 100%;
  border: 1px var(--secondairy-light-gray-plus) solid;
  animation: skeleton-loading-input 1.5s infinite;
  pointer-events: none; /* Disable interactions */
  color: transparent; /* Hide text color */
}

.form_dropdown--mobile.loading svg,
.form_dropdown--mobile.loading p {
  color: transparent; /* Hide text color */
}

/*  ANCHOR TABLES  */

.table__row_wrapper {
  position: relative;
}

.table__row-5--mobile {
  position: relative;
  display: grid;
  grid-template: 1.5rem 1.5rem / 1fr 1fr;
  width: 100%;
  padding: 7.5px 0;
  font-size: 0.9rem;
  color: var(--primary-black);
  column-gap: 10px;
  border-bottom: solid 1px var(--secondairy-light-gray-plus);
  padding-right: 1rem;
}

.table__row-4--mobile {
  position: relative;
  display: grid;
  grid-template: 1.5rem 1.5rem / 1fr 1fr;
  width: 100%;
  padding: 7.5px 0;
  font-size: 0.9rem;
  color: var(--primary-black);
  column-gap: 10px;
  border-bottom: solid 1px var(--secondairy-light-gray-plus);
  padding-right: 1rem;
}

.table__row-3--mobile {
  position: relative;
  display: grid;
  grid-template: 1.5rem 1.5rem / 1fr 1fr;
  width: 100%;
  padding: 7.5px 0;
  font-size: 0.9rem;
  color: var(--primary-black);
  column-gap: 10px;
  border-bottom: solid 1px var(--secondairy-light-gray-plus);
  padding-right: 1rem;
}

.table__row--field--arrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--secondairy-medium-gray);
  font-size: 1.1rem;
  height: 1rem;
  width: 1rem;
}

.table__row-4--mobile>.table__row--field,
.table__row-3--mobile>.table__row--field {
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding: 4px 0;
}

.table__row-4--mobile>div:nth-child(3),
.table__row-4--mobile>div:nth-child(4) {
  color: var(--secondairy-light-medium-gray);
  font-weight: 400;
}

.table__row-4--mobile>div:nth-child(2),
.table__row-4--mobile>div:nth-child(4) {
  width: calc(100% - 1rem);
}

.scroll_for_more__element--mobile {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 0.9rem;
  padding: 15px 0 60px 0;
}

.scroll_for_more__element--mobile svg {
  color: var(--secondairy-light-medium-gray);
}

.scroll_for_more__element--mobile .scroll_for_more__text {
  display: flex;
  align-items: center;
  gap: 15px;
}

.scroll_for_more__element--mobile .scroll_for_more__text svg {
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--primary-black);
}

/*  ANCHOR BUTTONS  */

.input_wth_button--wrapper {
  position: relative;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.input_wth_button--wrapper button {
  width: auto;
  height: 50px;
  margin-bottom: 15px;
}

.form_option_button {
  background-color: var(--primary-black);
  border: none;
  color: var(--secondairy-light-gray);
  padding: 10px 10px;
  border-radius: 7.5px;
  width: 100%;
  height: 50px;
}

.header__buttons {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;

}

.header__buttons .header__button {
  height: 50px;
  width: 50px;
  background-color: var(--primary-green);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondairy-light-gray);
  font-size: 1.8rem;
  box-shadow: var(--border-shadow-float);
}

.action_buttons--mobile {
  position: relative;
  display: flex;
  gap: 7.5px;
}

.action_buttons--mobile .green-button-action,
.action_buttons--mobile .red-button-action,
.action_buttons--mobile .gray-button-action {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 7.5px;
  padding: 7.5px 12.5px;
  font-size: 0.75rem;
  font-weight: 600;
  height: 50px;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  color: var(--primary-black);
}

.action_buttons--mobile .green-button-action {
  background-color: var(--light-primary-green);
  box-shadow: var(--border-shadow);
}

.action_buttons--mobile .red-button-action {
  background-color: var(--light-red);
  box-shadow: var(--border-shadow);
}

.action_buttons--mobile .gray-button-action {
  background-color: var(--primary-black);
  color: var(--secondairy-light-gray);
  box-shadow: var(--border-shadow);
}

/*  ANCHOR SECTIONS & LISTS  */

/* .list_2clmns__container--mobile {
} */

.list_2clmns__row--mobile {
  display: grid;
  grid-template-columns: 2fr 1fr;
  font-size: 1rem;
  padding: 10px 5px;
  border-radius: 5px;
}

.list_2clmns__row--mobile:nth-child(even) {
  background-color: var(--secondairy-light-gray-plus);
}

.list_2clmns__field--mobile {
  display: flex;
  align-items: center;
}

/*  ANCHOR COMPONENTS  */

.circular_loading_spinner__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondairy-light-gray-plus);
}

@media only screen and (max-width: 768px) {

  /*  ANCHOR //SCREEN GLOBAL  */

  *::-webkit-scrollbar {
    width: 1em;
  }

  *::-webkit-scrollbar-track {
    background: var(--secondairy-light-gray);
    border-radius: 10px;
    opacity: 0.5;
    margin-block: 0.5rem;
  }

  *::-webkit-scrollbar-thumb {
    background: var(--secondairy-light-medium-gray);
    border-radius: 10px;
  }

  /*  ANCHOR //SCREEN SECTIONS  */

  .body__section {
    height: 100%;
    background-color: var(--secondairy-light-gray);
    border: 1px solid var(--secondairy-light-medium-gray);
    padding: 10px;
  }

  .body__section--transparent {
    height: 100%;
    position: relative;
    margin-bottom: 10px;
    border-radius: 7.5px;
    padding: 15px;
    overflow: auto;
    overflow-x: hidden;
  }

  .body__section h2 {
    font-weight: 700;
    color: var(--primary-green);
    font-variant: small-caps;
    margin-bottom: 10px;
  }

  .body__section h2 p {
    font-size: 0.6rem;
    margin-left: 10px;
    margin-top: 7.5px;
  }

  /*  ANCHOR //SCREEN BUTTONS  */

  .green-button-header {
    position: absolute;
    height: 50px;
    font-size: 0.85rem;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 50%;
    color: var(--primary-black);
  }

  .red-button-header {
    position: absolute;
    height: 50px;
    font-size: 0.85rem;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 50%;
    color: var(--primary-black);
  }

  .gray-button-header {
    position: absolute;
    height: 50px;
    font-size: 0.85rem;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 50%;
    color: var(--secondairy-light-gray);
  }

}

  /*  ANCHOR  ANIMATIONS  */

@keyframes skeleton-loading-input {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}