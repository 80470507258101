.notification-popup__overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    opacity: 0;
    pointer-events: none;

    transition: all 200ms ease-in-out;
}

.notification-popup__overlay.active {
    position: fixed;
    pointer-events: all;
    opacity: 1;
    cursor: pointer;
}

.notification-popup__container {
    position: absolute;
    z-index: 1001;
    right: 50%;
    top: 50%;
    transform: translateX(75%) translateY(-500%);
    min-height: 15vh;
    width: 30vw;
    background-color: var(--primary-black);
    border-radius: 15px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: var(--border-shadow-min);
    opacity: 0;

    transition: all 200ms ease-in-out;
}

.notification-popup__container.active {
    opacity: 1;
    transform: translateX(75%) translateY(-100%);
}

.notification-popup__message {
    padding: 20px 30px;

    font-size: 1rem;
    color: white;
    line-height: 1.6rem;
}

.notification-popup__container #popup__close-icon {
    position: absolute;
    color: var(--primary-black);
    font-size: 0.7rem;
    right: 7px;
    top: 7px;
    cursor: pointer;
    background-color: var(--secondairy-light-medium-gray);
    border-radius: 20px;
    width: 25px;
    height: 18px;
    padding: 2.5px;
}