.form_dropdown_popup__container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  backdrop-filter: blur(10px);
}

.form_dropdown_popup__container.open {
  display: flex;
}

.form_dropdown_popup__container .clear_icon {
  position: absolute;
  top: 10%;
  right: 10%;
  color: var(--secondairy-light-medium-gray);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.form_dropdown_popup__container .search_bar__dropdown--mobile {
  position: absolute;
  top: 17.5%;
}

.form_dropdown_popup__container .clear_icon > p {
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-right: 5px;
}

.form_dropdown_popup {
  height: 250px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 100px 0;
}

.form_dropdown_popup__item {
  height: 50px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #666;
  text-align: center;
  transition: transform 0.3s, font-size 0.3s, opacity 0.3s;
  opacity: 0.5;
}

.form_dropdown_popup__item.selected {
  font-size: 18px;
  color: var(--primary-black);
  opacity: 1;
  transform: scale(1.5);
}

.form_dropdown_popup__container .selected_view {
  position: absolute;
  background-color: var(--secondairy-light-medium-gray);
  opacity: 50%;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 20px);
  height: 50px;
}

/*  ANCHOR DARK THEMED FILTER VERSION  */

.form_dropdown_popup__container.filter-popup {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 100%;
}

.form_dropdown_popup__container.filter-popup .form_dropdown_popup__item {
  color: var(--secondairy-light-medium-gray);
}

.form_dropdown_popup__container.filter-popup .form_dropdown_popup__item.selected {
  color: var(--secondairy-light-medium-gray);
}

.form_dropdown_popup__container.filter-popup .selected_view {
  background-color: var(--secondairy-medium-gray);
}