.notification-popup__overlay--popup-notification.active {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    backdrop-filter: blur(5px);
}

.notification-popup__overlay--popup-notification .notification-popup__container.active {
    opacity: 1;
    transform: translateX(50%) translateY(-75%);
}

.notification-popup__overlay--popup-notification .notification-popup__container {
    position: absolute;
    z-index: 1001;
    right: 50%;
    top: 50%;
    transform: translateX(50%) translateY(-500%);
    min-height: 10vh;
    width: 30vw;
    background-color: var(--primary-black);
    border-radius: 15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--border-shadow-min);
    opacity: 0;
    transition: all 200ms ease-in-out;
}

.notification-popup__overlay--popup-notification .notification-popup__elements {
    padding: 30px;
}

.notification-popup__overlay--popup-notification .notification-popup__elements__title h3 {
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
    text-align: center;
    margin-bottom: 20px;
}

.notification-popup__overlay--popup-notification .notification-popup__elements__text {
    font-size: 0.75rem;
    color: white;
    line-height: 1rem;
    margin: 20px 0;
    line-height: 1.2rem;
    text-align: center;
}

.notification-popup__overlay--popup-notification .notification-popup__elements__buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.notification-popup__overlay--popup-notification .notification-popup__elements__buttons__button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 0.85rem;
    font-weight: 500;
    background-color: var(--primary-green);
    cursor: pointer;
    transition: all 200ms ease-in-out;
    color: var(--primary-black);
}

.notification-popup__overlay--popup-notification .notification-popup__elements__buttons__button--decline {
    background-color: var(--red);
}

.notification-popup__overlay--popup-notification .notification-popup__elements__buttons__button--cancel {
    background-color: var(--secondairy-light-medium-gray);
}

.notification-popup__overlay--popup-notification .notification-popup__elements__buttons__button--confirm {
    background-color: var(--primary-green);
}