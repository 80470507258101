#popup-window--order-detail-mobile .popup_body {
    padding: 10px;
}


#popup-window--order-detail-mobile .unfinished {
    background-color: var(--secondairy-light-gray) !important;
}
  
#popup-window--order-detail-mobile .progress__trackers_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: auto;
}

#popup-window--order-detail-mobile .progress__tracker--first {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: var(--light-primary-green);
    border: white solid 1px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

#popup-window--order-detail-mobile .progress__tracker {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% + 10px);
    height: 40px;
    left: -10px;
    background-color: var(--light-primary-green);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border: white solid 1px;
}

#popup-window--order-detail-mobile .progress__tracker_wrapper p {
    font-weight: 400;
    font-size: 0.80rem;
    height: auto;
    margin-bottom: 7.5px;
    margin-left: 7.5px;
    text-align: left;
}

#popup-window--order-detail-mobile .progress__tracker--last {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% + 10px);
    height: 40px;
    left: -10px;
    background-color: var(--light-primary-green);
    border-radius: 15px;
    border: white solid 1px;
}

#popup-window--order-detail-mobile .progress__tracker--first.disabled,
#popup-window--order-detail-mobile .progress__tracker.disabled,
#popup-window--order-detail-mobile .progress__tracker--last.disabled {
    background-color: var(--secondairy-light-gray);
}

#popup-window--order-detail-mobile .progress__tracker_circle {
    width: 25px;
    height: 90%;
    margin-left: 2px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondairy-light-gray);
}

#popup-window--order-detail-mobile .progress__tracker_circle svg {
    fill: var(--light-primary-green);
    font-size: 1.2rem;
}

#popup-window--order-detail-mobile .progress__tracker_circle .disabled_icon {
    color: var(--secondairy-light-medium-gray);
    pointer-events: none;
}

#popup-window--order-detail-mobile .done_icon {
    opacity: 0;
}

#popup-window--order-detail-mobile .order_line {
    display: grid;
    grid-template: 30px 30px / 25px 50px 1fr 0.75fr;
    grid-template-areas:
    "quantity product product supplier"
    "comment comment comment delivery"
    ;
    font-size: 1rem;
    font-weight: 400;
    color: var(--primary-black);
    border-bottom: 1px solid var(--secondairy-light-gray-plus);
    margin-bottom: 15px;
}

#popup-window--order-detail-mobile #quantity {
    grid-area: quantity;
    display: flex;
    align-items: center;
    color: var(--primary-black);
    font-size: 1rem;
}

#popup-window--order-detail-mobile #product {
    grid-area: product;
    display: flex;
    align-items: center;
    color: var(--primary-black);
    font-size: 1rem;
}

#popup-window--order-detail-mobile #delivery {
    grid-area: delivery;
    display: flex;
    align-items: center;
}

#popup-window--order-detail-mobile #supplier {
    grid-area: supplier;
    display: flex;
    align-items: center;
    color: var(--secondairy-light-medium-gray);
    font-size: 0.85rem;
}

#popup-window--order-detail-mobile #comment {
    grid-area: supplier;
    display: flex;
    grid-area: comment;
    color: var(--secondairy-light-medium-gray);
    font-size: 0.85rem;
    overflow: hidden;
}

#popup-window--order-detail-mobile .action_buttons--mobile{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
