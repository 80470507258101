.input_box__container {
  display: flex;
  gap: 10px;
}

.input_box__title {
  font-size: 0.75rem;
  font-weight: 300;
  color: var(--secondairy-medium-gray);
  width: auto;
}

.input_box__input {
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 4px;
  resize: vertical;
  padding: 7.5px;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
  font-weight: 400;
}

.input_box__input:focus {
  outline: none;
}

.input_box__container.vert {
  display: flex;
  flex-direction: column;
}

.input_box__container.vert .input_box__input {
  width: 100%;
}

.input_box__container.vert .input_box__title {
  width: 100%;
}