
.order_line__container {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: var(--secondairy-light-gray-plus) 1px solid;
}

.order_line__container .input_text--mobile {
  margin-bottom: 7.5px;
}

.order_line__container .input_text--mobile.textarea {
  margin-bottom: 7.5px;
  min-height: 60px;
}

.order_line__container .dropdown_products__container {
  position: relative;
}

.order_line__container .dropdown_products__container svg {
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 10;
  font-size: 2rem;
  color: var(--secondairy-medium-gray);
}

.order_line__container .dropdown_suppliers__container {
  position: relative;
}

.order_line__container .dropdown_suppliers__container svg {
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 10;
  font-size: 2rem;
  color: var(--secondairy-medium-gray);
}

.order_line__container .dropdown_list_container {
  position: inherit;
  top: -12.5px;
  max-height: 200px;
  overflow: scroll;
  padding-top: 5px;
  border: 1px solid var(--secondairy-light-gray-plus);
  border-radius: 5px;
}

.order_line__container .dropdown_list_container .dropdown_item {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 7.5px;
  font-size: 0.9rem;
  font-weight: 300;
  color: var(--primary-black);
  padding: 10px 7.5px;
}

.order_line__container .dropdown_list_container .product_type__choices {
  display: flex;
  width: 100%;
  gap: 5px;
  padding: 2.5px;
  margin-bottom: 7.5px;
}

.order_line__container .dropdown_list_container .product_type__choices .product_choice__button {
  border: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 100%;
  background-color: var(--secondairy-light-gray);
}

.order_line__container .dropdown_list_container .product_type__choices .product_choice__button.selected {
  background-color: var(--secondairy-light-gray-plus);
  border: 1px solid var(--secondairy-light-medium-gray);
}

.order_line__container .product_and_amount_wrapper{
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 7.5px;
}

.order_line__container .delivered_and_supplier_wrapper{
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 7.5px;
}

.order_line__container .comment_and_delete_wrapper {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 7.5px;
}

.order_line__container .comment_and_delete_wrapper .delete {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-red);
  margin-bottom: 7.5px;
  border-radius: 5px;
}

.order_line__container .comment_and_delete_wrapper .delete #delete-icon {
  font-size: 1.5rem;
  color: var(--red);
}