#popout--create-new-product .popout__body{
    position: relative;
}

#popout--create-new-product .popout__body .form__section {
    padding: 0;
}

#popout--create-new-product .popout__body .form__section:nth-of-type(1) {
    display: grid;
    grid-template: 1fr 1fr 1fr / 1fr 2fr;
    gap: 15px;
    row-gap: 5px;
    height: fit-content;
}

#popout--create-new-product .popout__body .form__section:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: fit-content;
    width: 25%;
}

#popout--create-new-product .popout__body #grid-box_one {
    display: flex;
    flex-direction: column;
    grid-row: span 3;
    object-fit: cover;
}

#popout--create-new-product .popout__body #grid-box_one img {
    width: 100%;
    border-radius: 7.5px;
}

#popout--create-new-product .popout__body #grid-box_one .side_note {
    padding: 10px 0;
    /* text-align: center; */
}

#popout--create-new-product .popout__body #grid-box_one .image_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

#popout--create-new-product .popout__body #grid-box_one .image_wrapper button {
    position: absolute;
    width: 100%;
    height: 25%;
    outline: none;
    border: none;
    background-color: var(--light-red);
    font-weight: 500;
    bottom: -25%;
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

#popout--create-new-product .popout__body #grid-box_one .image_wrapper button.visible {
    transform: translateY(-100%);
}