.activity_fiche__container {
  background-color: var(--secondairy-ultra-light-gray);
  padding: 7.5px;
  border-radius: 7.5px;

  display: grid;
  grid-template: auto / 50px 1fr;
  grid-template-areas: 
    "picto person"
    "picto comment"
    "picto date"
  ;
  margin-bottom: 7.5px;
  column-gap: 10px;
}

.activity_date {
  grid-area: date;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);   
}

.activity_picto {
  grid-area: picto;
  height: 40px;
  align-self: center;
}

.activity_person {
  grid-area: person;
  font-size: 0.85rem;
  margin-bottom: 5px;
}

.activity_comment {
  grid-area: comment;
  font-size: 0.7rem;
  font-style: italic;
  margin-bottom: 10px;
  width: 50%;
}