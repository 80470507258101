.customer-content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
}

.customer-content-grid.mobile {
  grid-template-columns: repeat(2, 1fr);
}

.customer-content-grid .content-item {
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
  color: var(--secondairy-light-medium-gray);
  max-width: 150px;
  max-height: 150px;
}

.customer-content-grid .content-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.customer-content-grid .content-item .delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--light-red);
  color: var(--red);
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.customer-content-grid .add-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  cursor: pointer;
}

.customer-content-grid .add-button {
  font-size: 2rem;
  color: #888;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--secondairy-light-medium-gray);
}

.customer-content-grid .capture-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--secondairy-medium-gray);
}

.customer-content-grid .option-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  background-color: var(--secondairy-light-gray-plus);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  height: 100%;
  color: var(--secondairy-medium-gray);
}

.customer-content-grid .option-button svg {
  margin-right: 5px;
}

.fullscreen-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreen-popup img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.fullscreen-popup .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

/* Remove max-width/height constraints for list view */
.customer-content-grid.list-view .content-item {
  max-width: none;
  max-height: none;
  aspect-ratio: auto;
}

.customer-content-grid.list-view {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}

.customer-content-grid.list-view .delete-button {
  background-color: var(--light-red);
  color: var(--red);
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.customer-content-grid.list-view .add-content {
  width: 100%;
  height: 40px;
  background-color: #f0f0f0;
}

.customer-content-grid .content-item-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
}

.customer-content-grid .content-item-list:nth-child(even) {
  background-color: var(--secondairy-ultra-light-gray);
}

.customer-content-grid.mobile .content-item-list:nth-child(even) {
  background-color: transparent;
}

.customer-content-grid  .file-name {
  font-size: 14px;
  word-break: break-all;
}

.customer-content-grid  .list-actions {
  display: flex;
  gap: 10px;
}
