.searchbar__container {
  position: relative;
  width: 100%;
  height: 35px;
  border: 1px solid var(--secondairy-light-gray);
  border-radius: 7.5px;
  padding: 10px;
}

.searchbar__container.mobile {
  width: 100%;
  height: 60px;
  border: 1px solid var(--secondairy-light-gray-plus);
}

.searchbar__container > input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: 1px solid var(--secondairy-light-gray);
  border-radius: 7.5px;
  font-size: 1rem;
  color: var(--primary-black);
}

.searchbar__container > input:focus {
  outline: none;
}

.searchbar__container svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.8rem;
  color: var(--secondairy-light-gray-plus);
}

