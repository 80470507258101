.body__invoices-admin {
  height: 100%;
}

.body__invoices-admin #body-section-admin-users-1 {
  height: 100%;
}

.body__invoices-admin .table__header-7 {
  grid-template: 35px / 0.35fr 0.8fr 0.8fr 1.5fr 0.8fr 0.8fr 1fr;
}

.body__invoices-admin .table__row-7 {
  grid-template: 35px / 0.35fr 0.8fr 0.8fr 1.5fr 0.8fr 0.8fr 1fr;
}

.body__invoices-admin .invoices__hidden {
  display: none;
}