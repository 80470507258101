.body__brandowner_products {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.body__brandowner_products .body__section {
    transition: all 500ms ease-in-out;
}

.body__brandowner_products #create-new-product {
    position: relative;
    height: 30%;
    padding: 15px;
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
    gap: 15px;
    max-height: 100%;
    overflow: hidden;
}

.body__brandowner_products #my-products-table {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.body__brandowner_products #products--table .table__header-6 {
    position: relative;
    grid-template: 35px / 75px repeat(5, 1fr);
}

.body__brandowner_products #products--table .table__row-6 {
    grid-template: 75px / 75px repeat(5, 1fr);
    cursor: pointer;
}

.body__brandowner_products #products--table .table__row-6 .table__row--field{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

}

.body__brandowner_products #products--table .table__row-6 .table__row--field img {
    width: 100%;
    height: 100%;
    border-radius: 7.5px;
    object-fit: cover;
}